import React from "react";
import FormContainer from "./tables/crypto/FormContainer";
import { getAssets } from "../../../api/getAssets";
import { Asset } from "../../../models/assets.model";
import { useSelector, useDispatch } from "react-redux";
import { SET_SELECTED_ASSET, selectedAssetState } from "../../../store/reducers/selectedAssetSlice";
import { toast } from "react-toastify";
import AddNewAssetModal from "../modals/crypto/AddNewAssetModal";
export default function TableCrypto() {
  
    const [assets, setAssets] = React.useState<Asset[]>([])

    const [show, setShow] = React.useState(false)
    const [stateChange, setStateChange] = React.useState<boolean>(true)

    const closeModal = () => setShow(false);
    const openModal = () => setShow(true);

    const handleStateChange = () => setStateChange(!stateChange)

    const dispatch = useDispatch();
    const selectedAsset = useSelector(selectedAssetState);

    React.useEffect(() => {
      getAssets()
      .then((response) => {
        setAssets(response)
      })
      .catch((error) => {
          console.error(error);
      }); 
    }, [selectedAsset, stateChange])

  const handleAssetSelection = (name: string) => {
    const findAsset = assets.find((asset) => asset.name === name);
    if(!findAsset) return;
    dispatch(SET_SELECTED_ASSET(findAsset))
    toast.info("Új fókusz: " + findAsset?.name);
  }

  return (
    <div className="p-3">
    <AddNewAssetModal title={"Új eszköz"} showModal={show} closeModal={closeModal} handleStateChange={handleStateChange}/>
    <div className="flex flex-row justify-center items-center align-center bg-gray-300 rounded-md p-3">
      <label className="text-sm mr-2">Kérlek válaszd ki a kriptóeszközt: </label>
      <select defaultValue={selectedAsset ? selectedAsset.name : "Kérlek válassz"} onChange={(e) => handleAssetSelection(e.target.value)} className="w-52 bg-dark-blue text-white text-white px-4 py-3 rounded-lg shadow-md">
          <option className="px-2 py-1 text-center" disabled={true} value={"Kérlek válassz"}>Kérlek válassz</option>
          {assets.map((asset, index) => (
              <option value={asset?.name} className="px-2 py-1 text-center" key={index}>{asset?.name}</option>
          ))}
      </select>

      <button onClick={openModal} className="text-dark-blue mr-2 text-md text-center p-2 bg-silver ml-2 shadow-lg rounded hover:opacity-80">Új kriptoeszköz felvétele</button>
      
    </div>

    <FormContainer/>
    </div>
  );
}