import { DaisyTopList } from "../models/types/DaisyTopList";
import { HumanTopList } from "../models/types/HumanTopList";
import { responseHero } from "../utils/axiosInterceptor";
import { Systems } from "../enums/systems";

type TopListResponse = DaisyTopList[] | HumanTopList[];

export const getTopList = (system_id: number, group_id: number): Promise<TopListResponse> => {
    return new Promise<TopListResponse>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/toplist/${system_id}/${group_id}`).then((response) => {
            if (system_id === Systems.HPM) {
                resolve(response.data as HumanTopList[]);
            } else {
                resolve(response.data as DaisyTopList[]);
            }
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};
