
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { ModalPropsWithNewsID } from "../../../types/modalProps";

const RemoveNewModal = ({ title, showModal, closeModal, handleStateChange, news } : Omit<ModalPropsWithNewsID, "user">) => {

    const removeNews = async () => {
        if(!news) return;
        await responseHero.delete(`${process.env.REACT_APP_API_URL}/news/${news?.id}`).then((response) => {
            closeModal()
            handleStateChange()
            toast.success("Sikeres a hír törlése!");
        }).catch(error => {
            toast.error("Sikertelen a hír törlése!");
            console.error(error.response.data);
            closeModal()
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl text-dark-blue font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <p className="text-md text-center">Biztosan törlöd a következő hírt? <span className="text-error">{news?.title}</span></p>
                            <p className="text-md font-semibold text-center text-error mt-2">Figyelem! Ezzel a hírhez tartozó videók is törlésre kerülnek!</p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={removeNews}
                        >
                            Törlés
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default RemoveNewModal;