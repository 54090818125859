import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { set, selectUser, UserData } from "../store/reducers/userSlice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
export default function Login() {

    const [emailAddress, setEmailAddress] = React.useState<string>(''); // store email address
    const [password, setPassword] = React.useState<string>(''); // store password
    const [rememberMe, setRememberMe] = React.useState<boolean>(false); // store remember me
 
    const dispatch = useDispatch(); // dispatch for redux store
    const user = useSelector(selectUser); // selector for redux store

    const navigate = useNavigate(); // navigate

    const validateLogin = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        
        if(!emailAddress || !password) {
            return;
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/login`, { 
            email: emailAddress, 
            password: password,
            stalkerMode: false
        }).then((response) => {
            startLoginProcess(response.data.data)
        }).catch(error => {
            console.error(error.response.data);
            toast.error("Hibás email / jelszó kombináció!");
        })
    }

    const startLoginProcess = async (data: UserData) => {
        if(!data.userInfo.admin) return navigate('/login')
        dispatch(set(data));  
        navigate(0); // refresh to show home component.
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="https://humandc.info" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
            <img className="w-8 h-8 mr-2" src="https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=025" alt="logo"/>
            Humánbefektetések
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-600">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Bejelentkezés
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={validateLogin}>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email cím</label>
                        <input 
                            type="email" 
                            name="email" 
                            id="email" 
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="name@company.com" 
                            onChange={(e) => setEmailAddress(e.target.value)}
                            value={emailAddress}
                            />
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Jelszó</label>
                        <input 
                            type="password" 
                            name="password" 
                            id="password" 
                            placeholder="••••••••" 
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <input 
                                id="remember" 
                                aria-describedby="remember" 
                                type="checkbox" 
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" 
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Jegyezz meg</label>
                            </div>
                        </div>
                        <a href="https://humandc.info" className="text-sm font-medium text-white hover:underline text-primary-300">Elfelejtett jelszó</a>
                    </div>
                    <button type="submit" className="hover:bg-sky-900 bg-sky-600 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Belépés</button>
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Weboldal: <a href="https://humandc.info" target="_blank" rel="noreferrer" className="font-medium text-primary-600 hover:underline dark:text-primary-500">humanbefektetesek.info</a>
                    </p>
                </form>
            </div>
        </div>
    </div>
    )
}

