import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, remove } from "../store/reducers/userSlice";
import { decodeJwtToken } from '../utils/verifyJwt';
import { useNavigate } from 'react-router';
import { responseHero } from '../utils/axiosInterceptor';
import { Spinner } from '../utils/Spinner';

interface Statistics {
    number_of_projects: number;
    number_of_users: number;
    number_of_groups: number;
    number_of_active_groups: number;
    number_of_envelopes: number;
    number_of_templates: number,
    number_of_orgs: number;
    amount_of_investments: number;
    number_of_daisy_records: number;
}

interface MarketValues {
    name: string;
    price: string;
}

interface MarketStats {
    marketStats: MarketValues[]
}

const MarketStatistics = ({marketStats} : MarketStats) => {

    if(marketStats.length !== 0) {
        return (
            <>
                {marketStats?.map((memoizedMarketStat) => (
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold text-light-brown">{memoizedMarketStat?.price}</dt>
                        <dd className="text-gray-400 text-white">{memoizedMarketStat?.name} árfolyam</dd>
                    </div>
                ))}
            </>
        )
    } else {
        return  (
            <>
                <Spinner width={8} height={8} show={true}/><Spinner width={8} height={8} show={true}/><Spinner width={8} height={8} show={true}/>
            </>
        )
    }
}

export default function Home () {
    const user = useSelector(selectUser); // selector for redux store
    const dispatch = useDispatch(); // dispatch for redux store

    const [tab, setTab] = React.useState<number>(1)

    const [stats, setStats] = React.useState<Statistics>()
    const [marketStats, setMarketStats] = React.useState<MarketValues[]>([])

    const navigate = useNavigate();
    const lastViewedPage = localStorage.getItem("lastViewedPage")

    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    let welcomeMessage;

    if (currentHour < 12) {
        welcomeMessage = "Jó reggelt, ";
    } else if (currentHour < 18) {
        welcomeMessage = "Jó napot, ";
    } else {
        welcomeMessage = "Jó estét, ";
    }


    React.useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedAccessToken = storedUser ? JSON.parse(storedUser).accessToken : '';
        const result = decodeJwtToken(storedAccessToken);

        if(!result) return;

        if(result.isExpired) {
            dispatch(remove()); // log out user
        }
    });

    const getStats = React.useCallback(async () => {
        try {
          const response = await responseHero.get(
            `${process.env.REACT_APP_API_URL}/stats`
          );
    
          setStats(response.data)

          const { data } = await responseHero.get(
            `${process.env.REACT_APP_API_URL}/currencies`
          )

          setMarketStats(data)

        } catch (error: any) {
          console.error(error.response.data);
        }
      }, [stats]);
    
      /* Effects */
      React.useEffect(() => {
        getStats();
      }, []);
    
    return (
        <>
            <div className="m-20">
                <div className="w-full border border-gray-600 rounded-lg shadow bg-dark-blue">
                    <ul className="text-sm font-medium text-center text-white divide-x divide-gray-600 rounded-lg sm:flex bg-dark-blue" id="fullWidthTab" data-tabs-toggle="#fullWidthTabContent" role="tablist">
                        <li className="w-full">
                            <button onClick={() => setTab(1)} id="stats-tab" data-tabs-target="#stats" type="button" role="tab" aria-controls="stats" aria-selected="true" className={`inline-block w-full p-4 rounded-tl-lg focus:outline-none hover:bg-light-blue ${tab === 1 ? 'bg-light-blue text-cream' : 'bg-dark-blue text-white'}`}>Statisztika</button>
                        </li>
                        <li className="w-full">
                            <button onClick={() => setTab(2)} id="about-tab" data-tabs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="false" className={`inline-block w-full p-4 rounded-tl-lg focus:outline-none hover:bg-light-blue ${tab === 2 ? 'bg-light-blue text-cream' : 'bg-dark-blue text-white'}`}>Profil</button>
                        </li>
                        <li className="w-full">
                            <button onClick={() => setTab(3)} id="faq-tab" data-tabs-target="#faq" type="button" role="tab" aria-controls="faq" aria-selected="false" className={`inline-block w-full p-4 rounded-tl-lg focus:outline-none hover:bg-light-blue ${tab === 3 ? 'bg-light-blue text-cream' : 'bg-dark-blue text-white'}`}>Üzenet</button>
                        </li>
                    </ul>

                        {tab === 1 && (
                            <div id="fullWidthTabContent" className="border-t border-gray-600 bg-dark-blue rounded-lg">
                                <div className="p-4 rounded-lg shadow-lg md:p-8 bg-dark-blue" id="stats" role="tabpanel" aria-labelledby="stats-tab">
                                    
                                    <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-6 text-white sm:p-8">
                                        <MarketStatistics marketStats={marketStats}/>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_projects}</dt>
                                            <dd className="text-gray-400 text-white">Futó projektek</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_users}</dt>
                                            <dd className="text-gray-400 text-white">Ügyfelek száma</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_active_groups}</dt>
                                            <dd className="text-gray-400 text-white">Aktív csoportok</dd>
                                        </div>
                                    </dl>
                                    
                                    <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-6 text-white sm:p-8">
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_groups}</dt>
                                            <dd className="text-gray-400 text-white">Létrehozott csoportok</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_orgs}</dt>
                                            <dd className="text-gray-400 text-white">Cégek száma</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_envelopes}</dt>
                                            <dd className="text-gray-400 text-white">Küldött szerződések</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_templates}</dt>
                                            <dd className="text-gray-400 text-white">Szerződéssablonok</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">${stats?.amount_of_investments}</dt>
                                            <dd className="text-gray-400 text-white">Össz befektett összeg</dd>
                                        </div>
                                        <div className="flex flex-col items-center justify-center">
                                            <dt className="mb-2 text-3xl font-extrabold text-light-brown">{stats?.number_of_daisy_records}</dt>
                                            <dd className="text-gray-400 text-white">Daisy rekordok száma</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        )}

                        {tab === 2 && (
                            <div className="p-4 bg-dark-blue rounded-lg md:p-8 flex flex-col justify-center items-center" id="about" role="tabpanel" aria-labelledby="about-tab">
                                <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-white">{welcomeMessage}<span className="text-brown">{user.userInfo.name.split(' ')[0]}</span></h2>
                                <img className="mb-5 border rounded-full border-cream p-2" src={`https://robohash.org/${user.userInfo.name}`} width={160} height={160}/>
                                <ul role="list" className="space-y-4">
                                    <li className="flex space-x-2">
                                        <svg className="flex-shrink-0 w-4 h-4 text-cream" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                        <span className="leading-tight text-white">Email: {user.userInfo.email}</span>  
                                    </li>  
                                </ul>
                            </div>
                        )}

                        {tab === 3 && (
                            <div className="p-4 bg-dark-blue rounded-lg md:p-8 text-white" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                <label htmlFor="message" className="block mb-2 text-sm font-medium text-white">Üzenj az ügyfeleknek: </label>
                                <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none" placeholder="Írd le a gondolataid..."></textarea>
                            </div>
                    )}
                    </div>
                </div>
        </>
    )
}