import React from "react";
import NewsList from "../../components/news/NewsList";
import VideosList from "../../components/news/videos/VideosList";
import { getSys } from "../../api/getSys";
import { System } from "../../models/system.model";
import { sys } from "typescript";

export default function NewsManager () {

    const [systems, setSystems] = React.useState<System[]>([])
    const [selectedSystem, setSelectedSystem] = React.useState<System>();

    React.useEffect(() => {
        getSys()
        .then((response) => {
            setSystems(response)

            if(response.length > 0) {
                setSelectedSystem(response[0]) // change it to default
            }
            
        })
        .catch((error) => {
            console.error(error);
        });
    }, [])
    
    return (
        <div className="m-5 p-3">
            <div className="w-full overflow-x-auto text-white">
                <div className="text-center flex">
                    {systems.map((system, index) => (
                        <button
                        className={`flex-1 text-md text-center p-2 ${
                        selectedSystem?.id === system.id ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                        } hover:bg-black font-semibold border-l border-r border-white`}
                        onClick={() => setSelectedSystem(system)}
                        >
                            {system.name}
                        </button>
                    ))
                    }
                </div>
            </div>
            <NewsList selectedProject={selectedSystem}/>
        </div>
    )
}