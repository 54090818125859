import { Company } from "../models/company.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getCompanyDetails = (): Promise<Company> => {
    return new Promise<Company>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/company`).then((response) => {
            resolve(response.data as Company);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};