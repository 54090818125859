type NewsLetterSendedModalProps = {
    title: string;
    showModal: boolean;
    responses: NodeMailerResponse[];
    closeModal: () => void;
};

export type NodeMailerResponse = {
    recipient: string;
    result: {
        accepted: string[];
        rejected: string[];
    };
}
const FinishedNewsLetterModal = ({ title, showModal, responses, closeModal} : NewsLetterSendedModalProps ) => {

    const renderStatus = (accepted: string[], rejected: string[]) => {
        return accepted.length > 0 ? (
            <span className="text-green-500">✔️</span> // Zöld pipa
        ) : (
            <span className="text-red-500">❌</span> // Piros X
        );
    };

    return (
        <>
            {(showModal) && (
                <>
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                            <h3 className="text-3xl font=semibold">{title}</h3>
                            </div>
                            <div className="relative p-6 flex-auto">
                               <div className="overflow-y-auto max-h-60">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Elküldve</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {responses.map((response, index) => (
                                                <tr key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{response.recipient}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{renderStatus(response.result.accepted, response.result.rejected)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={closeModal}
                            >
                                Bezárás
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
}

export default FinishedNewsLetterModal;