import React from "react";
import ModalProps, { ModalPropsWithGroupMining } from "../../../../types/modalProps";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { BuyingLevels } from "../../subcomponents/tradinghelper/AssetBuyPanel";


interface ExtendedModalProps extends ModalProps {
    buyingLevels: BuyingLevels;
}

const BuyLevelChangeModal = ({ title, showModal, closeModal, handleStateChange, buyingLevels } : Omit<ExtendedModalProps, "user">) => {


    const [stateValue, setStateValue] = React.useState<BuyingLevels>({
        first: 50,
        second: 30,
        third: 20,
    });

    React.useEffect(() => {
        setStateValue({
            first: buyingLevels?.first,
            second: buyingLevels?.second,
            third: buyingLevels?.third
        })
    }, [buyingLevels])

    const handleChange = (type: keyof BuyingLevels, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
    
        setStateValue(prevData => ({
            ...prevData,
            [type]: parseInt(inputValue, 10) || 0
        }));
    }
    

    const saveNewLiqLevels = async () => {
        /*
        await responseHero.delete(`${process.env.REACT_APP_API_URL}/humandc/mining/${row?.transaction_id}`).then((response) => {
            closeModal()
            handleStateChange()
            toast.success("Sor törölve!")
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error("Hiba a sor törlése közben!")
        })
        */
       closeModal();
       toast.success("Sikeres mentetted az új likviditási szinteket. ")
    }

            return (
            <>
                {(showModal) && (
                    <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative my-6 mx-auto">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-3xl text-dark-blue font=semibold">{title}</h3>
                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <div className="flex flex-row items-center px-2 py-2 space-x-4">
                                            <label className="text-lg text-dark-blue">1. vásárlási szint változtatása (%): </label>
                                            <input onChange={(e) => handleChange("first", e)} value={stateValue?.first} className="text-center  border w-max text-lg" type="number" max={100} min={1}></input>   
                                        </div>

                                        <div className="flex flex-row items-center px-2 py-2 space-x-4">
                                            <label className="text-lg text-dark-blue">2. vásárlási szint változtatása (%): </label>
                                            <input onChange={(e) => handleChange("second", e)} value={stateValue?.second} className="text-center  border w-max text-lg" type="number" max={100} min={1}></input>   
                                        </div>

                                        <div className="flex flex-row items-center px-2 py-2 space-x-4">
                                            <label className="text-lg text-dark-blue">3. vásárlási szint változtatása (%): </label>
                                            <input onChange={(e) => handleChange("third", e)} value={stateValue?.third} className="text-center  border w-max text-lg" type="number" max={100} min={1}></input>   
                                        </div>
                                    </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Mégse
                                </button>
                                <button
                                    className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => saveNewLiqLevels()}
                                >
                                    Mentés
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </>
        );
}

export default BuyLevelChangeModal;