import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice'
import menuReducer from './reducers/menuSlice'
import navReducer from './reducers/navSlice'
import selectedUserReducer from './reducers/selectedUserSlice'
import selectOrgReducer from './reducers/selectedOrgSlice'
import selectHpmTabReducer from './reducers/hpmNavSlice'
import groupReducer from './reducers/selectedGroupSlice'
import assetReducer from './reducers/selectedAssetSlice'
const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    nav: navReducer,
    selectedUser: selectedUserReducer,
    selectedOrg: selectOrgReducer,
    tabs: selectHpmTabReducer,
    selectedGroup: groupReducer,
    selectedAsset: assetReducer
  },
});

export default store;
