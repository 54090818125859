import React from "react";
import { getSys } from "../../api/getSys";
import { System } from "../../models/system.model";
import DaisyTopListBoard from "../../components/toplist/daisy/DaisyTopListBoard";
import HumanTopListBoard from "../../components/toplist/human/HumanTopListBoard";
import { Systems } from "../../enums/systems";
import { Group } from "../../models/group.model";

const defaultGroups = [
    {
        id: 95,
        name: "Human Kripto Befektetesi Alap",
    },
    {
        id: 96,
        name: "Human D.C",
    },
]
export default function TopList() {
    const [systems, setSystems] = React.useState<System[]>([]);
    const [groups, setGroups] = React.useState<Group[]>([]);
    const [selectedSystem, setSelectedSystem] = React.useState<System>();
    const [selectedGroupId, setSelectedGroupId] = React.useState<number>(0);

    React.useEffect(() => {
        getSys()
            .then((response) => {
                setSystems(response);

                if (response.length > 0) {
                    setSelectedSystem(response[0]); // change it to default
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className="m-5 p-3">
            <div className="w-full overflow-x-auto text-white">
                <div className="text-center flex">
                    {systems.map((system, index) => (
                        <button
                            key={index}
                            className={`flex-1 text-md text-center p-2 ${
                                selectedSystem?.id === system.id ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                            } hover:bg-black font-semibold border-l border-r border-white`}
                            onClick={() => setSelectedSystem(system)}
                        >
                            {system.name}
                        </button>
                    ))}
                </div>
            </div>
            {selectedSystem?.id === Systems.HPM && (
                <div className="mt-2 bg-dark-blue shadow-md rounded px-4 py-2">
                    <ul className="flex items-center">
                        {defaultGroups.map((dgroups, index) => (
                            <li key={index}>
                            <input
                                id={dgroups.name}
                                value={dgroups.id}
                                checked={dgroups.id === selectedGroupId}
                                onChange={(e) => setSelectedGroupId(parseInt(e.target.value, 10))}
                                type="radio"
                                className="focus:outline-none w-4 h-4"
                            />
                            <label
                                htmlFor={dgroups.name}
                                className="ml-2 mr-2 text-lg font-medium text-white "
                            >
                                {dgroups.name}
                            </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div>
                {selectedSystem && selectedSystem.id === Systems.DAISY && <DaisyTopListBoard />}
                {selectedSystem && selectedSystem.id === Systems.HPM && <HumanTopListBoard group_id={selectedGroupId}/>}
            </div>
        </div>
    );
}
