import React, { Fragment, useState } from "react";
import { MiningMachine } from "../../models/miningmachine.model";
import { getMiningMachines, getMiningMachinesByTron } from "../../api/getMiningMachines";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import RemoveMiningMachineRecordModal from "./modals/RemoveMiningMachineRecordModal";
import ModifyMiningMachineRecordModal from "./modals/ModifyMiningMachineRecordModal";
import { EnergyIcon } from "../../styles/assets/svgs/EnergyIcon";
import { useNavigate } from "react-router";
import AddNewEnergyCostModal from "./modals/AddNewEnergyCostModal";
import AddNewProductionCostModal from "./modals/AddNewProductionCostModal";
import { PlusIcon } from "../../styles/assets/svgs/PlusIcon";
import AddNewMiningMachineModal from "./modals/AddNewMiningMachineModal";
import { ExtendedMiningMachine } from "../../models/types/ExtendedMiningMachine";
import { getMiningMachineTronTypes } from "../../api/getMiningMachineTypes";
import { MiningMachineTron } from "../../models/miningmachinetron.model";
import { TrashIcon } from "../../styles/assets/svgs/TrashIcon";
import RemoveMultipleRecords from "./modals/RemoveMultipleRecords";
import AddCoinConversionModal from "./modals/AddCoinConversionModal";

type MiningMachineListProps = {
    allMachine: boolean;
}
export default function MiningMachineList ({ allMachine } : MiningMachineListProps) {

    const menu = useSelector(selectMenuStates)

    const navigate = useNavigate();
    
    const [miningMachines, setMiningMachines] = React.useState<ExtendedMiningMachine[]>([]);
    const [tronWallets, setTronWallets] = React.useState<MiningMachineTron[]>([]);
    const [selectedMachines, setSelectedMachines] = React.useState<number[]>([]);

    const [showCCModal, setShowCCModal] = React.useState<boolean>(false);
    const [showModifyModal, setShowModifyModal] = React.useState<boolean>(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
    const [showRecordsRemoveModal, setShowRecordsRemoveModal] = React.useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = React.useState<MiningMachine>();

    const [showEnergyModal, setShowEnergyModal] = React.useState<boolean>(false);
    const [showProductionModal, setShowProductionModal] = React.useState<boolean>(false);

    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const [selectedWallet, setSelectedWallet] = useState<MiningMachineTron>();

    
    React.useEffect(() => {
        if(!allMachine) {
            getMiningMachines(menu?.daisyMenu)
            .then((response) => {
                setMiningMachines(response);
            })
            .catch((error) => {
                console.error(error);
            });
        } else {
            getMiningMachines()
            .then((response) => {
                setMiningMachines(response);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        setSelectedMachines([]);
        
    }, [recodsStateChange, menu, allMachine])

    React.useEffect(() => {
        getMiningMachineTronTypes()
        .then((response) => {
            setTronWallets(response);
        })
        .catch((error) => {
            console.error(error);
        });

        setSelectedMachines([]);
    }, [menu])

    const closeModal = () => { 
        setShowRemoveModal(false) 
        setShowModifyModal(false); 
        setShowEnergyModal(false);
        setShowProductionModal(false);
        setShowModal(false);
        setShowRecordsRemoveModal(false);
        setShowCCModal(false);
    }

    const openModal = () => {
        setShowModal(true)
    }

    const openRecordsRemoveModal = () => {
        setShowRecordsRemoveModal(true);
    }

    const openRemoveModal = (record: MiningMachine) => {
        setShowRemoveModal(true);
        setSelectedRecord(record);
    }

    const openModifyModal = (record: MiningMachine) => {
        setShowModifyModal(true);
        setSelectedRecord(record);
    }

    const openProductionModal = () => {
        setShowProductionModal(true);
    }

    const openEnergyModal = () => {
        setShowEnergyModal(true);
    }

    const openCCModal = () => {
        setShowCCModal(true)
    }

    const handleStateChange = () => setRecordsStateChange(!recodsStateChange)

    const handleSelectedMachines = (id: number) => {
        if (Array.isArray(selectedMachines)) {
            const isMachineSelected = selectedMachines.includes(id);
            if (isMachineSelected) {
                setSelectedMachines(
                    selectedMachines.filter((selectedMachine) => selectedMachine !== id)
                );
            } else {
                setSelectedMachines(prevState => [...prevState, id]);
            }
        } else {
            console.error('Expected an array for selectedMachines, received:', selectedMachines);
        }
    }

    

    const handleSelectionChange = (wallet: MiningMachineTron) => {
        setSelectedWallet(wallet);

        getMiningMachinesByTron(wallet.id)
        .then((response) => {
            setSelectedMachines(response.map((res) => res.id));
        })
        .catch((error) => {
            console.error(error);
        });
    };
    

    return (
        <>
            <AddNewEnergyCostModal title={"Új energiaköltség"} showModal={showEnergyModal} closeModal={closeModal} handleStateChange={handleStateChange} miningMachineIds={selectedMachines}/>
            <AddCoinConversionModal title={"Konverzió létrehozása"} showModal={showCCModal} closeModal={closeModal} handleStateChange={handleStateChange} miningMachineIds={selectedMachines}/>
            <AddNewProductionCostModal title={"Új termelési adatsor megadása"} showModal={showProductionModal} closeModal={closeModal} handleStateChange={handleStateChange} miningMachineIds={selectedMachines}/>
            <AddNewMiningMachineModal title={"Hozzárendelés"} showModal={showModal} closeModal={closeModal} handleStateChange={handleStateChange} group={menu?.daisyMenu}/>
            <ModifyMiningMachineRecordModal title={"Bányagép módosítása"} showModal={showModifyModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord} group={menu?.daisyMenu}/>
            <RemoveMiningMachineRecordModal title={"Bányagép törlése"} showModal={showRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord} group={menu?.daisyMenu}/>
            <RemoveMultipleRecords title={"Adatsorok törlése"} miningMachineIds={selectedMachines} showModal={showRecordsRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange} group={menu?.daisyMenu}/>
            <Fragment>

                <div className="flex flex-row space-x-2 mb-5">
                    <button
                        onClick={openModal} 
                        className="rounded-lg shadow-md mt-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 flex flex-col items-center">
                        <PlusIcon color="#fff"/>
                        <p className="text-sm mt-2">Bányagép hozzáadása a csoporthoz</p>
                    </button>


                    {selectedMachines.length > 0 && (
                    <Fragment>
                        <button
                            onClick={openEnergyModal} 
                            className="rounded-lg shadow-md mt-4 bg-gray-500 hover:bg-gray-900 text-white font-bold py-2 px-4 flex flex-col items-center">
                            <EnergyIcon color="#fff"/>
                            <p className="text-sm mt-2">Energiaköltség hozzáadása {selectedMachines.length}db géphez. </p>
                        </button>

                        <button
                            onClick={openProductionModal} 
                            className="rounded-lg shadow-md mt-4 bg-gray-500 hover:bg-gray-900 text-white font-bold py-2 px-4 flex flex-col items-center">
                            <PlusIcon color="#fff"/>
                            <p className="text-sm mt-2">Termelési adatok hozzáadása {selectedMachines.length}db géphez.</p>
                        </button>
                        
                        <button
                            onClick={openCCModal} 
                            className="rounded-lg shadow-md mt-4 bg-gray-500 hover:bg-gray-900 text-white font-bold py-2 px-4 flex flex-col items-center">
                            <PlusIcon color="#fff"/>
                            <p className="text-sm mt-2">Coin váltás hozzáadása {selectedMachines.length}db géphez.</p>
                        </button>

                        <button
                            onClick={openRecordsRemoveModal} 
                            className="rounded-lg shadow-md mt-4 bg-gray-500 hover:bg-gray-900 text-white font-bold py-2 px-4 flex flex-col items-center">
                            <TrashIcon color="#fff"/>
                            <p className="text-sm mt-2">Utolsó rögzített értékek törlése {selectedMachines.length}db gépnél.</p>
                        </button>
                    </Fragment>
                    )}
                    
                </div>
                <div className="space-x-4 bg-dark-blue text-white shadow-md px-2 py-2">
                    <label>TRON tárcák: </label>
                    {tronWallets.map((wallet) => (
                        <label key={wallet.id} className="inline-flex items-center space-x-2">
                        <input
                            type="radio"
                            className="form-radio h-4 w-4 text-white"
                            name="wallet"
                            value={wallet.id}
                            checked={selectedWallet === wallet}
                            onChange={() => handleSelectionChange(wallet)}
                        />
                        <span className="text-gray-200">{wallet.name}</span>
                        </label>
                    ))}
                </div>

                <h2 className="text-lg mt-5">Az <span className="font-semibold">{menu?.daisyMenu?.group_name}</span> csoport bányagépei:</h2>
                
                {miningMachines.length > 0 ? 
                <div className="flex justify-center border bg-dark-blue p-3 m-2 mx-auto">
                <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                    <thead className="bg-dark-blue text-cream">
                        <tr>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">
                                    <input 
                                        onChange={() => {
                                            if(selectedMachines.length === miningMachines.length) {
                                                setSelectedMachines([]);
                                            } else {
                                                setSelectedMachines(miningMachines.map((m) => m.machine.id))
                                            }
                                            
                                        }}
                                        className="w-4 h-4" 
                                        type="checkbox"
                                        checked={selectedMachines.length === miningMachines.length}
                                    />
                            </th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bányagép neve</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bányagép típusa</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">TRON tárca</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Indulás dátuma</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Fogyasztás (KW/H)</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Fogyasztás (USD)</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Termelés</th>
                            <th className="py-3 px-6 text-xs text-center font-medium tracking-wider text-left uppercase text-cream" colSpan={3}>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y bg-dark-blue divide-gray-600">
                    
                        {miningMachines.map((record, index) => (
                            <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    <input 
                                        onChange={() => handleSelectedMachines(record.machine.id)}
                                        className="w-4 h-4" 
                                        type="checkbox"
                                        checked={selectedMachines.includes(record.machine.id)}
                                    />
                                </td>
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.machine.id}
                                </td>
                                <td className={`py-4 px-6 text-sm font-medium whitespace-nowrap ${selectedMachines.includes(record.machine.id) ? 'text-indigo-300' : 'text-white'}`}>
                                    {record.machine.name}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-dark-brown">
                                    {record.machine.miningMachine.name}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream">
                                    {
                                        record.machine ? record.machine.miningMachineTron.name
                                        :'Nincs adat'
                                    }
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {
                                        record.miningProd ? record.miningProd.date.toString().split('T')[0] 
                                        :'Nincs adat'
                                    }
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {
                                        record.miningEnergy.kwh ? record.miningEnergy.kwh 
                                        : 'Nincs adat'
                                    }
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {
                                        record.miningEnergy.price ? record.miningEnergy.price 
                                        : 'Nincs adat'
                                    }
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {
                                        record.miningProd ? record.miningProd.amount
                                        : 'Nincs adat'}
                                </td>

                                <td onClick={() => navigate(`/myminingmachine/${record.machine.id}`)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-indigo-400 hover:cursor-pointer hover:underline">Kezelőfelület</p>
                                </td>

                                <td onClick={() => openModifyModal(record.machine)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream hover:cursor-pointer hover:underline">Módosít</p>
                                </td>
                                <td onClick={() => openRemoveModal(record.machine)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-error hover:cursor-pointer hover:underline">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                </div> :
                <p className="text-center text-xl mt-2">Még nincs bányagép a csoporthoz társítva!</p>
                }
                </Fragment>
        </>
    )
}