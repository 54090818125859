import React from "react";
import { useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MiningMachine } from "../../../models/miningmachine.model";
import { selectUser } from "../../../store/reducers/userSlice";
import { getMiningMachineTypes } from "../../../api/getMiningMachineTypes";
import { MiningMachineType } from "../../../models/miningmachinetype.model";
import { Group } from "../../../models/group.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    row: MiningMachineType | undefined;

}

type ModifiedPayload = Pick<MiningMachineType, 
"avg_price" |
"avg_amount" |
"avg_kwh">

const ModifyDailyCostDataModal = ({ title, showModal, closeModal, handleStateChange, row } : ModalProps) => {

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        avg_price: 0,
        avg_amount: "",
        avg_kwh: "",
    });

    const [miningMachineTypes, setMiningMachineTypes] = React.useState<MiningMachineType[]>([]);
    const [selectedMiningMachineTypeID, setSelectedMiningMachineTypeID] = React.useState<number>();

    React.useEffect(() => {
        if(!row) return;

        setRecordData({
            avg_price: row?.avg_price,
            avg_amount: row?.avg_amount,
            avg_kwh: row?.avg_kwh,
        });

    }, [row])

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
        ) => {
        const inputValue = e.target.value;
        
            if ((type === "avg_amount" || type === "avg_kwh") && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
                setRecordData((prevData) => ({
                    ...prevData,
                    [type]: inputValue,
                }));
            } else {
                setRecordData((prevData) => ({
                    ...prevData,
                    [type]: parseInt(inputValue),
                }));
            }
        };
    
    const makeRecord = async () => {
        if(!row) return;

        await responseHero.patch(`${process.env.REACT_APP_API_URL}/dailyCosts/${row?.id}`, { dailyMiningData: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen frissítettél egy bányagép típust!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatfrissítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">BÁNYAGÉP</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">{row?.name} </span> <br/>átlag napi fogyasztás
                                        </label>
                                        <input
                                            value={recordData?.avg_kwh}
                                            onChange={(e) => handleChange('avg_kwh', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Napi fogyasztás (KW/h)"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">{row?.name} </span> <br/>átlag napi fogyasztás költsége
                                        </label>
                                        <input
                                            value={recordData?.avg_price}
                                            onChange={(e) => handleChange('avg_price', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Napi fogyasztási költség (USD)"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">{row?.name} </span> <br/>átlag napi termelés
                                        </label>
                                        <input
                                            value={recordData?.avg_amount}
                                            onChange={(e) => handleChange('avg_amount', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Napi termelés"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ModifyDailyCostDataModal;