import React, { ChangeEvent } from "react";
import { useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { MiningMachine } from "../../../../models/miningmachine.model";
import { MiningProduction } from "../../../../models/miningproduction.model";

/*
    id: number;
  
    kwh: number;
    price: number;
    
    date: Date;
    miningMachineId: number;
    miningMachine: MiningMachine;
    customDistribution: boolean;
*/

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    miningMachine: MiningMachine | undefined;

    row: MiningProduction | undefined;
}

type ModifiedPayload = Pick<MiningProduction, 
"amount" |
"date" |
"customDistribution" |
"miningMachineId">

const ModifyProductionCostModal = ({ title, showModal, closeModal, handleStateChange, miningMachine, row } : ModalProps) => {

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        amount: "",
        date: new Date(),
        customDistribution: false,
        miningMachineId: -1
    });

    const [selectedOption, setSelectedOption] = useState('Hagyományos elosztás a csoportba érkezett befizetések alapján');

    React.useEffect(() => {
        if(!miningMachine) return;

        setRecordData((prevData) => ({
            ...prevData,
            miningMachineId: miningMachine?.id
        }))
    }, [miningMachine])

    React.useEffect(() => {
        if(!miningMachine || !row) return;

        setRecordData({
            amount: row?.amount,
            date: row?.date,
            customDistribution: row?.customDistribution,
            miningMachineId: row?.miningMachineId
        })
    }, [miningMachine, row])

    const handleDistributionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);

        setRecordData((prevData) => ({
            ...prevData,
            customDistribution: event.target.value === 'Hagyományos elosztás a csoportba érkezett befizetések alapján' ? false : true
        }))
    };

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
        ) => {
        const inputValue = e.target.value;
        
            if (type === "amount" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
                setRecordData((prevData) => ({
                    ...prevData,
                    [type]: inputValue,
                }));
            } else {
                setRecordData((prevData) => ({
                    ...prevData,
                    [type]: type === "date" ? inputValue : parseInt(inputValue),
                }));
            }
        };
          
    
    const makeRecord = async () => {
        await responseHero.put(`${process.env.REACT_APP_API_URL}/miningProd/${row?.id}`, { miningProduction: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen frissítettél egy termelési adatsort!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">BÁNYAGÉP</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">

                                     <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">{miningMachine?.name} </span> <br/>Rögzítés dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">{miningMachine?.name} </span> <br/>Termelt mennyiség (BTC)
                                        </label>
                                        <input
                                            value={recordData?.amount}
                                            onChange={(e) => handleChange('amount', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Coin mennyiség (BTC)"/>
                                    </div>

                                    
                                </div>
                                <div className="p-5">

                                    <label className="block text-sm font-bold mt-2 mb-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">{miningMachine?.name} </span> <br/>Részesedés elosztása
                                    </label>

                                    <div className="flex flex-col space-y-2">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="Hagyományos elosztás a csoportba érkezett befizetések alapján"
                                                checked={selectedOption === 'Hagyományos elosztás a csoportba érkezett befizetések alapján'}
                                                onChange={handleDistributionChange}
                                                className="text-blue-600 form-radio h-5 w-5"
                                            />
                                            <span className="ml-2 text-gray-700">Hagyományos elosztás a csoportba érkezett befizetések alapján</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="A termelt mennyiség 50-50 -ben való elosztása a Human D.C-vel"
                                                checked={selectedOption === 'A termelt mennyiség 50-50 -ben való elosztása a Human D.C-vel'}
                                                onChange={handleDistributionChange}
                                                className="text-blue-600 form-radio h-5 w-5"
                                            />
                                            <span className="ml-2 text-gray-700">A termelt mennyiség 50-50 -ben való elosztása a Human D.C-vel</span>
                                        </label>
                                    </div>
                                    <p className="mt-4 text-blue-500">Kiválasztva: {selectedOption}</p>
                                </div>
                                
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ModifyProductionCostModal;