import React, { useEffect, useState } from "react";
import { responseHero } from "../../utils/axiosInterceptor";
import { toast } from "react-toastify";

export default function RateDropLetter() {
    const [subject, setSubject] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [tempStore, setTempStore] = useState<string>("alert_btc");
    const [sendBreakingLoader, setSendBreakingLoader] = useState<boolean>(false);
    const [selectedNewsLetterTemplate, setSelectedNewsLetterTemplate] = useState<number>(1);
    const [htmlTemplate, setHtmlTemplate] = useState<string>("");

    const options = [
        { 
            id: 1, 
            msg: "Bitcoin árfolyamesés!",
            template: "alert_btc"
        },
        { 
            id: 2, 
            msg: "Ethereum árfolyamesés!",
            template: "alert_eth"
        },
        { 
            id: 3, 
            msg: "Altcoin árfolyamesés!",
            template: "alert_alt"
        },
    ];

    const handleChange = (o_id: number) => {
        const tempSelectedOption = options.find((o) => o.id === o_id);
        if(tempSelectedOption) {
            setContent(tempSelectedOption?.msg);
            setSubject(tempSelectedOption?.msg);
            setTempStore(tempSelectedOption?.template)
            setSelectedNewsLetterTemplate(o_id);
        }
    };

    useEffect(() => {
        // Fetch the HTML template on component mount
        fetch(`./newsletter/alerts/${tempStore}.html`)
            .then(response => response.text())
            .then(data => setHtmlTemplate(data));
    }, [selectedNewsLetterTemplate]);

    const sendNewsLetter = async () => {
        setSendBreakingLoader(true)

        await responseHero.post(`${process.env.REACT_APP_API_URL}/sendRateDrop`, {
            email: {
                from: process.env.REACT_APP_NEWSLETTER_SENDER,
                password: process.env.REACT_APP_NEWSLETTER_PASSWORD,
                message: htmlTemplate,
                subject: subject,
                text: content,
            }
        }).then((response) => {
            toast.success("Sikeresen elküldted a figyelmeztető levelet!")
            setSendBreakingLoader(false)
        }).catch(error => {
            console.error(error.response.data);
            toast.error("Hiba csúszott a levél küldése közben!")
            setSendBreakingLoader(false)
        })
    }

    return (
        <div className="m-5 p-3">
            <div className="shadow-md w-full">
                <p className="text-xl text-center mb-2 mt-2">Figyelmeztetés - Küldő: <span className="font-semibold">Válaszd ki a figyelmeztető sablont, majd ha megfelelő: Figyelmeztetés küldése</span></p>
            </div>

            <div className="flex space-x-4 overflow-x-auto text-dark-blue h-screen">
                <div className="w-1/2 text-center bg-white shadow-md rounded">
                    <div>
                        {options.map(option => (
                            <div key={option.id}>
                                <input 
                                    type="radio" 
                                    id={`option-${option.id}`} 
                                    name="newsletterTemplate" 
                                    value={option.id} 
                                    checked={selectedNewsLetterTemplate === option.id}
                                    onChange={() => handleChange(option.id)}
                                />
                                <label htmlFor={`option-${option.id}`}>{option.msg}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-1/2 text-center bg-white shadow-md rounded">
                    <iframe
                        srcDoc={htmlTemplate}
                        className="w-full h-full"
                        title="Newsletter Content"
                    ></iframe>
                </div>
            </div>

            <div className="flex items-center justify-center mt-10">
                {/*
                <button disabled={sendBreakingLoader ? true : false} onClick={sendNewsLetter} className={`h-12 ${sendBreakingLoader ? 'bg-gray-800' : 'bg-success'} hover:opacity-70 text-white px-2 py-2`}>
                    {sendBreakingLoader ? 'Figyelmeztetés küldése, kérlek várj ...' : 'Figyelmeztetés elküldése'}
                </button>
                */}
            </div>
        </div>
    );
}
