import { MiningBeneficiaries } from "../models/miningbeneficiaries.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getMiningBeneficiaries = (): Promise<MiningBeneficiaries[]> => {
    return new Promise<MiningBeneficiaries[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningbeneficiaries`).then((response) => {
            resolve(response.data as MiningBeneficiaries[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};