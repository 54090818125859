import { TemporaryData } from "../models/tempdata.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getTempData = (id?: number): Promise<TemporaryData[]> => {
    return new Promise<TemporaryData[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/tempdata/${id}`).then((response) => {
            resolve(response.data as TemporaryData[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};