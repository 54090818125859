import { DaisyDeposits } from '../../../../models/daisydeposits.model';
import { responseHero } from '../../../../utils/axiosInterceptor';
import { toast } from "react-toastify";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    record: DaisyDeposits | undefined;
}

const RemoveRecordModal = ({ title, showModal, closeModal, handleStateChange, record } : ModalProps) => {


    const removeRow = async () => {
        await responseHero.delete(`${process.env.REACT_APP_API_URL}/daisydeposit/${record?.id}`).then((response) => {
            closeModal()
            handleStateChange()
            toast.success("Sor törölve!")
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error("Hiba a sor törlése közben!")
        })
    }

            return (
            <>
                {(showModal) && (
                    <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-3xl font=semibold">{title}</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p className="">Biztos vagy benne, hogy törlöd <strong>{record?.user.name} befizetését a {record?.group.group_name} csoportban</strong>?</p>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Mégse
                                </button>
                                <button
                                    className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => removeRow()}
                                >
                                    Törlés
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </>
        );
}

export default RemoveRecordModal;