import { LiqudityTransfer } from "../models/liqtrans.model";
import { responseHero } from "../utils/axiosInterceptor";
import { Group } from "../models/group.model";
import { HumanDashboard } from "../models/types/HumanDashboard";

export const getLiquidityTransfers = (group?: Group | undefined) : Promise<HumanDashboard> => {
    return new Promise<HumanDashboard>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/dashboard/liqtransfers/${group?.group_id}`).then((response) => {
            resolve(response.data as HumanDashboard);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};