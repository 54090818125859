import { Daisy } from "../../../models/daisy.model";
import { Assignments } from "../../../types/assigments";

export const updateAssignmentsInPayoutModal = async (records: Daisy[]) => {
    const assignmentResults = records.filter((record) => record.assigned_to_customer_crypto > 0 || record.assigned_to_customer_forex > 0);

    let assignVals: Assignments[] = [];
    assignmentResults.map((assignmentResult, index) => {
        assignVals.push({
            forex_assignment: assignmentResult.assigned_to_customer_forex,
            crypto_assignment: assignmentResult.assigned_to_customer_crypto,
            transaction_id: assignmentResult.transaction_id ? assignmentResult.transaction_id : -1,
            date: assignmentResult.date
        })
    });

    return assignVals;
}