import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../models/group.model";
import React from "react";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    group: Group | undefined;
    miningMachineIds: number[] | undefined;
}

const options = [
    {
        label: "Csak az utolsó rögzített energiavásárlási érték visszavonása. ",
        value: 1
    }, 
    {
        label: "Csak az utolsó rögzített termelési adatsor visszavonása. ",
        value: 2
    }, 
    {
        label: "Az utolsó rögzített termelési és energiavásárlási adatsor törlése egyaránt. ",
        value: 3
    }, 
]

const RemoveMultipleRecords = ({ title, showModal, closeModal, group, handleStateChange, miningMachineIds } : ModalProps) => {

    const [selectedValue, setSelectedValue] = React.useState<number>(1);

    const removeRow = async () => {
        if(!group || miningMachineIds?.length === 0) return;
        await responseHero.post(`${process.env.REACT_APP_API_URL}/mininglasts`, { miningMachineIds: miningMachineIds, option: selectedValue }).then((response) => {
            closeModal()
            handleStateChange()
            toast.success("Bányagéphez tartozó utolsó adat / adatok törlésre kerültek!")
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error("Hiba az adatok törlése közben!")
        })
    }

    const handleSelectedValue = (val: string) => {
        setSelectedValue(parseInt(val));
    }

            return (
            <>
                {(showModal) && (
                    <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-3xl text-dark-blue font=semibold">{title}</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p className="text-dark-blue">Biztos vagy benne, hogy törlöd az ehhez a bányagéphez tartozó utolsó adatsorokat?</p>
                                    <ul className="mt-5">
                                    {options.map((option, index) => (
                                        <li key={index} className="py-2">
                                        <input
                                            id={option.label}
                                            value={option.value}
                                            checked={option.value === selectedValue}
                                            onChange={(e) => handleSelectedValue(e.target.value)}
                                            type="radio"
                                            className="focus:outline-none w-4 h-4"
                                        />
                                        <label
                                            htmlFor={option.label}
                                            className="ml-2 mr-2 text-lg font-medium text-dark-blue "
                                        >
                                            {option.label}
                                        </label>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Mégse
                                </button>
                                <button
                                    className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => removeRow()}
                                >
                                    Törlés
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </>
        );
}

export default RemoveMultipleRecords;