
import { Group } from "../models/group.model";
import { MiningEnergy } from "../models/miningenergy.model";
import { MiningMachine } from "../models/miningmachine.model";
import { MiningProduction } from "../models/miningproduction.model";
import { ExtendedMiningMachine } from "../models/types/ExtendedMiningMachine";
import { responseHero } from "../utils/axiosInterceptor";

export const getMiningMachines = (group?: Group | undefined) : Promise<ExtendedMiningMachine[]> => {
    return new Promise<ExtendedMiningMachine[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningMachine/${group?.group_id}`).then((response) => {
            resolve(response.data as ExtendedMiningMachine[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getMiningMachinesByTron = (id: number) : Promise<MiningMachine[]> => {
    return new Promise<MiningMachine[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningMachinesByTron/${id}`).then((response) => {
            resolve(response.data as MiningMachine[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getMyMiningMachine = (miningMachineId: number) : Promise<MiningMachine> => {
    return new Promise<MiningMachine>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/myMiningMachine/${miningMachineId}`).then((response) => {
            resolve(response.data as MiningMachine);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getEnergyCost = (miningMachineId: number) : Promise<MiningEnergy[]> => {
    return new Promise<MiningEnergy[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningEnergy/${miningMachineId}`).then((response) => {
            resolve(response.data as MiningEnergy[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getMiningProduction = (miningMachineId: number) : Promise<MiningProduction[]> => {
    return new Promise<MiningProduction[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningProd/${miningMachineId}`).then((response) => {
            resolve(response.data as MiningProduction[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};