import React, { useState } from "react";
import { Group } from "../../models/group.model"
import { GroupMemberListProps } from "../../types/groupMemberListProps";
import { Daisy } from "../../models/daisy.model";
import { getDaisyRecords } from "../../api/getDaisyRecords";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getDaisyPayoutPercentages } from "../../api/getDaisyPayoutPercentages";
import { News } from "../../models/news.model";
import { System } from "../../models/system.model";
import AddNewNewsModal from "./modals/AddNewNewsModal";
import { getNews } from "../../api/getNews";
import VideosList from "./videos/VideosList";
import AddNewVideoModal from "./modals/AddNewVideoModal";
import { getVideo } from "../../api/getVideo";
import { Video } from "../../models/video.model";
import ModifyNewModal from "./modals/ModifyNewModal";
import ModifyVideoModal from "./modals/ModifyVideoModal";
import RemoveNewModal from "./modals/RemoveNewModal";

type NewsProps = {
    selectedProject: System | undefined;
}

export default function NewsList ({ selectedProject } : NewsProps) {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showNewVideoRecordModal, setShowNewVideoRecordModal] = React.useState<boolean>(false); // new record modal state

    const [showModifyRecordModal, setModifyRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyVideoRecordModal, setShowModifyVideoRecordModal] = React.useState<boolean>(false); // new record modal state

    const [showRemoveRecordModal, setRemoveRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showRemoveVideoRecordModal, setShowRemoveVideoRecordModal] = React.useState<boolean>(false); // new record modal state

    const [records, setRecords] = React.useState<News[]>([])
    const [videos, setVideos] = React.useState<Video[]>([])
    
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = React.useState<News>();

    const menu = useSelector(selectMenuStates)

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openModifyModal = (news: News) => {
        setSelectedRecord(news)
        setModifyRecordModal(true)
    };

    const openRemoveModal = (news: News) => {
        setSelectedRecord(news)
        setRemoveRecordModal(true)
    };

    const openVideoModal = () => {
        setShowNewVideoRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowNewVideoRecordModal(false)
        
        setModifyRecordModal(false)
        setRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    React.useEffect(() => {
        let firstElement;
        getNews(selectedProject?.id)
        .then((response) => {
            setRecords(response)
            setSelectedRecord(response[0])
            firstElement = response[0];
        })
        .catch((error) => {
            console.error(error);
        });

        if(firstElement) {
            getVideos(firstElement)
        } else {
            setVideos([])
        };
        
    }, [recodsStateChange, selectedProject])


    const getVideos = (news: News) => {
        getVideo(news)
        .then((response) => {
            setVideos(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }

    return (
        <>

        <AddNewNewsModal title={"Új hír létrehozása"} system_id={selectedProject?.id} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <AddNewVideoModal title={"Új videó létrehozása"} news={selectedRecord} showModal={showNewVideoRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <ModifyNewModal title={"Meglévő hír módosítása"} news={selectedRecord} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <RemoveNewModal title={"Meglévő hír törlése"} news={selectedRecord} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            
        <div className="text-center text-white mt-5 flex justify-center items-center space-x-3">
                <button className="text-md text-center p-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={openModal}>Új hír rögzítése</button>
                {selectedRecord && (
                    <button className="text-md text-center p-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={openVideoModal}>Új videó hozzárendelése: <span className="text-cream">{selectedRecord?.title}</span></button>
                )}  
        </div>

        <div className="shadow-md w-full">
            <p className="text-xl text-center mb-2 mt-2">Ügyfélkimutatás - Hírfolyam: <span className="font-semibold">Videókat hírekhez tudsz hozzáadni, ha még nincs létrehozva hír, kérlek előtte hozz létre egyet!</span></p>
        </div>

        <div className="flex flex-row space-x-2 justify-center">
                    
            <div className="w-1/2 overflow-x-auto text-white h-full">
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                <thead className="bg-gray-800 text-white sticky top-0">
                    <tr>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Hír címe</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer" colSpan={3}>Hír műveletek</th>
                    </tr>
                </thead>
                <tbody className="bg-dark-blue text-white">
                
                    {records.map((record, index) => (
                        <tr onClick={() => {
                            setSelectedRecord(record)
                            getVideos(record)
                        }} key={index} className={`border hover:cursor-pointer hover:bg-gray-600 ${selectedRecord?.id === record.id ? 'bg-gray-400' : 'even:bg-dark-blue odd:bg-light-blue'}`}>
                            <td className="text-lg font-semibold text-center py-2 px-3">
                                {record.title}
                            </td>
                            <td className="text-center">
                            <button onClick={() => openModifyModal(record)} className="text-cream hover:text-brown text-md font-bold p-2 cursor-pointer">Módosít</button>
                            </td>
                            <td className="text-center">
                            <button onClick={() => openRemoveModal(record)} className="text-error hover:opacity-80 text-md font-bold p-2 cursor-pointer">Törlés</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
            <VideosList videos={videos} selectedProject={selectedProject} selectedNewsRecord={selectedRecord}/>
        </div>
        </>
    )
}