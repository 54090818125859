import { MiningMachineTron } from "../models/miningmachinetron.model";
import { MiningMachineType } from "../models/miningmachinetype.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getMiningMachineTypes = () : Promise<MiningMachineType[]> => {
    return new Promise<MiningMachineType[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningMachineTypes`).then((response) => {
            resolve(response.data as MiningMachineType[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getMiningMachineTronTypes = () : Promise<MiningMachineTron[]> => {
    return new Promise<MiningMachineTron[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningMachineTronTypes`).then((response) => {
            resolve(response.data as MiningMachineTron[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};