import React, { useEffect, useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Daisy } from "../../../models/daisy.model";
import { Group } from "../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/reducers/userSlice";
import { Assignments } from "../../../types/assigments";
import { getRecordPayouts } from "../../../api/getRecordPayouts";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    row: Daisy | undefined,
    assignments: Assignments[],
}

type PayoutType = {
    id: number;
    name: string;
    default_value: number;
}

type Payout = {
    value: number | undefined;
    typeId: number;
    daisyId: number;
    currency: boolean;
}

const ModifyAssignmentsModal = ({ group, row, title, showModal, closeModal, handleStateChange, assignments } : Omit<ModalProps, "user">) => {

    const user = useSelector(selectUser);

    const [selectedAssignMent, setSelectedAssignment] = React.useState<Assignments>();
    const [selectedAssignmentCurrency, setSelectedAssignmentCurrency] = React.useState<boolean>(false);// true, ezért crypto, false ezért forex

    const [payouts, setPayouts] = React.useState<Payout[]>([]);
    const [payoutTypes, setPayoutTypes] = React.useState<PayoutType[]>([]);
    const [selectedPayoutType, setSelectedPayoutType] = useState<PayoutType>();
    const [newPayoutTypeName, setNewPayoutTypeName] = useState<string>("");

    React.useEffect(() => {
        if(assignments.length > 0) {
            const foundMyAssignment = assignments.find((assignment) => assignment.transaction_id === row?.transaction_id);
            if(foundMyAssignment) {
                setSelectedAssignment(foundMyAssignment);
            } else {
                setSelectedAssignment(assignments[0])
            }
            
            getPayoutTypes();
        }
    }, [assignments, row])

    React.useEffect(() => {
        getPayouts();
    }, [selectedAssignMent])

    const getPayouts = async () => {
        if(!selectedAssignMent) return;
        const result = await getRecordPayouts(selectedAssignMent.transaction_id)
        setPayouts(result);
    }


    const savePayout = async () => {
        if(!selectedAssignMent) return toast.error('Nincs kiválasztva kifizetés!')
        
        await responseHero.post(`${process.env.REACT_APP_API_URL}/savepayout/${selectedAssignMent?.transaction_id}`, { payouts: payouts }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen mentetted a kifizetéseket!`);
        }).catch(error => {
            console.error(error.response.data.message);
            toast.error(`Sikertelen adatmódosítás!`);
        })
    }

    const addNewPayoutType = async () => {
        if(!newPayoutTypeName || newPayoutTypeName.length < 3) return toast.warn('Kérlek 3 karakternél hosszabb nevet adj meg!')

        await responseHero.post(`${process.env.REACT_APP_API_URL}/payouttypes`, { name: newPayoutTypeName }).then((response) => {
            // handleStateChange();
            getPayoutTypes();
            toast.success(`Sikeresen a listához adva az új kifizetés típus!`);
        }).catch(error => {
            console.error(error.response.data.message);
            toast.error(`Sikertelen adatmódosítás, lehet már ez a típus a rendszer része!`);
        })
    }

    const getPayoutTypes = async () => {
        await responseHero.get(`${process.env.REACT_APP_API_URL}/payouttypes`).then((response) => {
            setPayoutTypes(response.data)
        }).catch(error => {
            console.error(error.response.data.message);

        })
    }

    const addNewRecord = (daisyId: number | undefined, currency: boolean) => {
        if(!daisyId) return;
        let payout: Payout = {
            value: 0,
            typeId: 1,
            daisyId: daisyId,
            currency: currency,
        }
        setPayouts((prevState) => [...prevState, payout]);
    }

    const removeRecord = (index: number) => {
        if(!payouts) return;
        const tmpArray = payouts.filter((payout) => payouts.indexOf(payout) !== index);
        setPayouts(tmpArray);
    }

    const handlePayoutTypeSelection = (modifiedPayout: Payout, name: string) => {

            if (!payoutTypes) return;
            const findPayoutType = payoutTypes.find((payout) => payout.name === name);
            setSelectedPayoutType(findPayoutType);

            const findModifiedPayout = payouts.find((payout) => payout === modifiedPayout);

            if (!findModifiedPayout) return;
            // Check if `selectedPayoutType` and its `default_value` property exist before updating
            if (findPayoutType && findPayoutType?.default_value !== undefined) {
                // Update the 'value' property of the found element
                findModifiedPayout.value = findPayoutType.default_value;
                findModifiedPayout.typeId = findPayoutType.id;
                
                // Create a new array with the updated element
                const updatedPayouts = [...payouts];

                // Find the index of the element to be updated
                const index = updatedPayouts.findIndex((payout) => payout === findModifiedPayout);

                // Replace the element at the specified index with the updated element
                updatedPayouts[index] = findModifiedPayout;

                // Update the state with the new array
                setPayouts(updatedPayouts);
            }
    }

    const handleUpdatePayoutValue = (payoutIndex: number, newValue: number) => {
        setPayouts(prevPayouts => {
        // Create a shallow copy of the existing payouts array
        const newPayouts = [...prevPayouts];
    
        // Update the value of the specified payout
        newPayouts[payoutIndex] = {
            ...newPayouts[payoutIndex],
            value: newValue
        };
    
        return newPayouts;
        });
    };

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <p className="ml-3">Befizetések dátumai:</p>
                            <div className="flex flex-row space-x-5 px-2 py-3 shadow-lg min-w overflow-x-auto overflow-y-hidden">
                                {assignments.map((assignment, index) => (
                                    <p onClick={() => setSelectedAssignment(assignment)} className={`${selectedAssignMent === assignment ? 'bg-cream text-gray-900' : 'bg-dark-blue text-white'} text-center px-3 py-2 shadow-md rounded-md cursor-pointer`} 
                                    key={index}>{assignment.date.toString().split('T')[0]}</p>
                                ))}
                            </div>
                            <form>
                                <div className="flex space-x-2 p-2 rounded shadow-md">
                                    <div onClick={() => setSelectedAssignmentCurrency(!selectedAssignmentCurrency)}
                                    className={`hover:opacity-80 cursor-pointer w-2/4 pr-2 text-center px-2 py-3 font-semibold uppercase rounded ${selectedAssignmentCurrency ? 'text-gray-600 bg-dark-blue' : 'text-white bg-success'}`}>
                                        FOREX <span className="text-cream">({selectedAssignMent?.forex_assignment.toLocaleString('en-US').replace(',', ' ')}$)</span>
                                    </div>
                                    <div onClick={() => setSelectedAssignmentCurrency(!selectedAssignmentCurrency)} 
                                    className={`hover:opacity-80 cursor-pointer w-2/4 pr-2 text-center px-2 py-3 font-semibold uppercase rounded ${!selectedAssignmentCurrency ? 'text-gray-600 bg-dark-blue' : 'text-white bg-success'}`}>
                                        KRIPTO <span className="text-cream">({selectedAssignMent?.crypto_assignment.toLocaleString('en-US').replace(',', ' ')}$)</span>
                                    </div>
                                </div>                                
 
                                <div className="overflow-y-auto max-h-56">
                                    {payouts.map((payout, index) => (
                                        <>
                                        {payout.currency === selectedAssignmentCurrency && (
                                            <div className="flex p-2 rounded shadow-md">
                                                <div className="w-1/4 pr-2">
                                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                                    <span className="text-success">Kifizetés </span> <br/>Kifizetés típusa
                                                    </label>
                                                    
                                                    <select defaultValue={payoutTypes.find((payoutType) => payoutType.id === payout.typeId)?.name} onChange={(e) => handlePayoutTypeSelection(payout, e.target.value)} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                                        
                                                        {payoutTypes.map((payoutType, index) => (
                                                            <option value={payoutType.name} className="px-2 py-1 text-center" key={index}>{payoutType.name}</option>
                                                        ))}
                                                    </select>
                                                
                                                </div>

                                                <div className="w-2/4 pr-2">
                                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                                    <span className="text-success">Kifizetés </span> <br/> Összege
                                                    </label>
                                                    <input
                                                        onChange={(e) => handleUpdatePayoutValue(payouts.indexOf(payout), parseInt(e.target.value, 10))}
                                                        value={payout?.value}
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inpayment" type="number" placeholder="EUR, USD, HUF"/>
                                                </div>

                                                <div className="w-1/4 pr-2 text-center">

                                                <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                                    <span className="text-success">Kifizetés </span> <br/> Törlése
                                                    </label>
                                                    <button
                                                        className="text-white text-center bg-dark-blue font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                        type="button"
                                                        onClick={() => removeRecord(index)}
                                                    >
                                                        Sor törlése
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                    ))}
                                </div>
                            </form>
                        </div>
                        
                        <div className="flex p-4 rounded shadow-sm items-center bg-gray-300 px-2 py-2">
                            <div className="pr-2">
                                <input
                                    value={newPayoutTypeName}
                                    onChange={(e) => setNewPayoutTypeName(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Új listaelem pl: TIER-666"/>
                            </div>
                            <div className="pr-2 text-center">
                                <button
                                    className={`${newPayoutTypeName ? '' : 'opacity-80'} mt-1 text-white text-center bg-dark-blue font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
                                    disabled={!newPayoutTypeName}
                                    type="button"
                                    onClick={() => addNewPayoutType()}
                                >
                                    Létrehoz
                                </button>
                            </div>
                        </div>


                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">                                
                        <button
                            className="text-white bg-success font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={() => addNewRecord(selectedAssignMent?.transaction_id, selectedAssignmentCurrency)}
                        >
                            Új sor hozzáadása
                        </button>
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={savePayout}
                        >
                            Mentés
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>*/}
            </>
        )}
    </>
  );
};

export default ModifyAssignmentsModal;