import { Organization } from "../../models/organization.model";

interface selectedOrgProps {
    select: Organization | undefined;
}
export default function SelectOrg ({ select } : selectedOrgProps) {
    /*

        Name of company: Name:
        Type of company: Address:
        Headquarter: Country of residence:
        Address: Place and time of birth:
        Company registration number: Mother’s birth name:
        Principal activities: Identity card number:
        Tax number:
        IBAN:
        Bank name:
        Email:

    */
    return (
        <>
        {select ? <p>Hozzárendelve a szerződéshez: <span className="text-cream">{select?.full_name}</span></p> : <p>Jelenleg nincs hozzárendelve cég</p>}
        {select ?
        <div className="flex space-x-4">
        <table className="border border-2 w-full text-center mt-2 p-2">
            <tr className="border">
                <td className="border p-2 font-bold">
                    Cég teljes neve:
                </td>
                <td className="border p-2 text-cream">
                    {select?.full_name}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Cég típusa:
                </td>
                <td className="border p-2 text-cream">
                    {select.org_type}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Székhely:
                </td>
                <td className="border p-2 text-cream">
                    {select?.hq}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Cím:
                </td>
                <td className="border p-2 text-cream">
                    {select?.address}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Cégjegyzékszám:
                </td>
                <td className="border p-2 text-cream">
                    {select?.company_registration_number}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Fő tevékenységi kör:
                </td>
                <td className="border p-2 text-cream">
                    {select?.main_activity}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Adószám:
                </td>
                <td className="border p-2 text-cream">
                    {select?.tax_number}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Bank neve és számlaszáma:
                </td>
                <td className="border p-2 text-cream">
                    {select?.bank_name}, {select?.iban}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Cég email:
                </td>
                <td className="border p-2 text-cream">
                    {select?.email}
                </td>
            </tr>
        </table>

        <table className="border border-2 w-full text-center mt-2 p-2">
            <tr className="border">
                <td className="border p-2 font-bold">
                    Cég képviselője:
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.name}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Címe: 
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.address}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Tartózkodási helye: 
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.location}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Születési helye, ideje:
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.birthplace}, {select?.leader?.birthdate.toString().split('T')[0]}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Anyja neve:
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.mother_name}
                </td>
            </tr>

            <tr className="border">
                <td className="border p-2 font-bold">
                    Személyi igazolvány száma:
                </td>
                <td className="border p-2 text-cream">
                    {select?.leader?.id_card}
                </td>
            </tr>
        </table>
        </div>
        : null
        }
        </>
    )
}