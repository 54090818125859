import { responseHero } from "../../../utils/axiosInterceptor";
import React, { useState } from "react"
import { Group } from "../../../models/group.model";
import GroupMemberList from "./GroupMemberList";
import { toast } from "react-toastify";
import RemoveGroupModal from "../modals/RemoveGroupModal";
import StartStopModal from "../modals/StartStopModal";
import { Spinner } from "../../../utils/Spinner";
import { selectedGroupState, SET_SELECTED_GROUP, REMOVE_SELECTED_GROUP } from "../../../store/reducers/selectedGroupSlice"
import { useDispatch, useSelector } from "react-redux";
import AddMember from "../toolbar/AddMember";

type GroupListProps = {
    system_id: number;
    searchText: string;
    groupStateChanged: boolean;
}

export interface GroupMemberAmounts extends Group {
    memberAmount: number;
}



export default function GroupList ({ system_id, searchText, groupStateChanged }: GroupListProps) {

    const [groups, setGroups] = React.useState<GroupMemberAmounts[]>([])
    const [selectedGroups, setSelectedGroup] = React.useState<Group>()
    const [spinner, setSpinner] = React.useState<boolean>(true)

    const [stateChange, setStateChange] = React.useState<boolean>(true)

    const dispatch = useDispatch();
    const selectedGroupFromStore = useSelector(selectedGroupState)

    const handleGroupSelection = (group: Group) => {
        setSelectedGroup(group)
        dispatch(SET_SELECTED_GROUP(group))
    }


    /* Effects */
    React.useEffect(() => {
        const getGroups = async () => {
            try {

                const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/groups`);
                
                const filteredGroups = response?.data?.filter((group: GroupMemberAmounts) => group.system_id === system_id && group.group_name.includes(searchText));
                
                if(filteredGroups.length === 0) {
                    setSpinner(false)
                    setGroups([])
                    return;
                }

                const updatedGroupsWithMemberAmount = await Promise.all(
                    filteredGroups.map(async (group: GroupMemberAmounts) => {
                        try {
                            const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/usersByGroup/${group.group_id}`);
                            setSpinner(false);
                            return {
                                ...group,
                                memberAmount: response?.data?.length
                            };
                        } catch (error: any) {
                            console.error(error.response?.data);
                            setSpinner(false)
                            return undefined;
                        }
                    }
                ))
                const validGroups = updatedGroupsWithMemberAmount.filter((group) => group !== undefined);
                setGroups(validGroups);
                setSpinner(false)

                if(selectedGroupFromStore) {
                    setSelectedGroup(selectedGroupFromStore)
                } else {
                    setSelectedGroup(validGroups[0]);
                }
            } catch (error: any) {
                console.error(error?.response?.data);
                setSpinner(false)
            }
        }

        getGroups()
      }, [system_id, searchText, groupStateChanged, stateChange]);

      React.useEffect(() => { setSelectedGroup(undefined) }, [system_id])


      /* Modals */
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false); // remove modal state
    const [showStartStopModal, setShowStartStopModal] = React.useState<boolean>(false); // startstop modal state

    const openModal = (group: Group) => {
        if(group.running) {
            return toast.error("A csoport jelenleg aktív!")
        }

        setSelectedGroup(group);
        setShowRemoveModal(true)
    };

    const [selectedStatus, setSelectedStatus] = React.useState<Boolean>(false)
    const openStartStopModal = (group: Group, status: Boolean) => {
        setSelectedStatus(status);
        setShowStartStopModal(true)
    }

    const closeModal = () => {
        setSelectedGroup(undefined);
        setShowRemoveModal(false)
        setShowStartStopModal(false)
    };

    function handleStateChange() {
        setStateChange(!stateChange)
    }

    // sorting algorithms

    const sortGroups = (by: string) => {
        switch(by) {
            case 'name': 
                if (!isOrderedAtoZ(groups)) {
                    const orederGroups = [...groups].sort((a, b) => a.group_name > b.group_name ? 1 : -1);
                    setGroups(orederGroups)
                } else {
                    const orederGroups = [...groups].sort((a, b) => a.group_name > b.group_name ? -1 : 1);
                    setGroups(orederGroups)
                }
                return;
            default:
                return;
        }
    }

    function isOrderedAtoZ(arr: Group[]) {
        for (let i = 1; i < arr.length; i++) {
            if (arr[i].group_name < arr[i - 1].group_name) {
            return false;
            }
        }
        return true;
    }

    return (
        <>
        <RemoveGroupModal title="Csoport törlése" showModal={showRemoveModal} closeModal={closeModal} group={selectedGroups} handleStateChange={handleStateChange}/>
        <StartStopModal status={selectedStatus} title={`${selectedStatus ? 'Csoport leállítása' : 'Csoport elindítása'}`} showModal={showStartStopModal} closeModal={closeModal} group={selectedGroups} handleStateChange={handleStateChange}/>
        {(groups && !spinner) && ( 
            <>
            <AddMember group={selectedGroups}/>
            <div className="flex space-x-4">
            
                <div className="w-2/4">
                <h1 className='mb-2 text-center text-xl'>A csoportok listája: </h1>
                    <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                    <table className="divide-y w-full shadow-lg text-sm border-white border-[10px] shadow-lg divide-gray-600">
                        <thead className="bg-dark-blue sticky top-0">
                            <tr>
                                <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center uppercase text-cream">ID</th>
                                <th onClick={() => sortGroups("name")} scope="col" className="hover:text-brown hover:underline cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center uppercase text-cream">Csoport neve</th>
                                <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center uppercase text-cream">Állapot</th>
                                <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center uppercase text-cream">Létszám</th>
                                <th scope="col" className="p-4"><span className="sr-only">Kezelés</span></th>
                                <th scope="col" className="p-4"><span className="sr-only">Törlés</span></th>
                            </tr>
                        </thead>
                        
                        <tbody className="bg-dark-blue divide-y divide-gray-600">
                            {groups.map((group, index) => ( 
                                <tr key={index} className={`bg-dark-blue hover:bg-light-blue cursor-pointer ${selectedGroups?.group_id === group.group_id ? `bg-light-blue` : `bg-dark-blue`}`} onClick={() => handleGroupSelection(group)}>
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap text-white">{group.group_id}</td>
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap text-white">{group.group_name}</td>
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap text-white">
                                        {
                                            group.running ? 
                                                <span className="inline-block w-4 h-4 rounded-full bg-green-500"></span>
                                            : 
                                                <span className="inline-block w-4 h-4 rounded-full bg-red-500"></span> 
                                        }
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap text-white">{group.memberAmount} fő</td>
                                    
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap text-white">
                                        <button onClick={() => openStartStopModal(group, group.running)} className={`${group.running ? 'text-error' : 'text-success'} hover:underline`}>{group.running ? 'Stop' : 'Start'}</button>
                                    </td>
                                        
                                    <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap"><button onClick={() => openModal(group)} className="text-cream hover:underline">Törlés</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    
                    <div className="w-2/4">
                        {spinner ? <Spinner width={12} height={12} show={spinner}/> : <GroupMemberList group={selectedGroups}/>}
                    </div>
                    
                    {(groups.length === 0 && !spinner) ? <p className="text-sm text-center mt-5 mb-3">Jelenleg még nincs aktív csoportod.</p> : null}
            </div>
            </>
        )}
        </>
    )
}
