import { Envelope } from "../models/envelope.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getEnvelopeList = (archivedOpenList: boolean): Promise<Envelope[]> => {
    return new Promise<Envelope[]>((resolve, reject) => {
        responseHero.get(
            `${process.env.REACT_APP_API_URL}/envelopelist/${archivedOpenList}`, {
            }).then((response) => {
            resolve(response.data as Envelope[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};