import { useEffect, useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { ModalPropsWithNewsID, ModalPropsWithSystemID, ModalPropsWithVideo } from "../../../types/modalProps";
import { Video } from "../../../models/video.model";
type ModifiedPayload = Pick<Video, "id" | "uri" | "description">
const ModifyVideoModal = ({ title, showModal, closeModal, handleStateChange, videos } : Omit<ModalPropsWithVideo, "user">) => {

    const [video, setVideo] = useState<ModifiedPayload>({
        id: -1, description: "", uri: ""
    });

    useEffect(() => {
        if(!videos) return;
        setVideo({
            id: videos?.id,
            description: videos?.description,
            uri: videos?.uri
        })
    }, [videos])

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setVideo(prevData => ({
            ...prevData,
            [type]: e.target.value
        }));
    };

    const updateVideo = async () => {
        await responseHero.put(`${process.env.REACT_APP_API_URL}/video/${video?.id}`, { video: video }).then((response) => {
            closeModal()
            handleStateChange()
            toast.success("Sikeres a hír frissítése!");
        }).catch(error => {
            toast.error("Sikertelen a hír frissítése!");
            console.error(error.response.data);
            closeModal()
        })
    }
    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl text-dark-blue font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <label className="mt-2 block text-gray-700 text-sm font-bold mb-2" htmlFor="video_uri">
                                    Videó elérhetősége (LINK)
                                </label>
                                    <input value={video?.uri} onChange={(e) => handleChange('uri', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Videó URL" id="video_uri"/>
                                <label className="mt-2 block text-gray-700 text-sm font-bold mb-2" htmlFor="video_description">
                                    Videó leírása
                                </label>
                                    <textarea value={video?.description} onChange={(e) => handleChange('description', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Videó leírás" id="video_description"/>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={updateVideo}
                        >
                            Közzététel
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default ModifyVideoModal;