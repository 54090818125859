import React, { useEffect, useState } from 'react';
import { getVotes } from '../../api/getVotes';
import { getVoteTypes } from '../../api/getVoteTypes';
import { VoteType } from '../../models/votetype.model';
import { Votes } from '../../models/vote.model';

export default function Vote() {
    const [voteTypes, setVoteTypes] = useState<VoteType[]>([]);
    const [votes, setVotes] = useState<Votes[]>([]);
    const [selectedVoteType, setSelectedVoteType] = useState<VoteType | undefined>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getVoteTypes();
                setVoteTypes(response);

                if (response.length > 0) {
                    setSelectedVoteType(response[0]);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchVotes = async () => {
            try {
                if (selectedVoteType?.id) {
                    const response = await getVotes(selectedVoteType.id);
                    setVotes(response);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchVotes();
    }, [selectedVoteType]);

    return (
        <div className="m-5 p-3">
            <div className="w-full overflow-x-auto text-white">
                {voteTypes.map((voteType, index) => (
                    <div key={index} className="text-center flex">
                        <button
                            className={`flex-1 text-md text-center p-2 ${
                                selectedVoteType?.id === voteType?.id
                                    ? 'bg-black text-cream font-semibold text-2xl'
                                    : 'bg-gray-700 text-gray-300'
                            } hover:bg-black font-semibold rounded-l`}
                            onClick={() => setSelectedVoteType(voteType)}
                        >
                            {voteType?.name}
                        </button>
                    </div>
                ))}
            </div>
            <div className='bg-gray-800 shadow-md rounded-md mt-3 p-3'> 
            {votes.map((vote, index) => (
                <p className='border-b py-2 text-center text-white text-md mb-2'>[{vote?.createdAt.toString().split('T')[0]}] <span className='text-cream'>{vote?.user?.name}</span> a következő lehetőséget választotta: <span className='text-cream'>{vote.opportunity}</span>. Az általa megadott összeg:  <span className='text-cream'>{vote.amount} EUR</span></p> 
            ))}
            </div>
        </div>
    );
}
