import React, { useState } from "react";
import { System } from "../../../models/system.model";
import { getTopList } from "../../../api/getTopList";
import { DaisyTopList } from "../../../models/types/DaisyTopList";
import { Systems } from "../../../enums/systems";
import { HumanTopList } from "../../../models/types/HumanTopList";
import { Spinner } from "../../../utils/Spinner";

type HumanTopListBoardProps = {
    group_id: number;
}

type SortKey = 'date' | 'rate' | 'invested_amount_eur';

export default function HumanTopListBoard ({ group_id } : HumanTopListBoardProps) {

    const [topList, setTopList] = React.useState<HumanTopList[]>([]);
    const [loader, setLoader] = React.useState<boolean>(false);


    const [sortConfig, setSortConfig] = useState<{ key: SortKey, direction: 'ascending' | 'descending' } | null>(null);

    React.useEffect(() => {
        setLoader(true);

        getTopList(Systems.HPM, group_id)
        .then((response) => {
            setTopList(response as HumanTopList[]);
            setLoader(false);
        })
        .catch((error) => {
            console.error(error);
            setLoader(false);
        });
        
    }, [group_id]);

    const sortedTopList = React.useMemo(() => {
        if (sortConfig !== null) {
            return [...topList].sort((a, b) => {
                let valueA, valueB;
                switch (sortConfig.key) {
                    case 'date':
                        valueA = new Date(a.depositData[0].date).getTime();
                        valueB = new Date(b.depositData[0].date).getTime();
                        break;
                    case 'rate':
                        valueA = parseFloat(a.assetState.result[0].rate);
                        valueB = parseFloat(b.assetState.result[0].rate);
                        break;
                    case 'invested_amount_eur':
                        valueA = a.depositData[0].invested_amount_eur;
                        valueB = b.depositData[0].invested_amount_eur;
                        break;
                    default:
                        return 0;
                }

                if (valueA < valueB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return topList;
    }, [topList, sortConfig]);

    const requestSort = (key: SortKey) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <>
        {loader ? <Spinner show={true} width={40} height={40}/> : 
        <div className="flex justify-center border p-3 m-2 mx-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                <thead className="bg-dark-blue text-cream">
                    <tr>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Név</th>
                        <th onClick={() => requestSort('date')} className="hover:text-white hover:underline hover:cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Befizetés dátuma</th>
                        <th onClick={() => requestSort('invested_amount_eur')} className="hover:text-white hover:underline hover:cursor-pointer  py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Befizetés (EUR)</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Befektetett (USD)</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Portfolio mai állása (USD)</th>
                        <th onClick={() => requestSort('rate')} className="hover:text-white hover:underline hover:cursor-pointer  py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Százalékos változás</th>
                    </tr>
                </thead>
                <tbody className="divide-y bg-dark-blue divide-gray-600">
                
                    {sortedTopList.map((record, index) => (
                        <React.Fragment key={index}>
                            {record.depositData.map((ddata, dindex) => (
                                <tr key={dindex} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {ddata.user_id}
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {ddata.user.name}
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {ddata.date.toString().split('T')[0]}
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {ddata.invested_amount_eur}
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {ddata.invested_amount_usdt}
                                    </td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        {record.assetState.result[dindex].portfolio_value}
                                    </td>
                                    <td className={`py-4 px-6 text-sm font-medium whitespace-nowrap ${parseFloat(record.assetState.result[dindex].rate) > 0 ? 'text-success' : 'text-error'}`}>
                                        {record.assetState.result[dindex].rate}
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
        }
        </>
    )
}