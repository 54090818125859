import React, { Fragment } from "react";
import { getMiningBeneficiaries } from "../../api/getMiningBeneficiaries";
import { MiningBeneficiaries } from "../../models/miningbeneficiaries.model";

export default function MiningBeneficiariesPanel () {
    const [miningBenefits, setMiningBenefits] = React.useState<MiningBeneficiaries[]>([]);
    const [selectedUserId, setSelectedUserId] = React.useState<number | null>(null);

    React.useEffect(() => {
        getMiningBeneficiaries()
        .then((response) => {
            setMiningBenefits(response)
        })
        .catch((error) => {
            console.error(error);
        }); 
    }, []);

    const handleUserClick = (userId: number) => {
        setSelectedUserId(userId);
    };

    const filteredRecords = selectedUserId
        ? miningBenefits.filter(record => record.user?.user_id === selectedUserId)
        : [];

    return (
        <Fragment>
            <div className="container mx-auto p-4">
                <h1 className="text-brown text-xl text-center mb-2">Kedvezmények listája: </h1>
                <div className="flex flex-wrap justify-center space-x-4 mb-4">
                    
                    {miningBenefits.map((record, index) => (
                        <button 
                            key={index} 
                            className={`p-2 m-2 ${selectedUserId === record.user?.user_id ? 'bg-dark-brown text-white' : 'bg-gray-300 text-black'}`} 
                            onClick={() => handleUserClick(record.user?.user_id)}
                        >
                            {record.user?.name} <span className="text-sm">({record.miningMachine?.name})</span>
                        </button>
                    ))}
                </div>

                {selectedUserId && (
                    <div className="flex justify-center border bg-dark-blue p-3 m-2 mx-auto">
                        <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                            <thead className="bg-dark-blue text-cream">
                                <tr>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Kedvezményezett neve</th>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bányagép azonosítója</th>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bányagép neve</th>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Kedvezmény indulási dátuma</th>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Kedvezmény befejezési dátuma</th>
                                    <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Kedvezmény típusa</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y bg-dark-blue divide-gray-600">
                                {filteredRecords.map((record, index) => (
                                    <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                            {record.user?.name}
                                        </td>
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                            {record?.miningMachineId}
                                        </td>
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                            {record?.miningMachine?.name}
                                        </td>
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                            {record?.start_date.toString().split('T')[0]}
                                        </td>
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                            {record?.end_date.toString().split('T')[0]}
                                        </td>
                                        <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-gray-200">
                                            {record?.which_part ?
                                            `Mentességet élvez a ${record?.miningMachine?.name} energiaköltségei alól.`
                                            : `Részesül a ${record?.miningMachine?.name} termeléséből.`
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
