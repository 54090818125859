import { useNavigate } from "react-router";
import { Group } from "../../../models/group.model";
import { toast } from "react-toastify";

type GroupCreatorProps = {
    group: Group | undefined;
}

export default function AddMember ({ group } : GroupCreatorProps) {
    const navigate = useNavigate();
    return (
        <>
        {(group) && (
            <div className="mb-3 border w-fit items-center text-sm font-medium border border-gray-200 rounded-lg sm:flex h-8">
                <button 
                onClick={() => {
                    if(!group.running) {
                        navigate('/manager', { state: { group } })
                    } else {
                        return toast.warn("Előbb a csoport leállítására lesz szükség!")
                    }
                    
                }}
                className="px-3 py-2 text-white bg-dark-blue hover:underline rounded-lg">{group.running ? 'Új tag hozzáadásához, kérlek előbb állítsd le a csoportot!' : `Új tag felvétele ide: ${group ? group.group_name : "Nincs csoport kiválasztva"}`}</button>
            </div>
        )}
        </>
    )
}
