import axios from 'axios';
import { UserData } from '../store/reducers/userSlice';

// Create an Axios instance with default headers
export const responseHero = axios.create();

// Add a request interceptor
responseHero.interceptors.request.use(
  function (config) {
    // Retrieve the JWT token from your source (e.g., local storage, state management)

    // Get token from the storage
    const storedUser = localStorage.getItem('user');
    const token: UserData = storedUser ? JSON.parse(storedUser) : {
    userInfo: {
        id: -1,
        email: '',
        name: '',
    },
        accessToken: '',
    };
    
    if(token.accessToken !== '') {
        // Append the token to the 'Authorization' header
        config.headers['Authorization'] = `Bearer ${token.accessToken}`;
    }
    
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);