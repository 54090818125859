import { useEffect, useState } from "react";
import { ModalProps } from "../../../types/modalProps";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { selectUser } from "../../../store/reducers/userSlice";
import { useSelector } from "react-redux";

interface ModalPropsForInvestment extends ModalProps {
    group_id: number | undefined;
    user_id: number | undefined;
    currentValue: number | undefined;
}

const SetInvestedMoney = ({ currentValue, title, group_id, user_id, showModal, closeModal, handleStateChange } : Omit<ModalPropsForInvestment, "user">) => {

    const [amount, setAmount] = useState<number>(0)
    const user = useSelector(selectUser)

    useEffect(() => { 
        if(!currentValue) return;
        setAmount(currentValue)
    }, [currentValue])
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const parsedValue = parseInt(inputValue, 10);
    
        if (inputValue.trim() === '') {
            setAmount(0);
        } else if (!isNaN(parsedValue)) {
            setAmount(parsedValue);
        } else {
            console.error('Invalid input value');
        }
    }

    const setInvestmentAmount = async () => {
        await responseHero.put(`${process.env.REACT_APP_API_URL}/invest`, {
            user_id, group_id, invested: amount
        }).then((response) => {
            closeModal()
            handleStateChange()

            toast.success("Befektetés rögzítve!");
        }).catch(error => {
            toast.error("Sikertelen a befektetés rögzítése!");
            console.error(error.response.data);
            closeModal()
        })
    }

    return (
    <>
        {(showModal && group_id && user_id) &&(
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="g_name">
                                Befektetés összege
                            </label>
                                <input value={amount.toString()} onChange={handleInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="g_name" type="number" placeholder="Csoport neve"/>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={setInvestmentAmount}
                        >
                            Megad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default SetInvestedMoney;