import jwt_decode, { JwtPayload } from "jwt-decode";

interface DecodedJwtPayload extends JwtPayload {
  isExpired: boolean;
}

export const decodeJwtToken = (token: string): DecodedJwtPayload | null => {
    try {
      const decodedToken = jwt_decode(token) as DecodedJwtPayload;

      const expirationDate = decodedToken.exp ? new Date(decodedToken.exp * 1000) : null; // Check if exp property exists
      const isExpired = expirationDate ? expirationDate < new Date() : false; // Check if expirationDate is defined
      
      return {
        ...decodedToken,
        isExpired: isExpired,
      };
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return null;
    }
  };