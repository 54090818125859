import { Crypto } from "../../../../models/crypto.model";
import { Asset } from "../../../../models/assets.model";
import React from "react";
import { calculateAvarageExchangeRateOfLatestTransactions, getPercentage } from "./AssetTradingSettings";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { getCryptoRecords } from "../../../../api/getHumanDCFunctions";
import LiqLevelsModal from "../../modals/tradinghelper/BuyLevelChangeModal";
import { getCurrentLiquidityValue } from "../../../../api/getCurrentLiquidityValue";

type AssetsMenuProps = {
  selectedAsset: Asset | undefined;
  takenRecords: Crypto[];
}

export default function AssetSellPanel({ selectedAsset, takenRecords } : AssetsMenuProps) {

  const [exchangeRise, setExchangeRise] = React.useState<number>(10);
  const [total, setTotal] = React.useState({ quantity: 0, value: 0 });

  const menu = useSelector(selectMenuStates);
  
  React.useEffect(() => {
    if(selectedAsset && menu?.daisyMenu) {
        getCryptoRecords(menu?.daisyMenu, selectedAsset?.id)
        .then((response) => {
            setTotal({
                quantity:  response.quantity,
                value:  response.value
            })
        })
        .catch((error) => {
            console.error(error);
        });
      }
    }, [selectedAsset, menu?.daisyMenu]);

  const calculateExchangeRise = () => {
      if(takenRecords.length === 0 && exchangeRise <= 0) return 0;
      return parseFloat((calculateAvarageExchangeRateOfLatestTransactions(takenRecords) * (1 + (exchangeRise / 100))).toFixed(2));
  }

  return (
    <>
      <div className="mt-5">
        
        <div className="bg-white rounded shadow-sm shadow-gray-400 flex space-x-4 px-2 py-2 items-center">
            <label className="text-lg text-dark-blue">Hány százalékos <strong>árfolyamemelkedést</strong> nézzünk? </label>
            <input onChange={(e) => setExchangeRise(parseInt(e.target.value, 10))} value={exchangeRise} className="text-center  border w-max text-lg" type="number" max={10} min={1}></input>   
            <label className="text-lg text-dark-blue">%</label>
            <button className="text-center px-2 py-2 bg-dark-blue text-white hover:opacity-100 opacity-80"
            onClick={() => alert('Mentve')}
            >Változtatás</button>
        </div>

        <div className="bg-white rounded shadow-sm shadow-gray-400 mt-2 flex space-x-4 px-2 py-2">
            <p className="text-xl">Jelenleg rendelkezésre álló <span className="text-brown">{selectedAsset?.name}</span> készlet: <span className="font-semibold">{total.quantity} db</span>, melynek {exchangeRise}% -a <span className="text-dark-brown">{getPercentage(total.quantity, exchangeRise)} db</span></p>
        </div>

        {(takenRecords.length > 0 && exchangeRise > 0) && (
            <div className="bg-white rounded shadow-sm shadow-gray-400 mt-2 flex space-x-4 px-2 py-2">
                <p className="text-xl">{exchangeRise}% -os növekedésnél az árfolyam: <span className="font-semibold">{calculateExchangeRise()}$</span></p>
            </div>
        )}
      </div>
    </>
  );
}