import React, { useEffect, useState } from "react"
import GroupList from "../subcomponents/GroupList"
import GroupCreator from "./GroupCreator"
import { System } from "../../../models/system.model"
import { responseHero } from "../../../utils/axiosInterceptor"
import { Spinner } from "../../../utils/Spinner"
import { Systems } from "../../../enums/systems"

export default function SearchBar () {
    const [searchText, setSearchText] = React.useState<string>('')
    const [searchOption, setSearchOption] = useState<number>(0);
    const [systems, setSystems] = React.useState<System[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(true);

    const getSys = async () => {
        await responseHero.get(`${process.env.REACT_APP_API_URL}/systems`).then((response) => {
            setSystems(response.data);
            setSpinner(false)
        }).catch(error => {
            console.error(error.response.data);
            setSpinner(false)
        })
    }

    const handleSearchOption = (searchOption: number) => {
        setSearchOption(searchOption)
        localStorage.setItem("searchOption", searchOption.toString())
    }

    /* This is a utility function for interactive group updating */
    const [groupStateChange, setGroupStateChange] = useState(false)
    const handleNewGroupAdded = () => {
        setGroupStateChange(!groupStateChange)
    }

    /* Effects */
    React.useEffect(() => {
        getSys();
    }, [])

    return (
        <> 
        <Spinner show={spinner} width={12} height={12}/>
        {!spinner && (
            <>
            <div className="flex mb-5">
            <ul className="w-fit mr-3 items-center text-sm font-medium border border-gray-600 rounded-lg sm:flex bg-dark-blue text-white">
                <label className="ml-2">Csoport kereső: </label>
                <input
                    className="ml-2 px-2 py-1 border rounded focus:outline-none text-dark-blue"
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                {systems.map((sys, index) => (
                <li
                    key={sys.id}
                    className={`${
                    index !== systems.length - 1 ? 'mr-2 border-b sm:border-b-0 sm:border-r dark:border-gray-600 text-cream' : ''
                    }`}
                >
                    <div className="flex items-center pl-3">
                    <input
                        id={sys.name}
                        value={sys.id}
                        checked={searchOption === sys.id}
                        onChange={(e) => handleSearchOption(parseInt(e.target.value, 10))}
                        type="radio"
                        className="focus:outline-none w-4 h-4"
                    />
                    <label
                        htmlFor={sys.name}
                        className="py-3 ml-2 mr-2 text-sm font-medium text-cream"
                    >
                        {sys.name}
                    </label>
                    </div>
                </li>
                ))}
            </ul>
            
            {searchOption !== 0 && (<GroupCreator system_id={searchOption} newGroupAdded={handleNewGroupAdded}/>)}
            </div>
            {searchOption !== 0 && (<GroupList system_id={searchOption} searchText={searchText} groupStateChanged={groupStateChange}/>)}
            </>
            )}
        </>
    )
}
