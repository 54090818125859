import { responseHero } from "../utils/axiosInterceptor";
import { Payout } from "../models/payout.model";

export const getRecordPayouts = (daisy_id: number | undefined): Promise<Payout[]> => {
    return new Promise<Payout[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/payout/${daisy_id}`).then((response) => {
            resolve(response.data as Payout[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getPayoutsByGroup = (group_id: number | undefined): Promise<Payout[]> => {
    return new Promise<Payout[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/grouppayouts/${group_id}`).then((response) => {
            resolve(response.data as Payout[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};