import { Group } from "../models/group.model";
import { UserPercentage } from "../models/types/UserPercentage";
import { responseHero } from "../utils/axiosInterceptor";

export const getDaisyPayoutPercentages = (group: Group | undefined): Promise<UserPercentage[]> => {
    return new Promise<UserPercentage[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisypercentages/${group?.group_id}`).then((response) => {
            resolve(response.data as UserPercentage[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};