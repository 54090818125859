import { CryptoRecords } from "../components/humandc/subcomponents/tables/crypto/FormContainer";
import { Crypto } from "../models/crypto.model";
import { CryptoDeposit } from "../models/cryptodeposit.model";
import { DepositRegister } from "../models/depositregister";
import { Group } from "../models/group.model";
import { MiningComputer } from "../models/miningcomputer.model";
import { PhysicalGold } from "../models/physicalgold.model";
import { DepositRegisterSelector } from "../models/types/DepositRegisterSelector";
import { responseHero } from "../utils/axiosInterceptor";

export const getDepositRecords = (group: Group | undefined): Promise<DepositRegister[]> => {
    return new Promise<DepositRegister[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/deposit/${group?.group_id}`).then((response) => {
            resolve(response.data as DepositRegister[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getDepositRecordById = (id: number): Promise<DepositRegister> => {
    return new Promise<DepositRegister>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/depositById/${id}`).then((response) => {
            resolve(response.data as DepositRegister);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getCryptoRecords = (group: Group | undefined, asset_id: number | undefined): Promise<CryptoRecords> => {
    return new Promise<CryptoRecords>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/crypto/${group?.group_id}/${asset_id}`).then((response) => {
            resolve(response.data as CryptoRecords);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getPhysicalGoldRecords = (group: Group | undefined): Promise<PhysicalGold[]> => {
    return new Promise<PhysicalGold[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/physicalgold/${group?.group_id}`).then((response) => {
            resolve(response.data as PhysicalGold[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getMiningRecords = (group: Group | undefined): Promise<MiningComputer[]> => {
    return new Promise<MiningComputer[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/mining/${group?.group_id}`).then((response) => {
            resolve(response.data as MiningComputer[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getFreeCryptoDeposits = (group: Group | undefined, assetType: number): Promise<DepositRegisterSelector[]> => {
    return new Promise<DepositRegisterSelector[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/freedeposits/${group?.group_id}/${assetType}`).then((response) => {
            resolve(response.data as DepositRegisterSelector[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getCryptoDepositByGroupAndAsset = (group: Group | undefined, assetType: number): Promise<CryptoDeposit[]> => {
    return new Promise<CryptoDeposit[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/cryptoDeposit/${group?.group_id}/${assetType}`).then((response) => {
            resolve(response.data as CryptoDeposit[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};