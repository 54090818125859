import { Group } from "../models/group.model";
import { HumanSummarize } from "../models/types/HumanSummarize";
import { responseHero } from "../utils/axiosInterceptor";

export const getGroupSumData = (group: Group | undefined): Promise<HumanSummarize> => {
    return new Promise<HumanSummarize>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/dashboard/${group?.group_id}`).then((response) => {
            resolve(response.data as HumanSummarize);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};