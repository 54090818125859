import { Daisy } from "../models/daisy.model";
import { Group } from "../models/group.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getDaisyRecords = (group: Group | undefined): Promise<Daisy[]> => {
    return new Promise<Daisy[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisy/${group?.group_id}`).then((response) => {
            resolve(response.data as Daisy[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};