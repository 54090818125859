import { responseHero } from "../utils/axiosInterceptor";
import { MiningGroupStats } from "../types/miningGroupStats";

export const getMiningGroupStats = (group_id: number): Promise<MiningGroupStats> => {
    return new Promise<MiningGroupStats>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/miningGroupStats/${group_id}`).then((response) => {
            resolve(response.data as MiningGroupStats);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};