import React, { useEffect, useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    temporaryDataId: number;
    prevPercentage: string;
}

const ChangePercModal = ({ title, showModal, closeModal, handleStateChange, temporaryDataId, prevPercentage } : ModalProps) => {

    const [percentage, setPercentage] = useState<string>("");

    function isInteger(value: string) {
        return !isNaN(parseInt(value, 10));
    }

    const modifyRecord = async () => {
        try {

            if(!percentage || !isInteger(percentage)) return toast.warn("Hibás százalék érték!");


            await responseHero.put(`${process.env.REACT_APP_API_URL}/tempdata/${temporaryDataId}`, { record: { percentage: parseInt(percentage, 10) } });
            closeModal();
            handleStateChange();
            toast.success(`Sikeresen létrehoztad a rekordot!`);
        } catch (error) {
            closeModal();
            toast.error(`Sikertelen adatrögzítés!`);
        }
    }
    
    useEffect(() => { setPercentage(prevPercentage) },[ prevPercentage ])

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl font=semibold"><span className="text-brown">Ideiglenes adatok</span> - {title}</h3>
                        </div>
                        <div className="relative p-6">
                            <form>
                                <div className="rounded shadow-md px-2 py-2">
                                    <label className="text-sm font-bold mt-5 text-brown text-center mr-2" htmlFor="crypto_1">
                                    Csoport jelenlegi állása százalékban: 
                                    </label>
                                    <input
                                        value={percentage}
                                        onChange={(e) => setPercentage(e.target.value)}
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="text" placeholder="Százalék"/>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={modifyRecord}
                        >
                            Módosítás
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default ChangePercModal;