import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { REMOVE_SELECTED_USER } from "../../store/reducers/selectedUserSlice";
import { toast } from "react-toastify";
import { useState } from "react";
import { Template } from "../../models/templates.model";
import React from "react";
import { Organization } from "../../models/organization.model";
import { REMOVE_SELECTED_ORG } from "../../store/reducers/selectedOrgSlice";

type OrgContractConnectionManagerProps = {
    template: Template | undefined;
    color: string;
    title: string;
    org: Organization | undefined;
}

export default function OrgContractConnectionManager ({ template, color , title, org} : OrgContractConnectionManagerProps) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [stateChange, setStateChange] = useState<boolean>(false)
    const [showModifyModal, setShowModifyModal] = useState<boolean>(false)

    const openModal = () => setShowModifyModal(true);
    const closeModal = () => setShowModifyModal(false);

    const handleStateChange = () => {
        setStateChange(!stateChange)
    }

    React.useEffect(() => {

    }, [org])

    return (
        <>
        
        {template && (
            <div className="mb-3 w-fit items-center text-sm font-medium rounded-lg sm:flex h-8 mt-5">
                <button 
                onClick={() => navigate('/orgmanager', { state: { template } })}
                className={`hover:text-cream text-white bg-${color} font-bold uppercase text-sm px-3 py-1 mt-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}>{title}</button>
            {/* Ha van kiválasztott cég */}
            {org && (
                <>
                    <button 
                    onClick={() => {
                        openModal()
                    }}
                    className={`hover:text-cream text-white bg-${color} font-bold uppercase text-sm px-3 py-1 mt-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}>Cégadatok megtekintése</button>
                
                    <button 
                    onClick={() => {
                        dispatch(REMOVE_SELECTED_ORG())
                        toast.success('Cég eltávolítva a szerződésről!')
                    }}
                    className={`hover:text-cream text-white bg-error font-bold uppercase text-sm px-3 py-1 mt-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}>Cégadatok törlése</button>
                </>
            )}
            </div>
        )}
        </>
    )
}