import React, { ChangeEvent } from "react";
import { useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { MiningEnergy } from "../../../models/miningenergy.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    miningMachineIds: number[] | undefined;
}

type ModifiedPayload = Pick<MiningEnergy, 
"kwh" |
"price" |
"date" |
"paidby">

const AddNewEnergyCostModal = ({ title, showModal, closeModal, handleStateChange, miningMachineIds } : ModalProps) => {

    const [isValueDistributed, setValueDistribution] = React.useState<boolean>(false);

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        kwh: 180,
        price: 180,
        date: new Date(),
        paidby: false,
    });

    const [selectedOption, setSelectedOption] = useState('Human D.C fizette');

    const handleDistributionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);

        setRecordData((prevData) => ({
            ...prevData,
            paidby: event.target.value === 'Human D.C fizette' ? false : true
        }))
    };

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let inputValue = e.target.value;
        let parsedValue = parseInt(inputValue, 10);
    
        setRecordData((prevData) => {
            let newValue = (type === "kwh" || type === "price") ? parsedValue : inputValue;
            if (type === "price") {
                return {
                    ...prevData,
                    price: parsedValue,
                    kwh: Math.round((parsedValue / 6) * 61)
                } as ModifiedPayload;
            } else if (type === "kwh") {
                return {
                    ...prevData,
                    kwh: parsedValue,
                } as ModifiedPayload;
            } else {
                return {
                    ...prevData,
                    [type]: newValue
                } as ModifiedPayload;
            }
        });
    }
    
    
          
    
    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/miningEnergy`, { miningEnergy: recordData, miningMachineIds: miningMachineIds, copyValues: isValueDistributed }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy/több energiaköltséget!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    const handleDsChange = () => {
        setValueDistribution(!isValueDistributed);
    };

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">BÁNYAGÉP</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">

                                     <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Bányagépek </span> <br/>Rögzítés dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagépek </span> <br/>Fogyasztás (KW/h)
                                        </label>
                                        <input
                                            value={recordData?.kwh}
                                            onChange={(e) => handleChange('kwh', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="number" placeholder="Fogyasztás (kw/h)"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Bányagépek </span> <br/>Összeg ($)
                                        </label>
                                        <input
                                            value={recordData?.price}
                                            onChange={(e) => handleChange('price', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="number" placeholder="Összeg ($)"/>
                                    </div>

                                    
                                </div>
                                <div className="p-5 flex flex-row text-center align-center justify-center">
                                    <p className="text-md">A megadott KWH mennyiség <span className="text-brown">{(recordData.kwh / 61).toFixed(2)}</span> nap üzemidőre elegendő. </p>
                                </div>
                                <div className="p-5 flex flex-row text-center align-center justify-center">
                                    <label className="text-lg">A bevitt értékek másolása (nincs szétosztás): </label>
                                    <input className="h-6 w-6 ml-2" type="checkbox" checked={isValueDistributed} onChange={handleDsChange} />
                                </div>
                                <div className="p-5">

                                    <label className="block text-sm font-bold mt-2 mb-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Bányagépek </span> <br/>Részesedés elosztása
                                    </label>

                                    <div className="flex flex-col space-y-2">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="Human D.C fizette"
                                                checked={selectedOption === 'Human D.C fizette'}
                                                onChange={handleDistributionChange}
                                                className="text-blue-600 form-radio h-5 w-5"
                                            />
                                            <span className="ml-2 text-gray-700">Ezt az energiafogyasztást a <span className="text-dark-brown">Human D.C</span> fizette.</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="Ügyfelek fizették"
                                                checked={selectedOption === 'Ügyfelek fizették'}
                                                onChange={handleDistributionChange}
                                                className="text-blue-600 form-radio h-5 w-5"
                                            />
                                            <span className="ml-2 text-gray-700">Ezt az energiafogyasztást az <span className="text-dark-brown">Ügyfelek</span> fizették.</span>
                                        </label>
                                    </div>
                                    <p className="mt-4 text-blue-500">Kiválasztva: {selectedOption}</p>
                                </div>
                                
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewEnergyCostModal;