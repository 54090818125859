import React from "react";
import { getSys } from "../../api/getSys";
import { System } from "../../models/system.model";
import DepositRegister from "../../components/common/depositregister/DepositRegister";
import { Systems } from "../../enums/systems";

export default function DepositRegisterPage() {
    const [systems, setSystems] = React.useState<System[]>([]); 
    const [selectedSystem, setSelectedSystem] = React.useState<System>();

    React.useEffect(() => {
        getSys()
            .then((response) => {
                setSystems(response.filter((s) => s.id !== Systems.HPM)); // ignore human dc

                if (response.length > 0) {
                    setSelectedSystem(response[0]); // change it to default
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className="m-5 p-3">
            <div className="w-full overflow-x-auto text-white">
                <div className="text-center flex">
                    {systems.map((system, index) => (
                        <button
                            key={index}
                            className={`flex-1 text-md text-center p-2 ${
                                selectedSystem?.id === system.id ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                            } hover:bg-black font-semibold border-l border-r border-white`}
                            onClick={() => setSelectedSystem(system)}
                        >
                            {system.name}
                        </button>
                    ))}
                </div>
            </div>
            
            <DepositRegister system={selectedSystem}/>
        </div>
    );
}
