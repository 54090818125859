import { News } from "../models/news.model";
import { Video } from "../models/video.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getVideo = (news: News | undefined): Promise<Video[]> => {
    return new Promise<Video[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/videos/${news?.id}`).then((response) => {
            resolve(response.data as Video[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};