import React from "react"
import { Company } from "../../models/company.model"
import { getCompanyDetails } from "../../api/getCompanyDetails";
import { toast } from "react-toastify";
import { responseHero } from "../../utils/axiosInterceptor";

export default function CompanyDetails () {

    const [companyParams, setCompanyParams] = React.useState<Company>({
        company_name: "",
        hq: "",
        license_number: "",
        right_status: "",
        created: new Date(),
        localization_number: "",
        building_name: "",
        area_name: "",
        bank_name: "",
        iban: "",
        swift: "",
        bank_number: "",
        account_number: "",
        kozlemeny: "",
        account_owner: ""
    })

    React.useEffect(() => {
        getCompanyDetails()
        .then((response) => {
            setCompanyParams(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [])

    const handleChange = (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyParams(prevData => ({
            ...prevData,
            [type]: e.target.value
        }));
    };

    const updateCompanyDetails = async () => {
        try {
            await responseHero.put(`${process.env.REACT_APP_API_URL}/company`, {
                company_details: companyParams
            }).then((response) => {
                toast.success("Cégadatok frissítve!");
            }).catch(error => {
                toast.error('Sikertelen módosítási kísérlet!');
            })
        } catch (error) {
            console.error(error);
            toast.error('Sikertelen módosítási kísérlet!');
        }
    }

    return (
        
        <div className="m-5 border-2 p-3">
        {!companyParams ? <p className="text-error text-center text-lg">Hiba lépett fel a cégadatok lekérdezése közben!</p> :
        <>
            <div className="m-auto w-2/4">
                <form>
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="company_name">
                        Cég neve
                    </label>
                        <input value={companyParams?.company_name} onChange={(e) => handleChange('company_name', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cég neve"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="hq">
                        Székhely
                    </label>
                        <input value={companyParams?.hq} onChange={(e) => handleChange('hq', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Székhely"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="license_number">
                        Licensz száma
                    </label>
                        <input value={companyParams?.license_number} onChange={(e) => handleChange('license_number', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Licensz száma"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="right_status">
                        Jogi státusz
                    </label>
                        <input value={companyParams?.right_status} onChange={(e) => handleChange('right_status', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Jogi státusz"/>
                     
                     <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="created">
                        Bejegyzés dátuma
                    </label>
                        <input type="date" value={companyParams?.created.toString().split('T')[0]} onChange={(e) => handleChange('created', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" placeholder="Jogi státusz"/>

                     <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="localization_number">
                        Helyiség szám
                    </label>
                    <input value={companyParams?.localization_number} onChange={(e) => handleChange('localization_number', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Helyiség száma"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="building_name">
                        Épület neve
                    </label>
                    <input value={companyParams?.building_name} onChange={(e) => handleChange('building_name', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Épület neve"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="area_name">
                        Körzet neve
                    </label>
                    <input value={companyParams?.area_name} onChange={(e) => handleChange('area_name', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Körzet neve"/>

                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="bank_name">
                        Bank neve
                    </label>
                    <input value={companyParams?.bank_name} onChange={(e) => handleChange('bank_name', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Bank neve"/>

                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="iban">
                        IBAN szám
                    </label>
                    <input value={companyParams?.iban} onChange={(e) => handleChange('iban', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="IBAN szám"/>
                
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="acc_number">
                        Account number
                    </label>
                    <input value={companyParams?.account_number} onChange={(e) => handleChange('account_number', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Account number"/>

                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="acc_owner">
                        Account owner
                    </label>
                    <input value={companyParams?.account_owner} onChange={(e) => handleChange('account_owner', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Account owner"/>

                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="bank_number">
                        Bank number
                    </label>
                    <input value={companyParams?.bank_number} onChange={(e) => handleChange('bank_number', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Bank number"/>
                    
                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="kozlemeny">
                        Közlemény
                    </label>
                    <input value={companyParams?.kozlemeny} onChange={(e) => handleChange('kozlemeny', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Közlemény"/>

                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="swift">
                        Swift code
                    </label>
                    <input value={companyParams?.swift} onChange={(e) => handleChange('swift', e)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="card" type="text" placeholder="Swift"/>

                </form>
            </div>
            
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
                onClick={updateCompanyDetails}
                className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
            >
                Módosít
            </button>
        </div>
        </>
        }
        </div>
    )
}
