import { System } from "../models/system.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getSys = (): Promise<System[]> => {
    return new Promise<System[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/systems`).then((response) => {
            resolve(response.data as System[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};