import React, { useState } from "react";
import { System } from "../../../models/system.model";
import { getTopList } from "../../../api/getTopList";
import { DaisyTopList } from "../../../models/types/DaisyTopList";
import { Systems } from "../../../enums/systems";


export default function DaisyTopListBoard () {

    const [topList, setTopList] = React.useState<DaisyTopList[]>([]);
    
    React.useEffect(() => {
        getTopList(Systems.DAISY, -1)
        .then((response) => {
            setTopList(response as DaisyTopList[]);
        })
        .catch((error) => {
            console.error(error);
        });
        
    }, [])

    return (
        <>
        <div className="flex justify-center border p-3 m-2 mx-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                <thead className="bg-dark-blue text-cream">
                    <tr>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Név</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Csoport neve</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Befizetett összeg (USD)</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Termelt profit (USD)</th>
                        <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Százalékos változás</th>
                    </tr>
                </thead>
                <tbody className="divide-y bg-dark-blue divide-gray-600">
                
                    {topList.map((record, index) => (
                        <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                {record.user_id}
                            </td>
                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                {record.user_name}
                            </td>

                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                {record.group_name}
                            </td>

                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                {record.deposit}$
                            </td>

                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                {record.from_start}$
                            </td>

                            <td className={`py-4 px-6 text-sm font-medium whitespace-nowrap 
                                ${parseFloat(record.progress) > 100 ? 'text-cream' : 'text-white' }`}>
                                {record.progress}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    )
}