import React from "react";
import FormContainer from "./tables/dashboard/FormContainer";
import { getGroupSumData } from "../../../api/getGroupSumInvested";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
import { HumanSummarize } from "../../../models/types/HumanSummarize";
import AddNewRecordModal from "../modals/liqhistory/AddNewRecordModal";

export default function Dashboard() {

    const menu = useSelector(selectMenuStates)
    const [data, setData] = React.useState<HumanSummarize>();

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [stateChange, setStateChange] = React.useState<boolean>(false);

    const closeModal = () => {
        setShowModal(false);
    }

    const handleStateChange = () => {
        setStateChange(!stateChange);
    }

    React.useEffect(() => {
        getGroupSumData(menu?.daisyMenu)
        .then((response) => {
            setData(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, stateChange])

  return (
    <>
        <AddNewRecordModal title={"Likviditás frissítése"} group={menu?.daisyMenu} showModal={showModal} closeModal={closeModal} handleStateChange={handleStateChange} currentAmount={data?.lastLiq?.amount}/>

        <div className="p-3">
            <div className="flex space-x-4">
                <div className="px-4 py-2 rounded bg-white shadow-md w-1/4">
                    <h1 className="text-center text-2xl text-dark-blue mb-2">Összegzés</h1>
                    <p className="text-lg text-gray-500 text-center">A csoportba összesen befizetett összeg: <span className="text-brown">{data?.totalInvested?.toLocaleString('en-En')?.replace(',', ' ')}$</span></p>
                    
                    {data?.lastLiq && (
                        <>
                            <p className="text-lg text-gray-500 text-center">A csoport jelenlegi likviditása <span className="text-dark-blue font-semibold">({data?.lastLiq?.date?.toString()?.split('T')[0]})</span>:</p>
                            <p className="text-brown text-2xl text-center">{data?.lastLiq?.amount?.toLocaleString('en-En')?.replace(',', ' ')}$</p>
                        </>
                    )}

                    <div className="flex items-center justify-center">
                        <button onClick={() => setShowModal(true)} className="text-center m-auto text-md px-2 py-2 bg-brown text-white opacity-80 hover:opacity-100">
                            Likviditás változtatása
                        </button>
                    </div>
                    
                </div>

                <div className="px-4 py-2 rounded bg-white shadow-md w-3/4">
                    <FormContainer/>
                </div>
            </div>
            
        </div>
    </>
  );
}