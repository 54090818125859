import React, { EventHandler } from "react"
import OrgList from "./OrgList"

const options = [
    { id: 1, name: 'full_name', label: 'cégnév' },
    { id: 2, name: 'email', label: 'email' },
    { id: 3, name: 'company_registration_number', label: 'cégjegyzékszám' }
]

export default function SearchBar () {
    const [searchText, setSearchText] = React.useState<string>('')
    const [searchOption, setSearchOption] = React.useState<string>("full_name")

    return (
        <>
        <div className="m-10">
        <div className="flex items-center mt-2">
                <ul className="w-full items-center text-sm font-medium rounded-lg sm:flex bg-dark-blue">
                <label htmlFor="search" className="ml-2 text-white">Kereső: </label>
                <input
                    className="ml-2 px-2 py-1 border border-white-300 rounded text-dark-blue focus:outline-none"
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                    {options.map((option, index) => (
                    <li
                        key={option.id}
                        className={`${
                        index !== options.length - 1 ? 'mr-2 border-b sm:border-b-0 sm:border-r border-white' : ''
                        }`}
                    >
                        <div className="flex items-center pl-3">
                        <input
                            id={option.name}
                            value={option.name}
                            checked={searchOption === option.name}
                            onChange={(e) => setSearchOption(e.target.value)}
                            type="radio"
                            className="focus:outline-none w-4 h-4 text-cream"
                        />
                        <label
                            htmlFor={option.name}
                            className="py-3 ml-2 mr-2 text-sm font-medium text-cream"
                        >
                            {option.label}
                        </label>
                        </div>
                    </li>
                    ))}
                </ul>
                </div>
                </div>
                <OrgList searchText={searchText} searchOption={searchOption}/>
        </>
    )
}
