
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_TAB, TabValues } from '../../store/reducers/hpmNavSlice';
import { selectHpmTabState } from '../../store/reducers/hpmNavSlice';

export default function TabHandler () {

    const dispatch = useDispatch();
    const selectedTab = useSelector(selectHpmTabState);

    const handleTabClick = (tabValue: TabValues) => {
        dispatch(SET_SELECTED_TAB(tabValue));
    };

    return (
        <div className="w-full overflow-x-auto text-white">
            <div className="text-center flex">
            <button
                className={`flex-1 text-md text-center p-2 ${
                selectedTab === TabValues.TAB_INV ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                } hover:bg-black font-semibold rounded-l`}
                onClick={() => handleTabClick(TabValues.TAB_INV)}
            >
                Befizetés nyilvántartás
            </button>

            <button
                className={`flex-1 text-md text-center p-2 ${
                selectedTab === TabValues.TAB_CRYPTO ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                } hover:bg-black font-semibold border-l border-r border-white`}
                onClick={() => handleTabClick(TabValues.TAB_CRYPTO)}
            >
                Crypto nyilvántartás
            </button>

            <button
                className={`flex-1 text-md text-center p-2 ${
                selectedTab === TabValues.TAB_GOLD ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                } hover:bg-black font-semibold border-l border-r border-white`}
                onClick={() => handleTabClick(TabValues.TAB_GOLD)}
            >
                Arany nyilvántartás
            </button>
            
            <button
                className={`flex-1 text-md text-center p-2 ${
                selectedTab === TabValues.TAB_TRADING_HELP ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                } hover:bg-black font-semibold rounded-r border-l`}
                onClick={() => handleTabClick(TabValues.TAB_TRADING_HELP)}
            >
                Kereskedési segédlet
            </button>

            <button
                className={`flex-1 text-md text-center p-2 ${
                selectedTab === TabValues.DASHBOARD ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                } hover:bg-black font-semibold rounded-r border-l`}
                onClick={() => handleTabClick(TabValues.DASHBOARD)}
            >
                Összesítés
            </button>
            </div>
      </div>
    )
}