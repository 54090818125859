import { useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { Crypto } from "../../../../models/crypto.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import React from "react";
import { CryptoDeposit } from "../../../../models/cryptodeposit.model";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    asset_id: number | undefined;
}

type ModifiedPayload = Pick<Crypto, 
"date" |
"operation" |
"quantity" |
"exchange_rate" |
"amount" |
"percentage_to_alt" |
"percentage_to_gold" |
"percentage_to_mining" |
"creatorId">

const AddNewRecordModal = ({ group, title, showModal, closeModal, handleStateChange, asset_id } : ModalProps) => {

    const user = useSelector(selectUser)
    const [operation, setOperation] = useState(true); // BUY

    const [depositRegistrations, setDepositRegistrations] = useState<CryptoDeposit[]>([])

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        date: new Date(),
        operation: true,
        quantity: "",
        exchange_rate: "",
        amount: 0,
        percentage_to_alt: 0,
        percentage_to_gold: 0,
        percentage_to_mining: 0,
        creatorId: user.userInfo.user_id,
    });

    /*
        BUY = true
        SELL = false
    */
    

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
        ) => {
        const inputValue = e.target.value;
        
        if (type === "exchange_rate" && /^(\d*\.)?\d{0,4}$/.test(inputValue)) {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: inputValue,
            }));
        } else if (type === "operation") {
            setOperation(!operation);
            setRecordData((prevData) => ({
            ...prevData,
            [type]: !operation,
            }));
        } else if (type === "quantity" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: inputValue,
            }));
        } else {
            setRecordData((prevData) => ({
            ...prevData,
            [type]: type === "date" ? inputValue : parseInt(inputValue),
            }));
        }
        };
          

    const makeRecord = async () => {

        if(!group) return;
        await responseHero.post(`${process.env.REACT_APP_API_URL}/humandc/crypto/${group.group_id}/${asset_id}`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    const handleDepositSelection = (id: number) => {
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <div className="flex p-2 rounded shadow-md">
                                <div className="w-2/4 pr-2 text-center items-center">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-brown">Dátum </span>
                                    </label>
                                    <input
                                        value={recordData?.date?.toString().split('T')[0]}
                                        onChange={(e) => handleChange('date', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                </div>

                                <div className="w-2/4 pr-2 text-center items-center">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-brown">Típus </span>
                                    </label>
                                    <div className="flex justify-center mt-2">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                value="option1"
                                                checked={operation === true}
                                                onChange={(e) => handleChange('operation', e)}
                                                className="form-radio h-5 w-5 text-brown"
                                            />
                                            <span className="ml-2 text-success">BUY</span>
                                        </label>
                                        <label className="inline-flex items-center ml-6">
                                            <input
                                                type="radio"
                                                value="option2"
                                                checked={operation === false}
                                                onChange={(e) => handleChange('operation', e)}
                                                className="form-radio h-5 w-5 text-brown"
                                            />
                                            <span className="ml-2 text-error">SELL</span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-2/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Árfolyam </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.exchange_rate}
                                            onChange={(e) => handleChange('exchange_rate', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Árfolyam USDT-ben"/>
                                    </div>
                                    {operation ? (
                                        <div className="w-2/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Összeg </span> <br/>USDT
                                        </label>
                                        <input
                                            value={recordData?.amount}
                                            onChange={(e) => handleChange('amount', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Összeg USDT-ben"/>
                                        </div>
                                    ) : (
                                        <div className="w-2/4 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Kriptoeszköz </span> <br/>Darabszám
                                        </label>
                                        <input
                                            value={recordData?.quantity}
                                            onChange={(e) => handleChange('quantity', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="text" placeholder="Kriptoeszköz darabszáma"/>
                                        </div>
                                    )}
                                    </div>

                                    {/*
                                    {operation && (
                                        <div className="flex p-2 rounded shadow-md">
                                            <div className="w-full pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 mb-2 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Befizetés </span> <br/> A befizetés nyilvántartó egyik befizetése
                                        </label>

                                        <select defaultValue={-1} onChange={(e) => handleDepositSelection(parseInt(e.target.value))} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                            <option disabled={false} value={-1} className="px-2 py-1 text-center">{"Csoporthoz"}</option>
                                            {depositRegistrations?.map((depositRegistration, index) => (
                                                <option value={depositRegistration.id} className="px-2 py-1 text-center" key={index}>{depositRegistration.user.name} ({depositRegistration.date.toString().split('T')[0]})</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                    )}
                                    */}

                                    {!operation && (
                                        <div className="flex p-2 rounded shadow-md">
                                        <div className="w-2/4 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Százalék </span> <br/>Arany
                                        </label>
                                        <input
                                            value={recordData?.percentage_to_gold}
                                            onChange={(e) => handleChange('percentage_to_gold', e)}
                                            min="1" max="100" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction" type="number" placeholder="Százalék"/>
                                        </div>
                                        <div className="w-2/4 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Százalék </span> <br/>Bányagép-energia
                                        </label>
                                        <input
                                            value={recordData?.percentage_to_mining}
                                            onChange={(e) => handleChange('percentage_to_mining', e)}
                                            min="1" max="100" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction" type="number" placeholder="Százalék"/>
                                        </div>
                                        <div className="w-2/4 pr-2 text-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Százalék </span> <br/>Altcoin
                                        </label>
                                        <input
                                            value={recordData?.percentage_to_alt}
                                            onChange={(e) => handleChange('percentage_to_alt', e)}
                                            min="1" max="100" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="deduction" type="number" placeholder="Százalék"/>
                                        </div>
                                        </div>
                                    )}
                                </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewRecordModal;