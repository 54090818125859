import React from "react";
import { getTempData } from "../../api/getTempData";
import { TemporaryData } from "../../models/tempdata.model";
import ChangePercModal from "../../components/alt/modals/ChangePercModal";
import { useNavigate } from 'react-router';

export default function TemporaryPage () {

    const [temps, setTemps] = React.useState<TemporaryData[]>([]);
    const [showModal, setShowModal] = React.useState(false);
    const [stateChange, setStateChange] = React.useState(false);

    const [selectedTempRow, setSelectedTempRow] = React.useState<number>(0);
    const [prevPercentage, setPrevPercentage] = React.useState<string>("");

    const navigate = useNavigate();

    React.useEffect(() => {
        getTempData()
        .then((response) => {
            setTemps(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [stateChange])

    const handleStateChange = () => {
        setStateChange(!stateChange);
    }

    const closeModal = () => { setShowModal(false) }

    const openModal = (temp: TemporaryData) => {
        setPrevPercentage(temp?.percentage.toString());
        setSelectedTempRow(temp.id);

        setShowModal(true);
    }
    
    return (
        <>
            <ChangePercModal prevPercentage={prevPercentage} title={"Százalék beállítása"} showModal={showModal} closeModal={closeModal} handleStateChange={handleStateChange} temporaryDataId={selectedTempRow}/>

            <div className="m-5 p-3">
                <h1 className="text-2xl text-center mb-5">Ideiglenes csoportállások beállítása</h1>
                <button onClick={() => navigate('/hpm')} className="bg-gray-400 rounded shadow-dark-blue shadow-sm px-2 py-2 text-dark-blue mb-5 hover:underline">Vissza a Human D.C felületre</button>
                {temps.map((temp, index) => (
                    <div className="px-2 py-2 border-b flex space-x-4" key={index}>
                        <p className="text-2xl text-brown">{temp?.group?.group_name}</p>
                        <p className="text-2xl">({temp?.percentage}%)</p>
                        <button onClick={() => openModal(temp)} className="hover:opacity-100 opacity-80 px-2 py-2 bg-dark-blue text-white rounded">Érték módosítása</button>
                    </div>
                ))}
            </div>
        </>
    )
}
