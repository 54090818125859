import React from "react"
import { responseHero } from "../../utils/axiosInterceptor"
import { useSelector } from "react-redux"
import { selectMenuStates } from "../../store/reducers/menuSlice"
import { selectUserState } from "../../store/reducers/selectedUserSlice"
import { selectNavStates } from "../../store/reducers/navSlice"
import { dateFormatter } from "../../utils/dateFormatter"
import {User} from '../../models/user.model'
import UserContractConnectionManager from "./UserContractConnectionManager"
import { toast } from "react-toastify"
import { Spinner } from "../../utils/Spinner"
import { Company } from "../../models/company.model"
import { getCompanyDetails } from "../../api/getCompanyDetails"
import { Organization } from "../../models/organization.model"
import SelectedUser from "./SelectedUser"
import { selectOrgState } from "../../store/reducers/selectedOrgSlice"
import SelectOrg from "./SelectOrg"
import OrgContractConnectionManager from "./OrgContractConnectionManager"

export default function ShowContractDetails () {

    const menu = useSelector(selectMenuStates)
    const nav = useSelector(selectNavStates)
    const select = useSelector(selectUserState)
    const org = useSelector(selectOrgState)

    const [spinner, setSpinner] = React.useState<boolean>(false)

    const [selectedUserDetails, setSelectedUserDetails] = React.useState<User>()
    const [selectedOrgDetails, setSelectedOrgDetails] = React.useState<Organization>()

    const [companyDetails, setCompanyDetails] = React.useState<Company>()
    const [currencyOption, setCurrencyOption] = React.useState<string>("EUR");
    const [amount, setAmount] = React.useState<number>(0);
    const [docuUri, setDocuUri] = React.useState<string>("");

    const currencies = ["EUR", "USD"];
    const [selectedCurrency, setSelectedCurrency] = React.useState<string>("EUR");

    const handleCurrencyOption = (currency: string) => {
        setSelectedCurrency(currency);
    };

    const handleAmountChanges = (amount: string) => {
        const validAmount = amount.replace(/[,.]/g, "");
        setAmount(parseInt(validAmount, 10))
    }

    const copyToClipBoard = () => {
        const dummyTextArea = document.createElement('textarea');
        dummyTextArea.value = docuUri;
        document.body.appendChild(dummyTextArea);
        dummyTextArea.select();
        // @ts-ignore
        document.execCommand('copy');
        document.body.removeChild(dummyTextArea);
    }

    /* Effects */
    React.useEffect(() => {
        setSelectedUserDetails(select)
        setSelectedOrgDetails(org)
    }, [select, org])

    React.useEffect(() => {
        getCompanyDetails()
        .then((response) => {
            setCompanyDetails(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [])

    if(!menu.templateMenu) return <p className="text-center text-dark-blue text-lg">Válassz egy szerződést, vagy hozz létre egy újat!</p>;

    const makeContract = async (user: User | undefined, org: Organization | undefined, company: Company | undefined, currency: string, amount: number) => {
        
        if(user && org) return toast.error('Hiba a szerződés küldése közben!');

        try {
            setSpinner(true)

            await responseHero.post(`${process.env.REACT_APP_API_URL}/makeEnvelope`, {
                user: user, org: org, company: company, template_ref_id: menu.templateMenu?.templateId, currency: currency, amount: amount
            }).then((response) => {
                setSpinner(false)
                toast.success('Sikeres a szerződésküldés!')
                setDocuUri(response.data.url)
            }).catch((error) => {
                console.error(error) 
                setSpinner(false)
            });
            
        } catch (error: any) {
            console.error(error?.response?.data);
            setSpinner(false)
        }
    }

    return (
        <>
            <div className="rounded-lg shadow-lg bg-dark-blue text-white py-2 px-2 flex">
                
                <div className="flex items-center">
                    <img src="/svg/contract.svg" width={85} height={85} className="mr-2 p-2" />
                    <div>
                        <h1 className="text-lg mb-2">Sablon neve: <span className="text-cream">{menu?.templateMenu?.name}</span></h1>
                        <p className="text-md">Sablon készült: <span className="text-cream">{dateFormatter(menu?.templateMenu?.created.toString() || '')}</span></p>
                        <p className="text-md">Sablon tárgya: <span className="text-cream">{menu?.templateMenu?.emailSubject}</span></p>
                        <p className="text-md">Sablon szövege: <span className="text-cream">{menu?.templateMenu?.emailBlurb}</span></p>
                        <p className="text-md">Sablon oldalainak száma: <span className="text-cream">{menu?.templateMenu?.pageCount}</span></p>
                        <p className="text-md">Sablon azonosítója: <span className="text-cream">{menu?.templateMenu?.templateId}</span></p>

                        <a href="https://www.docusign.com/" target="_blank">
                            <button
                                className="hover:bg-dark-brown text-white bg-brown font-bold uppercase text-sm px-3 py-1 mt-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                            >
                                Sablon módosítása
                            </button>
                        </a>

                    </div>
                </div>
            </div>
            <div className="flex flex-row mt-10">
                <div className="rounded-lg shadow-lg bg-dark-blue text-white py-2 px-2 w-2/4 mr-3">
                    <div className="flex items-center">
                        <img src="/svg/user.svg" width={85} height={85} className="mr-2 p-2" />
                        <div className="mt-2">
                            {menu?.templateMenu?.template_applicant === 0 ? (
                                <>
                                    <SelectedUser select={select}/>
                                    <UserContractConnectionManager user={selectedUserDetails} template={menu.templateMenu} title={select ? 'Felhasználó cseréje' : 'Felhasználó hozzárendelése'} color={select ? 'brown' : 'success'}/>
                                </>
                            ) : (
                                <>
                                    <SelectOrg select={org}/>
                                    <OrgContractConnectionManager template={menu.templateMenu} color={org ? 'brown' : 'success'} title={org ? "Cég cseréje" : 'Cég hozzárendelése'} org={selectedOrgDetails}/>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="rounded-lg shadow-lg bg-dark-blue text-white py-2 px-2 w-2/4 ml-3">
                    <div className="flex items-center">
                        <img src="/svg/download.svg" width={85} height={85} className="mr-2 p-2" />
                        <div className="mt-2">
                            <p className="text-cream">Személyre szabott szerződés elkészítése</p>
                            <div className="">
                                <label className="block text-white text-sm font-bold mt-5" htmlFor="amount">
                                    Befektetendő összeg:
                                </label>
                                <input
                                    onChange={(e) => handleAmountChanges(e.target.value)}
                                    className="shadow appearance-none border rounded w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="amount" type="number" placeholder="Add meg az összeget."/>
                                <ul className="flex items-center mt-5">
                                    {currencies.map((currency, index) => (
                                        <li key={index}>
                                        <input
                                            id={currency}
                                            value={currency}
                                            checked={currency === selectedCurrency}
                                            onChange={(e) => handleCurrencyOption(e.target.value)}
                                            type="radio"
                                            className="focus:outline-none w-4 h-4"
                                        />
                                        <label
                                            htmlFor={currency}
                                            className="ml-2 mr-2 text-lg font-medium text-white "
                                        >
                                            {currency}
                                        </label>
                                        </li>
                                    ))}
                                     <li>
                                        <>
                                        <Spinner show={spinner} width={4} height={4}/>

                                        {!spinner && (
                                            <button 
                                            onClick={() => {
                                                if(select && !org) {
                                                    makeContract(select, org, companyDetails, selectedCurrency, amount);
                                                }

                                                if(org && !select) {
                                                    makeContract(select, org, companyDetails, selectedCurrency, amount);
                                                }
                                            }}
                                            className={`enabled:hover:text-cream text-white bg-error font-bold uppercase text-sm px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none disabled:bg-error disabled:opacity-70 enabled:bg-success`} disabled={(amount > 0 && (select || org)) ? false : true}>Szerződés küldése</button>
                                        )}
                                        </>
                                        
                                    </li>
                                </ul>
                                
                            </div>
                            <div className="mb-3 items-center text-lg font-medium rounded-lg sm:flex h-8 mt-2">
                                <p className="text-md">DocuSign URL: <a className="text-cream w-fit" href={docuUri} target="_blank">{docuUri.length === 0 ? 'Még nincs link előállítva!' : "Tovább a DocuSign-ra!"}</a></p>
                                {docuUri.length > 0 && (
                                    <button
                                        className="ml-2 hover:bg-dark-brown text-white bg-brown font-bold uppercase text-sm px-3 py-1 mt-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={copyToClipBoard}
                                    >
                                        Link másolása
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}