import { useSelector } from "react-redux"
import { selectMenuStates } from "../../store/reducers/menuSlice"
import FormContainer from "../../components/daisy/FormContainer"
import React from "react"
import { UserWithInvestment } from "../../types/userWithInvestment"
import { getGroupMembers } from "../../api/getGroupMembers"
import { useNavigate } from "react-router"
import { getGroupData } from "../../api/getGroupData"
import { Response } from "../../types/groupDataSummary"
import UserList from "../../components/daisy/UserList"

export default function DaisyMainPage () {
    const menu = useSelector(selectMenuStates)

    const [screenSize, getDimension] = React.useState({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });

    const [users, setUsers] = React.useState<UserWithInvestment[]>([])
    const [groupData, setGroupData] = React.useState<Response>()

    const labels = users.map(user => user.name);
    const datas = users.map(user => user.share)
    const navigate = useNavigate()

    const pieChartData = {
        labels: labels,
        datasets: [
          {
            label: '%-os rész a csoportban: ',
            data: datas,
            backgroundColor: ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"],
            borderColor: [
              'rgba(244, 244, 244, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

    /* Effects */
    React.useEffect(() => {

        if(!menu.daisyMenu) {
            return;
        }

        getGroupMembers(menu.daisyMenu.group_id)
        .then((response: UserWithInvestment[]) => {
            setUsers(response)
        })
        .catch((error) => {
            console.error(error);
        });

        getGroupData(menu.daisyMenu)
        .then((response: Response) => {
          setGroupData(response)
        })
        .catch((error) => {
            console.error(error);
        });


    }, [menu.daisyMenu?.group_id])

    const setDimension = () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      })
    }
     
    React.useEffect(() => {
      window.addEventListener('resize', setDimension);
       
      return(() => {
          window.removeEventListener('resize', setDimension);
      })
    }, [screenSize])

    return (
        menu.daisyMenu ? (
          <div className="m-5">
            <div className="flex flex-wrap">
              <div className="w-full mt-2 mb-10">
                <FormContainer screenSize={screenSize} users={users}/>
              </div>

              <UserList screenSize={screenSize} users={users}/>
              
            </div>
            
          </div>
        ) : <p className="mt-5 text-xl text-center">Kérlek válassz csoportot, vagy hozz létre egy <span onClick={() => navigate('/group-manager')}className="text-dark-brown hover:text-cream hover:cursor-pointer">újat</span>.</p>
      );
      }
