import { DaisyDeposits } from "../models/daisydeposits.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getDaisyDepositRecords = (incoming: boolean, system_id: number): Promise<DaisyDeposits[]> => {
    return new Promise<DaisyDeposits[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisydeposit/${system_id}/${incoming}`).then((response) => {
            resolve(response.data as DaisyDeposits[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};