import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interfaces
export interface UserInfo {
  user_id: number;
  email: string;
  name: string;
  admin: boolean;
}
export interface UserData  {
  userInfo: UserInfo,
  accessToken: string;
}

// initial state
const storedUser = localStorage.getItem('user');
const initialState: UserData = storedUser ? JSON.parse(storedUser) : {
  userInfo: {
    user_id: -1,
    email: '',
    name: '',
  },
  accessToken: '',
};

// reducers

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<UserData>) => {
      state.userInfo = action.payload.userInfo;
      state.accessToken = action.payload.accessToken;
      localStorage.setItem('user', JSON.stringify(state));
    },
    remove: (state) => {
      state.userInfo = { user_id: -1, email: '', name: '', admin: false };
      state.accessToken = '';
      localStorage.removeItem('user');
    },
  },
});

// exports

export const { set, remove } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: { user: UserData }) => state.user;
