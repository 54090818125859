import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/user.model';
import { Group } from '../../models/group.model';


interface IGroup {
  group: Group | undefined;
}

const initialState: IGroup = {
  group: undefined
}
// reducers

const selectedGroupSlice = createSlice({
    name: 'selectedGroup',
    initialState,
    reducers: {
      SET_SELECTED_GROUP: (state, action: PayloadAction<Group>) => {
        state.group = action.payload;
      },
      REMOVE_SELECTED_GROUP: (state) => {
        state.group = initialState.group;
      },
    },
  });

// exports

export const { SET_SELECTED_GROUP, REMOVE_SELECTED_GROUP } = selectedGroupSlice.actions;

export default selectedGroupSlice.reducer;

export const selectedGroupState = (state: { selectedGroup: IGroup }) => state.selectedGroup.group;