import { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import React from "react";
import { LiquidityHistory } from "../../../../models/liqhistory.model";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    currentAmount: number | undefined;
}

type ModifiedPayload = Pick<LiquidityHistory, 
"amount" |
"group_id" |
"creatorId">

const AddNewRecordModal = ({ group, title, showModal, closeModal, handleStateChange, currentAmount } : ModalProps) => {

    const user = useSelector(selectUser);

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        amount: 0,
        group_id: 0,
        creatorId: 0,
    });
    
    useEffect(() => {

        if(!group) return;

        setRecordData({
            amount: currentAmount || 0,
            group_id: group?.group_id,
            creatorId: user?.userInfo?.user_id

        })

    }, [group])

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if(type === "amount") {
            setRecordData(prevData => ({
                ...prevData,
                [type]: parseInt(inputValue)
            }));
        }
    };

    const makeRecord = async () => {

        if(!group) return;
        await responseHero.post(`${process.env.REACT_APP_API_URL}/liqhistory`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen frissítetted a likviditást!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen frissítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <div className="flex p-2 rounded shadow-md">
                                
                                <div className="pr-2 text-center items-center">
                                <label className="text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Likviditás</span> <br/>Összeg (USD)
                                    </label>
                                    <input
                                        value={recordData?.amount}
                                        onChange={(e) => handleChange('amount', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="amount" type="number" placeholder="Összeg (USD)"/>
                                </div>

                            </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Mentés
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
}
export default AddNewRecordModal;