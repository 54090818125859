import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getDepositRecords } from "../../../../../api/getHumanDCFunctions";
import { DepositRegister } from "../../../../../models/depositregister";
import ModifyRecordModal from "../../../modals/deposit/ModifyRecordModal";
import AddNewRecordModal from "../../../modals/deposit/AddNewRecordModal";
import { User } from "../../../../../models/user.model";
import RemoveRecordModal from "../../../modals/deposit/RemoveRecordModal";
import ModifyManualRecordModal from "../../../modals/deposit/ModifyManualRecordModal";
import { useNavigate } from "react-router";

export default function FormContainer () {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyRecordModal, setShowModifyRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showModifyManualRecordModal, setShowModifyManualRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [selectedUser, setSelectedUser] = React.useState<User>();

    const [records, setRecords] = React.useState<DepositRegister[]>([])
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)

    const [selectedRecord, setSelectedRecord] = React.useState<DepositRegister>();

    const menu = useSelector(selectMenuStates)
    const navigate = useNavigate();

    const openModal = (user: User) => {
        setShowNewRecordModal(true)
        setSelectedUser(user)
    };

    const openModifyRecordModal = (record: DepositRegister) => {
        setSelectedRecord(record)
        setShowModifyRecordModal(true)
    };

    const openModifyManualRecordModal = (record: DepositRegister) => {
        setSelectedRecord(record)
        setShowModifyManualRecordModal(true)
    };

    const openRemoveModal = (record: DepositRegister) => {
        setSelectedRecord(record)
        setShowRemoveRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowModifyRecordModal(false)
        setShowModifyManualRecordModal(false)
        setShowRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }
    
    React.useEffect(() => {
        getDepositRecords(menu?.daisyMenu)
        .then((response) => {
            setRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#E8D788";
    const defaultValue = "#D3D3D3";

    const getColor = (number1: number, number2: number) => {
        if(Number(number1) === Number(number2)) {
            return "#aaa";
        
        }
        return "#E8D788";
    }
    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">
            
            <ModifyRecordModal title={"Adatsor módosítása"} group={menu.daisyMenu} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <ModifyManualRecordModal title={"Adatsor manuális kezelése"} group={menu.daisyMenu} showModal={showModifyManualRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <AddNewRecordModal title={"Adatsor hozzárendelése"} group={menu.daisyMenu} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} user={selectedUser}/>
            <RemoveRecordModal title={"Adatsor törlése"} group={menu.daisyMenu} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Ügyfél neve</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Befektetett összeg (EUR)</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Befektetett összeg levonásokkal (EUR)</th>

                            <th className="border p-2 bg-success hover:opacity-80 cursor-pointer">Tőkeviszonyszám (EUR)</th>
                            <th className="border p-2 bg-gray-500 hover:opacity-80 cursor-pointer">Bónusz (%)</th>

                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 bg-success hover:opacity-80 cursor-pointer">Befektett összeg (USDT)</th>
                            
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Levonás - Összes</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Levonás - Fizikai aranyra</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Levonás - Bányagép</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Levonás - Altcoin</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Levonás - Költségek</th>
                            <th className="border p-2 bg-gray-500 hover:opacity-80 cursor-pointer">Kereskedésben lévő összeg</th>

                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Likviditás</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Likviditás PAXG</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Fee</th>

                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Módosít</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Coinkezelő</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Manuális módosítás</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Új beutalás</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {records.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.id}</td>
                                <td className={dataRowStyling} style={{ color: record?.user?.name.length === 0 ? defaultValue : "#fff" }}>{record?.user?.name}</td>
                                <td className={dataRowStyling} style={{ color: getColor(record.invested_amount_eur, record.invested_amount_usdt) }}>{record.invested_amount_eur}</td>
                                <td className={dataRowStyling} style={{ color: getColor(record.invested_amount_eur, record.invested_amount_usdt) }}>{record.net_invested_amount_eur}</td>

                                <td className={dataRowStyling} style={{ color: record.capital_ratio === 0 ? defaultValue : validValue}}>{record.capital_ratio}</td>
                                <td className={dataRowStyling} style={{ color: record.bonus === 0 ? defaultValue : validValue}}>{record.bonus}%</td>

                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling} style={{ color: record.invested_amount_usdt === 0 ? defaultValue : validValue}}>{record.invested_amount_usdt}</td>
                                <td className={dataRowStyling} style={{ color: record.deduction_all === 0 ? defaultValue : validValue}}>{record.deduction_all}</td>
                                <td className={dataRowStyling} style={{ color: record.deduction_physical_gold === 0 ? defaultValue : validValue}}>{record.deduction_physical_gold}</td>
                                <td className={dataRowStyling} style={{ color: record.deduction_mining === 0 ? defaultValue : validValue}}>{record.deduction_mining}</td>
                                <td className={dataRowStyling} style={{ color: record.deduction_alt === 0 ? defaultValue : validValue}}>{record.deduction_alt}</td>
                                <td className={dataRowStyling} style={{ color: record.cost_deduction === 0 ? defaultValue : validValue}}>{record.cost_deduction}</td>
                                <td className={dataRowStyling} style={{ color: record.amount_in_trade === 0 ? defaultValue : validValue}}>{record.amount_in_trade}</td>
                                
                                <td className={dataRowStyling} style={{ color: record.liquidity === 0 ? defaultValue : validValue}}>{record.liquidity}</td>
                                <td className={dataRowStyling} style={{ color: record.liquidity_paxg === 0 ? defaultValue : validValue}}>{record.liquidity_paxg}</td>
                                <td className={dataRowStyling} style={{ color: record.fee === 0 ? defaultValue : validValue}}>{record.fee}</td>
                                

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openModifyRecordModal(record)
                                    }} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Automata módosítás</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        navigate(`/cp/${record?.id}`)
                                    }} className="text-emerald-200 hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Coinok kezelése</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openModifyManualRecordModal(record)
                                    }} className="text-red-500 hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Kézi módosítás</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openRemoveModal(record)
                                    }} className="text-error hover:opacity-80 text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openModal(record.user)
                                    }} className="text-white hover:opacity-80 text-md font-bold text-center p-2 cursor-pointer">Új beutalás</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}