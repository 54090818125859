import React from 'react';
import { User } from '../../../models/user.model';
import { Group } from '../../../models/group.model';
import RemoveUserFromGroupModal from '../modals/RemoveUserFromGroupModal';
import { Spinner } from '../../../utils/Spinner';
import AddMember from '../toolbar/AddMember';
import { getGroupMembers } from '../../../api/getGroupMembers';
import SetInvestedMoney from '../modals/SetInvestedMoney';
import { UserWithInvestment } from '../../../types/userWithInvestment';
import SetMinusMoney from '../modals/SetMinusMoney';

type GroupMemberListProps = {
    group: Group | undefined,
}

export default function GroupMemberList ({ group } : GroupMemberListProps) {

    const [users, setUsers] = React.useState<UserWithInvestment[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(false)
    const [stateChange, setStateChange] = React.useState<boolean>(true)

    /* Effects */
    React.useEffect(() => {

        if(!group) {
            setUsers([]);
            return;
        }

        getGroupMembers(group.group_id)
        .then((response) => {
            setUsers(response)
            setSpinner(false)
        })
        .catch((error) => {
            console.error(error);
            setSpinner(false)
        });

    }, [group, stateChange])

    /* Modals */
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false); // remove modal state
    const [showInvestmentSetterModal, setShowInvestmentSetterModal] = React.useState<boolean>(false); // remove modal state
    const [showMinusSetterModal, setShowMinusSetterModal] = React.useState<boolean>(false); // remove modal state

    const [selectedUser, setSelectedUser] = React.useState<User>();

    const [currentValue, setCurrentValue] = React.useState<number>(0);


    const openModal = (user: User) => {
        setSelectedUser(user);
        setShowRemoveModal(true);
    };

    const openModal2 = (user: User) => {
        setSelectedUser(user);
        setShowInvestmentSetterModal(true)
    };

    const openModal3 = (user: User) => {
        setSelectedUser(user);
        setShowMinusSetterModal(true)
    };

    const closeModal = () => {
        setShowRemoveModal(false);
        setShowInvestmentSetterModal(false);
        setShowMinusSetterModal(false);
        setSelectedUser(undefined);
        
    };

    const handleStateChange = () => {
        setStateChange(!stateChange)
    }

    // sorting algorithms

    const sortUsers = (by: string) => {
        switch(by) {
            case 'invested': 
                if (!isOrderedInvestments(users)) {
                    const orderedUsers = [...users].sort((a, b) => a.invested > b.invested ? 1 : -1);
                    setUsers(orderedUsers)
                } else {
                    const orderedUsers = [...users].sort((a, b) => a.invested > b.invested ? -1 : 1);
                    setUsers(orderedUsers)
                }
                break;

            case 'name': 
                if (!isOrderedAtoZ(users)) {
                    const orderedUsers = [...users].sort((a, b) => a.name > b.name ? 1 : -1);
                    setUsers(orderedUsers)
                } else {
                    const orderedUsers = [...users].sort((a, b) => a.name > b.name ? -1 : 1);
                    setUsers(orderedUsers)
                }
                break;

            default:
                return;
        }
    }

    function isOrderedAtoZ(arr: User[]) {
        for (let i = 1; i < arr.length; i++) {
            if (arr[i].name < arr[i - 1].name) {
            return false;
            }
        }
        return true;
    }

    function isOrderedInvestments(arr: UserWithInvestment[]) {
        for (let i = 1; i < arr.length; i++) {
            if (arr[i].invested < arr[i - 1].invested) {
            return false;
            }
        }
        return true;
    }

    return (
        <>
        <RemoveUserFromGroupModal title="Felhasználó törlése" user={selectedUser} showModal={showRemoveModal} closeModal={closeModal} group={group} handleStateChange={handleStateChange}/>
        <SetInvestedMoney currentValue={currentValue} group_id={group?.group_id} user_id={selectedUser?.user_id} title={`${selectedUser?.name} befektetése`} showModal={showInvestmentSetterModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <SetMinusMoney currentValue={currentValue} group_id={group?.group_id} user_id={selectedUser?.user_id} title={`${selectedUser?.name} tőkeviszonyszáma`} showModal={showMinusSetterModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        {(group) && (
            <>
            {users && ( 
                <div className="">
                    <Spinner show={spinner} width={12} height={12}/>
                    
                    <h1 className='mb-2 text-center text-xl'>A {group?.group_name} tagjai: </h1>
                    {users.length === 0 ? <p className="mt-2 text-xl text-center bg-dark-blue text-white px-2 py-1 rounded">Nincs felhasználó a csoportban</p> 
                        :
                        <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                            <table className="divide-y w-full shadow-lg text-sm border-white border-[10px] shadow-lg divide-gray-600">
                                <thead className="bg-dark-blue sticky top-0">
                                    <tr>
                                        <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">ID</th>
                                        <th scope="col" onClick={() => sortUsers("name")} className="cursor-pointer hover:text-brown hover:underline py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Név</th>
                                        <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Email</th>
                                        <th scope="col" onClick={() => sortUsers("invested")} className="cursor-pointer hover:text-brown hover:underline py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Befektett összeg</th>
                                        <th scope="col" className="cursor-pointer py-3 px-6 text-xs font-medium tracking-wider text-center text-cream uppercase">Tőke viszony szám</th>
                                        {group.running ? null : <th scope="col" className="p-4"><span className="sr-only">Kezelés</span></th>}
                                        {group.running ? null : <th scope="col" className="p-4"><span className="sr-only">Kezelés</span></th>}
                                        <th scope="col" className="p-4"><span className="sr-only">Törlés</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-dark-blue divide-y divide-gray-600 w-full">
                                    {users.map((user, index) => ( 
                                        <tr key={index} className="hover:bg-light-blue">
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-center  text-white">{user.user_id}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-center  text-white">{user.name} {user.admin ? '[ADMIN]' : null}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-center  text-white">{user.email}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-center  text-white text-center">{user.invested} {group?.system?.currency}</td>
                                            <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-center  text-white text-center">{user.capital_ratio} {group?.system?.currency}</td>
                                            {group.running ? null :  <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap"><button onClick={() => {openModal2(user); setCurrentValue(user.invested)}} className="text-cream hover:underline">Befektett tőke megadása</button></td>}
                                            {group.running ? null :  <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap"><button onClick={() => {openModal3(user); setCurrentValue(user.capital_ratio)}} className="text-cream hover:underline">Tőkeviszonyszám megadása</button></td>}
                                            <td className="py-4 px-6 text-sm font-medium text-center whitespace-nowrap"><button onClick={() => openModal(user)} className="text-cream hover:underline">Törlés</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        }
                </div>
            )}
            </>
        )}
        </>
    )
}
