
import { AssetPurchase } from "../models/assetpurchase.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getAssetPurchaseData = (group_id: number, assetId: number): Promise<AssetPurchase[]> => {
    return new Promise<AssetPurchase[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/assetp/group/${group_id}/${assetId}`).then((response) => {
            resolve(response.data as AssetPurchase[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};