import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/user.model';


interface IUser {
  user: User | undefined;
}

const initialState: IUser = {
  user: undefined
}
// reducers

const selectedUserSlice = createSlice({
    name: 'selectedUser',
    initialState,
    reducers: {
      SET_SELECTED_USER: (state, action: PayloadAction<User>) => {
        state.user = action.payload;
      },
      REMOVE_SELECTED_USER: (state) => {
        state.user = initialState.user;
      },
    },
  });

// exports

export const { SET_SELECTED_USER, REMOVE_SELECTED_USER } = selectedUserSlice.actions;

export default selectedUserSlice.reducer;

export const selectUserState = (state: { selectedUser: IUser }) => state.selectedUser.user;