import { ModalProps } from "../../../types/modalProps";
import { User } from "../../../models/user.model";
const GoToUserProfile = ({ title, user, showModal, closeModal, handleStateChange } : ModalProps) => {


  const stalkerModeOn = async (user: User | undefined) => {
    if(!user) return;
    window.open(`${process.env.REACT_APP_CLIENT_URL}/login?email=${user.email.replace('@', 'AT')}&password=${user.password}&stalkerMode=true`);
    closeModal();
  }

  return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <p className="text-xl mb-2">Átjelentkezel és ezzel megtekinted <b>{user?.name}</b> kimutatási oldalát?</p>
                            <p className="text-sm">Az ügyfél nem kap értesítést az átjelentkezésről és a rendszer nem veszi tényleges bejelentkezésnek.</p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={() => stalkerModeOn(user)}
                        >
                            Megtekint
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default GoToUserProfile;