import { Asset } from "../models/assets.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getAssets = (): Promise<Asset[]> => {
    return new Promise<Asset[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/asset`).then((response) => {
            resolve(response.data as Asset[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};