import { ConnectedGroups } from "../models/connectedgroups.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getConnectedGroups = (): Promise<ConnectedGroups[]> => {
    return new Promise<ConnectedGroups[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/connectedGroups`).then((response) => {
            resolve(response.data as ConnectedGroups[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};