import { responseHero } from "../utils/axiosInterceptor";
import { UserWithInvestment } from "../types/userWithInvestment";

export const getGroupMembers = (group_id: number): Promise<UserWithInvestment[]> => {
    return new Promise<UserWithInvestment[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/usersByGroup/${group_id}`).then((response) => {
            resolve(response.data as UserWithInvestment[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};