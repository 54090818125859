import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../../models/organization.model';


interface IOrg {
    org: Organization | undefined;
}

const initialState: IOrg = {
    org: undefined
}
// reducers

const selectedOrgSlice = createSlice({
    name: 'selectedOrg',
    initialState,
    reducers: {
      SET_SELECTED_ORG: (state, action: PayloadAction<Organization>) => {
        state.org = action.payload;
      },
      REMOVE_SELECTED_ORG: (state) => {
        state.org = initialState.org;
      },
    },
  });

// exports

export const { SET_SELECTED_ORG, REMOVE_SELECTED_ORG } = selectedOrgSlice.actions;

export default selectedOrgSlice.reducer;

export const selectOrgState = (state: { selectedOrg: IOrg }) => state.selectedOrg.org;