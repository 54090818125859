import React from "react"
import { getConnectedGroups } from "../../../api/getConnectedGroups";
import { ConnectedGroups } from "../../../models/connectedgroups.model";

export default function GroupConnectionsList () {

    const [connectedGroups, setConnectedGroups] = React.useState<ConnectedGroups[]>([]);
    React.useEffect(() => {
        getConnectedGroups()
        .then((response) => {
            setConnectedGroups(response)
        })
        .catch((error) => {
            console.error(error);
        });
    })
    return (
        <div className="m-5">
            <h1 className="text-2xl text-center mb-5">Kapcsolatok különböző csoportok között:</h1>
            {connectedGroups.map((connectedGroup) => (
                <div className="flex flex-row space-x-4 items-center justify-center mt-2">
                    <p className="text-lg text-white bg-dark-blue px-4 py-2 shadow-md font-semibold">{connectedGroup?.groupA?.group_name} <span className="text-cream text-sm">(Rendszer: {connectedGroup?.groupA?.system?.name})</span></p>
                    <p className=" font-semibold">-</p>
                    <p className="text-2xl text-white bg-dark-blue px-4 py-2 shadow-md text-lg font-semibold">{connectedGroup?.groupB?.group_name} <span className="text-cream text-sm">(Rendszer: {connectedGroup?.groupB?.system?.name})</span></p>
                </div>
            ))}
        </div>
    )
}