import { Votes } from "../models/vote.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getVotes = (voteType: number | undefined): Promise<Votes[]> => {
    return new Promise<Votes[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/vote/${voteType}`).then((response) => {
            resolve(response.data as Votes[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};