import { MiningGroupStats } from "../../../types/miningGroupStats";

type MiningStatsPanel = {
    stats: MiningGroupStats["groupStats"] | undefined;
    group_name: string;
};

export default function MiningStatsPanel ({ stats, group_name } : MiningStatsPanel) {

    /*
        Paraméterek:
            csoport tényleges BTC db száma, 
            Mai $ értéke, 
            Összes termelt coin, 

            Összes átváltott coin, 
            Összes átváltott coin értéke, 

            Energiaköltségek (Ügyfelek), 
            Energiaköltségek (Human DC) 

            Csoport % megtérülés az össz befizetéshez képest
    */

    return (
        <>
        
        <div className="max-w px-12 py-6 mt-10 m-3">
        <h1 className="text-2xl text-center mb-2">A <span className="text-white px-1 py-1 rounded shadow-md shadow-cream bg-dark-brown">{group_name}</span> összefoglaló adatai: </h1>
            <div className="flex flex-row space-x-4 mb-5 items-center justify-center">
                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.netProduced?.toFixed(8)}</p>
                    <p className="text-lg text-cream text-center">Csoport <span className="text-cream">tényleges </span>BTC darabszáma</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalProducedValue}$</p>
                    <p className="text-lg text-cream text-center">Csoport <span className="text-cream">mai </span>BTC ($) értéke</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalProduced?.toFixed(8)}</p>
                    <p className="text-lg text-cream text-center">Csoport <span className="text-cream">összes termelt</span> BTC darabszáma</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalConverted?.toFixed(8)}</p>
                    <p className="text-lg text-cream text-center">Összes átváltott coin mennyisége</p>
                </div>
            </div>
            <div className="flex flex-wrap space-x-4 mb-5 items-center justify-center">
                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalConvertedValue}$</p>
                    <p className="text-lg text-cream text-center">Összes átváltott coin értéke</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalEnergy.client}$</p>
                    <p className="text-lg text-cream text-center">Energiaköltségek (Ügyfelek)</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.totalEnergy.company}$</p>
                    <p className="text-lg text-cream text-center" >Energiaköltségek (Human D.C)</p>
                </div>

                <div className="rounded px-4 py-4 text-white bg-dark-blue shadow-dark-blue shadow-md">
                    <p className="text-2xl text-white text-center">{stats?.rate}%</p>
                    <p className="text-lg text-cream text-center">Százalékos megtérülés</p>
                </div>
            </div>
        </div>
        </>
    )
}