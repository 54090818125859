import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from '../../models/templates.model';
import { Group } from '../../models/group.model';

interface MenuValues {
    daisyMenu: Group | undefined;
    templateMenu: Template | undefined;
    insuranceMenu: number;
}

const initialState : MenuValues = {
    daisyMenu: undefined,
    templateMenu: undefined,
    insuranceMenu: 0,
};

// reducers

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
      set_daisy_menu: (state, action: PayloadAction<Group>) => {
        state.daisyMenu = action.payload;
      },
      remove_daisy_menu: (state) => {
        state.daisyMenu = undefined;
      },
      set_template_menu: (state, action: PayloadAction<Pick<MenuValues, "templateMenu">>) => {
        state.templateMenu = action.payload.templateMenu;
      },
      set_insurance_menu: (state, action: PayloadAction<Pick<MenuValues, "insuranceMenu">>) => {
        state.insuranceMenu = action.payload.insuranceMenu;
      },
    },
  });

// exports

export const { set_daisy_menu, set_template_menu, set_insurance_menu, remove_daisy_menu } = menuSlice.actions;

export default menuSlice.reducer;

export const selectMenuStates = (state: { menu: MenuValues }) => state.menu;