import React, { useEffect, useState } from "react";
import { ModalPropsWithOrganization } from "../../../types/modalProps";
import { useNavigate } from "react-router";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserState } from "../../../store/reducers/selectedUserSlice";
import { Organization } from "../../../models/organization.model";

type ModifiedPayload = Omit<Organization, "id">

const CreateOrgModal = ({ title, org, showModal, closeModal, handleStateChange } : ModalPropsWithOrganization) => {

    const [changePasswordVisibility, setChangePasswordVisibility] = React.useState<boolean>(false);
    const select = useSelector(selectUserState)
    const navigate = useNavigate()

    const [newOrgData, setNewOrgData] = useState<ModifiedPayload>({
        full_name: '',
        org_type: '',
        name: '',
        company_registration_number: '',
        hq: '', 
        address: '',
        iban: '',
        bank_name: '',
        email: '',
        main_activity: '',
        tax_number: '',
        leader: undefined,
      });
      
      useEffect(() => {
        if (org) {
        setNewOrgData({
            full_name: '',
            org_type: '',
            name: '',
            company_registration_number: '',
            hq: '', 
            address: '',
            iban: '',
            bank_name: '',
            email: '',
            main_activity: '',
            tax_number: '',
            leader: undefined,
          });
        }
      }, [org]);

    const createOrg = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/org`, { org: newOrgData }).then((response) => {
            closeModal()
            handleStateChange();

            toast.success(`Sikeresen létrehoztad a következőt: ${newOrgData.name}`);

            // reset user data state
            setNewOrgData({
                full_name: '',
                org_type: '',
                name: '',
                company_registration_number: '',
                hq: '', 
                address: '',
                bank_name: '',
                iban: '',
                email: '',
                main_activity: '',
                tax_number: '',
                leader: undefined,
              });
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error(`Sikertelen hozzáadás!`);
        })
    }

    const handleChange = (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setNewOrgData(prevData => ({
            ...prevData,
            [type]: e.target.value,
        }));
    };

    return (
    <>
        {showModal && (
            <>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-dark-blue font=semibold"><b>{org?.name}</b> {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="full_name">
                                    Cég teljes neve
                                </label>
                                    <input value={newOrgData.full_name} onChange={(e) => handleChange('full_name', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cég teljes neve"/>
                                
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="org_type">
                                    Cég formája
                                </label>
                                    <input value={newOrgData.org_type} onChange={(e) => handleChange('org_type', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Cég típusa"/>
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="name">
                                    Cég rövidített neve
                                </label>
                                    <input value={newOrgData.name} onChange={(e) => handleChange('name', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Cég rövidített neve"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="full_name">
                                    Székhely
                                </label>
                                    <input value={newOrgData.hq} onChange={(e) => handleChange('hq', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Székhely"/>
                                
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="org_type">
                                    Fióktelep címe
                                </label>
                                    <input value={newOrgData.address} onChange={(e) => handleChange('address', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Fióktelep címe"/>
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="name">
                                        Cég email címe
                                    </label>
                                    <input value={newOrgData.email} onChange={(e) => handleChange('email', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Email"/>
                                </div>
                            </div>

                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="full_name">
                                    Cégjegyzékszám
                                </label>
                                    <input value={newOrgData.company_registration_number} onChange={(e) => handleChange('company_registration_number', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cégjegyzékszám"/>
                                
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="org_type">
                                    Fő tevékenységi kör
                                </label>
                                    <input value={newOrgData.main_activity} onChange={(e) => handleChange('main_activity', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Fő tevékenység"/>
                                </div>
                                <div className="w-1/3 pr-2">
                                <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="name">
                                    Adószám
                                </label>
                                    <input value={newOrgData.tax_number} onChange={(e) => handleChange('tax_number', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Adószám"/>
                                </div>
                            </div>
                            <div className="flex p-4 rounded shadow-md">
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="name">
                                        Cég bankszámlaszáma
                                    </label>
                                    <input value={newOrgData.iban} onChange={(e) => handleChange('iban', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="IBAN"/>
                                </div>
                                <div className="w-1/2 pr-2">
                                    <label className="block text-gray-700 text-sm font-bold mt-2" htmlFor="name">
                                        Bank neve
                                    </label>
                                    <input value={newOrgData.bank_name} onChange={(e) => handleChange('bank_name', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Bank neve"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={createOrg}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default CreateOrgModal;