import { useDispatch, useSelector } from "react-redux";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { Fragment } from "react";
import React from "react";
import MiningMachineList from "../../components/miningmachine/MiningMachineList";
import { UserWithInvestment } from "../../types/userWithInvestment";
import { getGroupMembers } from "../../api/getGroupMembers";
import UserList from "../../components/daisy/UserList";
import { remove_daisy_menu } from "../../store/reducers/menuSlice";
import { getMiningGroupStats } from "../../api/getMiningGroupStats";
import { MiningGroupStats } from "../../types/miningGroupStats";
import MiningStatsPanel from "../../components/miningmachine/miningstatistics/MiningStatsPanel";

type MiningMachineProps = {
    flag: boolean;
}
export default function MiningMachine({ flag }: MiningMachineProps) {

    const menu = useSelector(selectMenuStates)
    const [groupStats, setGroupStats] = React.useState<MiningGroupStats["groupStats"]>();
    const [users, setUsers] = React.useState<UserWithInvestment[]>([]);

    const dispatch = useDispatch();

    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      });

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }
    
    React.useEffect(() => {
        window.addEventListener('resize', setDimension);
            
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    /* Effects */
    React.useEffect(() => {
        if(flag) {
            dispatch(remove_daisy_menu());
        }
    }, [flag])


    React.useEffect(() => {
        if(!flag && menu?.daisyMenu) {
            getMiningGroupStats(menu.daisyMenu.group_id)
            .then((response: MiningGroupStats) => {
                setGroupStats(response.groupStats);
                setUsers(response.users);
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }, [menu.daisyMenu, flag])

    return (
        <Fragment>
        <div className="m-5">
            <MiningMachineList allMachine={flag}/>
        </div>

        {menu?.daisyMenu && (
            <div className="m-5">
                <MiningStatsPanel stats={groupStats} group_name={menu?.daisyMenu?.group_name}/>

                <UserList users={users} screenSize={screenSize}/>
                
            </div>
        )}
        </Fragment>
    );
}

