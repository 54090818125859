import { useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/reducers/userSlice";
import { Asset } from "../../../../models/assets.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
}

type ModifiedPayload = Pick<Asset, 
"name" |
"code">

const AddNewAssetModal = ({ title, showModal, closeModal, handleStateChange } : ModalProps) => {

    const user = useSelector(selectUser)

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        name: "",
        code: ""
    });

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
        ) => {
        const inputValue = e.target.value;
        
        setRecordData((prevData) => ({
        ...prevData,
        [type]: inputValue,
        }));
    }
          
          
    
    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/asset`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy eszközt!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Eszköz </span> <br/>Név
                                        </label>
                                        <input
                                            value={recordData?.name}
                                            onChange={(e) => handleChange('name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Eszköz neve pl. Bitcoin"/>
                                    </div>

                                    <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Eszköz </span> <br/>Code
                                        </label>
                                        <input
                                            value={recordData?.code}
                                            onChange={(e) => handleChange('code', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="code" type="text" placeholder="Eszköz rövidítése pl. BTC"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewAssetModal;