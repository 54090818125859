import React from "react"
import { User } from "../../models/user.model";
import { responseHero } from "../../utils/axiosInterceptor";
import { Spinner } from "../../utils/Spinner";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import RemoveOrgModal from "./modals/RemoveOrgModal";
import ModifyOrgModal from "./modals/ModifyOrgModal";
import CreateOrgModal from "./modals/CreateOrgModal";
import { Organization } from "../../models/organization.model";
import { toast } from "react-toastify";
import { SET_SELECTED_ORG } from "../../store/reducers/selectedOrgSlice";
import { Template } from "../../models/templates.model";
import { REMOVE_SELECTED_USER } from "../../store/reducers/selectedUserSlice";

type UserListProps = {
    searchText: string; // searchText prop
    searchOption: string;
};

export default function OrgList ({ searchText, searchOption } : UserListProps) {

    const [orgs, setOrgs] = React.useState<Organization[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(true)
    const [stateChange, setStateChange] = React.useState<boolean>(true)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { state } = useLocation();
    
    let template: Template | undefined = undefined;

    if (state && state.template) {
        template = state.template;
    }

    const getOrgs = React.useCallback(async () => {
        setSpinner(true);
    
        try {
          const response = await responseHero.get(
            `${process.env.REACT_APP_API_URL}/org`
          );
    
          const filteredOrgs = response.data.filter((org: Organization) => {
            const searchValue = searchText.toLowerCase();
            if (searchOption === 'full_name') {
              return org.full_name.toLowerCase().includes(searchValue);
            } else if (searchOption === 'company_registration_number') {
              return org.company_registration_number.toLowerCase().includes(searchValue);
            } else if (searchOption === 'email') {
              return org.email.includes(searchValue);
            } 
            return true;
          });

          setOrgs(filteredOrgs);
        } catch (error: any) {
          console.error(error.response.data);
        }
    
        setSpinner(false);
      }, [searchOption, searchText]);
    
      

      /* Effects */
      React.useEffect(() => {
        getOrgs();
      }, [getOrgs, stateChange]);

    /* Modals */
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false); // remove modal state
    const [showModifyModal, setShowModifyModal] = React.useState<boolean>(false); // modify modal state
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false); // create org modal state
    const [selectedUser, setSelectedUser] = React.useState<Organization>();


    const openModal = (org: Organization, modalType: string) => {
        setSelectedUser(org);
        switch(modalType) {
            case 'modify': 
                return setShowModifyModal(true);
            case 'remove':
                return setShowRemoveModal(true);
            default:
                return;
        }
    };

    const openCreateModal = () => {
        setShowCreateModal(true);
    }

    const closeModal = () => {
        setShowRemoveModal(false);
        setShowModifyModal(false);
        setShowCreateModal(false);
        setSelectedUser(undefined);
    };

    const handleStateChange = () => {
        setStateChange(!stateChange)
    }

    const removeMemberFromOrganization = async (org: Organization, user: User | undefined) => {
        if(!org) {
            return;
        }

        await responseHero.put(`${process.env.REACT_APP_API_URL}/leader/${org.id}`).then((response) => {
            handleStateChange();
            toast.success(`${user?.name} sikeresen eltávolítva a cég képviselője posztról!`)
        }).catch(error => {
            console.error(error.response.data);

            toast.error('Hiba a hozzárendelés közben!')
        })
    }

    const addOrganizationToContract = async (org: Organization) => {
        if(!org) {
            return;
        }
        
        dispatch(SET_SELECTED_ORG(org))
        dispatch(REMOVE_SELECTED_USER())
        navigate('/contracts');
    } 

    return (
        <>
        <RemoveOrgModal title="Felhasználó törlése" org={selectedUser} showModal={showRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <ModifyOrgModal title="adatai" org={selectedUser} showModal={showModifyModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <CreateOrgModal title="Új cég" org={selectedUser} showModal={showCreateModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <div className="m-10">
            <Spinner show={spinner} width={12} height={12}/>
            <button onClick={openCreateModal} className={`hover:opacity-80 text-center rounded-lg shadow-lg bg-success text-sm text-white p-2`}>Új cég felvétele</button>
                {JSON.stringify(state?.org)}
            {(orgs && !spinner) && ( 
               
                <div className="flex justify-center border p-3 m-2 mx-auto">
                    <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                        <thead className="bg-dark-blue text-cream">
                            <tr>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Cég neve</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Cég típusa</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Cég email</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Cég képviselője</th>

                                {(template) && (
                                    <>
                                        <th scope="col" className="p-4"><span className="sr-only">Kiválaszt</span></th>
                                    </>
                                )}   


                                {(!template) && (
                                    <>
                                        <th scope="col" className="p-4"><span className="sr-only">Módosítás</span></th>
                                        <th scope="col" className="p-4"><span className="sr-only">Törlés</span></th>
                                    </>
                                )}

                                  
                            </tr>
                        </thead>
                        
                        <tbody className="divide-y bg-dark-blue divide-gray-600">
                            {orgs.map((org, index) => ( 
                                <tr key={index} className={`hover:bg-light-blue ${selectedUser === org ? `bg-light-blue` : `bg-dark-blue`}`}>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{org.id}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{org.full_name}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{org.org_type}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{org.email}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{
                                        org.leader? 
                                        <>
                                        <p>{org.leader?.name}</p> 
                                        <button onClick={() => removeMemberFromOrganization(org, org?.leader)} className="text-cream text-center hover:underline">Eltávolít</button> 
                                        </>
                                        :
                                        <button onClick={() => navigate('/manager', { state: { org } })} className="text-cream text-center hover:underline">Kiválaszt</button>
                                    }
                                    </td>
                                    

                                    {template && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => addOrganizationToContract(org)} className="text-cream hover:underline">Hozzárendel: {org.name}</button>
                                            </td>
                                        </>
                                    )}
                                    {!template && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => openModal(org, 'modify')} className="text-cream hover:underline">Módosít</button>
                                            </td>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => openModal(org, 'remove')} className="text-cream hover:underline">Törlés</button>
                                            </td>
                                        </>
                                    )}
                                    
                                </tr>
                            ))}
                        </tbody>
                            
                    </table>
                    
                </div>
                )}
            </div>
        </>
    )
}
