import React, { useState } from "react";
import { Video } from "../../../models/video.model";
import { getVideo } from "../../../api/getVideo";
import { System } from "../../../models/system.model";
import ModifyVideoModal from "../modals/ModifyVideoModal";
import { News } from "../../../models/news.model";

type NewsProps = {
    selectedProject: System | undefined;
    selectedNewsRecord: News | undefined;
    videos: Video[];
}

export default function VideosList ({ selectedProject, selectedNewsRecord, videos } : NewsProps) {

    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = React.useState<Video>();
    const [showModifyRecordModal, setModifyRecordModal] = React.useState<boolean>(false); // new record modal state

    const openModifyModal = (video: Video) => {
        setSelectedRecord(video)
        setModifyRecordModal(true)
    };

    const closeModal = () => {
        setModifyRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    return (
        <>
        <ModifyVideoModal title={"Meglévő videó módosítása"} videos={selectedRecord} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        
        <div className="w-1/2 overflow-x-auto text-white h-full">
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                <thead className="bg-gray-800 sticky top-0">
                    <tr>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Videó azonosítója</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Videó URL</th>
                        <th className="border p-2 hover:opacity-80 cursor-pointer">Videó létrehozója</th>
                        <th colSpan={2} className="border p-2 hover:opacity-80 cursor-pointer">Videó műveletek</th>
                    </tr>
                </thead>
                <tbody className="bg-dark-blue text-white">
                
                    {videos.map((video, index) => (
                        <tr key={index} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                            <td className="text-lg font-semibold text-center py-2">
                                {video.id}
                            </td>
                            <td className="text-lg font-semibold text-center py-2">
                                {video.uri}
                            </td>
                            <td className="text-lg font-semibold text-center py-2">
                                {video?.author?.name}
                            </td>

                            <td>
                                <p onClick={() => openModifyModal(video)} className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosít</p>
                            </td>
                            <td>
                                <p className="text-error hover:opacity-80 text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
        </>
    )
}