import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset } from '../../models/assets.model';


interface IAsset {
  asset: Asset | undefined;
}

const initialState: IAsset = {
  asset: undefined
}
// reducers

const selectedAssetSlice = createSlice({
    name: 'selectedAsset',
    initialState,
    reducers: {
      SET_SELECTED_ASSET: (state, action: PayloadAction<Asset>) => {
        state.asset = action.payload;
      },
      REMOVE_SELECTED_ASSET: (state) => {
        state.asset = initialState.asset;
      },
    },
  });

// exports

export const { SET_SELECTED_ASSET, REMOVE_SELECTED_ASSET } = selectedAssetSlice.actions;

export default selectedAssetSlice.reducer;

export const selectedAssetState = (state: { selectedAsset: IAsset }) => state.selectedAsset.asset;