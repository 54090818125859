import React, { useState } from 'react';
interface SVGProps {
    color: string;
}

export const EnergyIcon = ({ color }: SVGProps) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const iconColor = hovered ? '#E8D788' : "#FFF"; // Change 'red' to the desired hover color

  return (
    <svg 
        fill={iconColor} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M13 2L3 14h8l-1 8l10-12h-8l1-8z"/>
    </svg>
  );
};
