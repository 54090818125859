import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Template } from '../../models/templates.model';

interface NavElement {
    index: number;
    title: string;
}
interface MenuValues {
    daisyGroups: NavElement[],
    hpmGroups: NavElement[],
    templates: Template[],
    insurances: NavElement[],
}

const initialState : MenuValues = {
    daisyGroups: [],
    hpmGroups: [],
    templates: [],
    insurances: [],
};
// reducers

const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
    SET_DAISY_GROUPS: (state, action: PayloadAction<Pick<MenuValues, "daisyGroups">>) => {
        state.daisyGroups = action.payload.daisyGroups;
    },
    SET_HPM_GROUPS: (state, action: PayloadAction<Pick<MenuValues, "hpmGroups">>) => {
        state.hpmGroups = action.payload.hpmGroups;
    },
    SET_TEMPLATES: (state, action: PayloadAction<Template[]>) => {
        state.templates = action.payload;
    },
    SET_INSURANCES: (state, action: PayloadAction<Pick<MenuValues, "insurances">>) => {
        state.hpmGroups = action.payload.insurances;
    },
    },
  });

// exports

export const { SET_DAISY_GROUPS, SET_HPM_GROUPS, SET_TEMPLATES, SET_INSURANCES } = navSlice.actions;

export default navSlice.reducer;

export const selectNavStates = (state: { nav: MenuValues }) => state.nav;