import { ModalProps } from "../../../types/modalProps";
import { User } from "../../../models/user.model";
import { responseHero } from '../../../utils/axiosInterceptor';
import { toast } from "react-toastify";

const RemoveUserModal = ({ title, user, showModal, closeModal, handleStateChange } : ModalProps) => {


  const removeUser = async (user: User | undefined) => {
    if(!user) return;
    await responseHero.delete(`${process.env.REACT_APP_API_URL}/users/${user.user_id}`).then((response) => {

        closeModal()
        handleStateChange();

        toast.success(`Felhasználó törölve!`);
    }).catch(error => {
        console.error(error.response.data);
        closeModal()

        toast.error('Felhasználó törlése sikertelen!')
    })
  }
  return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <p>Biztos vagy benne, hogy törlöd a következő felhasználót: <b>{user?.name}</b> ?</p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={() => removeUser(user)}
                        >
                            Törlés
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default RemoveUserModal;