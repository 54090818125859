export default function NoPage () {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-500">404</h1>
                <p className="text-2xl font-medium text-gray-800">Az oldal nem található!</p>
                <p className="text-gray-600">Úgy tűnik az oldal amit keresel nem található!</p>
            </div>
        </div>
    )
}
