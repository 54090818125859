import React from "react";
import CreateNewGroupModal from "../modals/CreateNewGroupModal";

type GroupCreatorProps = {
    system_id: number;
    newGroupAdded: () => void;
}

export default function GroupCreator ({ system_id, newGroupAdded } : GroupCreatorProps) {

    const [groupName, setGroupName] = React.useState('')

    /* Modals */
    const [showGroupCreatorModal, setShowGroupCreatorModal] = React.useState<boolean>(false); // group creator modal state


    const openModal = () => {
        setShowGroupCreatorModal(true)
    };

    const closeModal = () => {
        setShowGroupCreatorModal(false)
    };

    return (
        <>
        <CreateNewGroupModal title="Új csoport" showModal={showGroupCreatorModal} closeModal={closeModal} handleStateChange={newGroupAdded} system_id={system_id}/>
        <div className="w-fit items-center text-sm font-medium border rounded-lg sm:flex">
            <button onClick={openModal} value={groupName} className="bg-dark-blue rounded-lg px-4 py-2 text-white hover:underline float-right">Új csoport indítása</button>
        </div>
        </>
    )
}
