import { useSelector } from "react-redux";
import { Asset } from "../../../../models/assets.model";
import React from "react";

type AssetsMenuProps = {
    assetsList: Asset[];
    selectedAsset: Asset | undefined;
    handleSelectedAsset: (asset: Asset) => void;
}

export default function AssetsMenu({ assetsList, selectedAsset, handleSelectedAsset } : AssetsMenuProps) {
    return (
        <div className="bg-dark-blue px-4 py-4">
        <ul>
            {assetsList.map((asset, index) => (
                <li
                    key={index}
                    onClick={() => handleSelectedAsset(asset)}
                    className={`flex items-center hover:cursor-pointer hover:bg-gray-500 border-b mb-2 text-center text-white text-lg px-1 py-1 ${selectedAsset?.id === asset.id ? 'bg-gray-500' : ''}`}
                >
                    <img
                        src={`https://assets.coincap.io/assets/icons/${asset.code.toLowerCase()}@2x.png`}
                        width={30}
                        height={30}
                        alt="Logo of asset"
                        className="mr-2"
                    />
                    {asset.name} - ({asset.code})
                </li>
            ))}
        </ul>
    </div>
    
    );
  }
