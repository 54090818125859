type SendingNewsLetterModalProps = {
    title: string;
    showModal: boolean;
    sendingStatus: boolean;
}
const SendingNewsLetterModal = ({ title, showModal, sendingStatus } : SendingNewsLetterModalProps ) => {
    return (
        <>
            {(showModal) && (
                <>
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                            <h3 className="text-3xl font=semibold">{title}</h3>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <p className="">Hírlevél elküldése... Kérlek várj még minden levél kézbesítésre kerül. (Pár perc...)</p>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
}

export default SendingNewsLetterModal;