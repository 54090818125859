import React from "react";
import { useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { MiningCoinConversion } from "../../../models/mcc.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    miningMachineIds: number[] | undefined;
}

type ModifiedPayload = Pick<MiningCoinConversion, 
"date" |
"quantity" |
"exchange_rate">

const AddCoinConversionModal = ({ title, showModal, closeModal, handleStateChange, miningMachineIds } : ModalProps) => {

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        quantity: "",
        exchange_rate: 0,
        date: new Date(),
    });

    const [isValueDistributed, setValueDistribution] = React.useState<boolean>(false);

    const handleDsChange = () => {
        setValueDistribution(!isValueDistributed);
    };

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let inputValue = e.target.value;
    
        setRecordData((prevData) => {
            let updatedValue: any;
    
            switch (type) {
                case "quantity":
                    if (/^(\d*\.)?\d{0,8}$/.test(inputValue)) {
                        updatedValue = inputValue;
                    } else {
                        return prevData;
                    }
                    break;
                case "exchange_rate":
                    if (/^(\d*\.)?\d{0,8}$/.test(inputValue)) {
                        updatedValue = parseFloat(inputValue);
                    } else {
                        return prevData;
                    }
                    break;
                case "date":
                    updatedValue = inputValue;
                    break;
                default:
                    updatedValue = inputValue;
            }
    
            return {
                ...prevData,
                [type]: updatedValue
            };
        });
    }
    
    
          
    
    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/miningcc`, { miningMachineIds: miningMachineIds, miningCC: recordData, copyValues: isValueDistributed }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen létrehoztál egy konverziót!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">BÁNYAGÉP</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">

                                     <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Konverzió </span> <br/>Váltás dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="date" placeholder="Dátum"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Konverzió </span> <br/>Coin mennyiség
                                        </label>
                                        <input
                                            value={recordData?.quantity}
                                            onChange={(e) => handleChange('quantity', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Coin mennyiség (BTC)"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                        <span className="text-success">Konverzió </span> <br/>Árfolyam ($)
                                        </label>
                                        <input
                                        value={recordData?.exchange_rate}
                                        onChange={(e) => handleChange('exchange_rate', e)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" placeholder="Árfolyam USDT-ben"/>
                                    </div>

                                    
                                </div>

                                <div className="p-5 flex flex-row text-center align-center justify-center">
                                    <label className="text-lg">A bevitt értékek másolása (nincs szétosztás): </label>
                                    <input className="h-6 w-6 ml-2" type="checkbox" checked={isValueDistributed} onChange={handleDsChange} />
                                </div>

                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddCoinConversionModal;