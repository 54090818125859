import { News } from "../models/news.model";
import { System } from "../models/system.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getNews = (system_id: number | undefined): Promise<News[]> => {
    return new Promise<News[]>((resolve, reject) => {
        if(!system_id) return;
        responseHero.get(`${process.env.REACT_APP_API_URL}/news/${system_id}`).then((response) => {
            resolve(response.data as News[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};