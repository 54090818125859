import { VoteType } from "../models/votetype.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getVoteTypes = (): Promise<VoteType[]> => {
    return new Promise<VoteType[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/voteTypes`).then((response) => {
            resolve(response.data as VoteType[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};