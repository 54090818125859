export const dateFormatter = (inputDate: string): string => {
    const dateObj = new Date(inputDate);
  
    const formattedDate = `${dateObj.getUTCFullYear()}-${pad(dateObj.getUTCMonth() + 1)}-${pad(dateObj.getUTCDate())} ${pad(dateObj.getUTCHours())}:${pad(dateObj.getUTCMinutes())}`;
  
    function pad(value: number): string {
      return value.toString().padStart(2, "0");
    }
  
    return formattedDate;
}