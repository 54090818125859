import React, { useState } from 'react';
interface SVGProps {
    color: string;
}

export const PlusIcon = ({ color }: SVGProps) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const iconColor = hovered ? '#E8D788' : "#FFF"; // Change 'red' to the desired hover color

  return (
    <svg 
        fill={iconColor} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M10 5v5H5v2h5v5h2v-5h5v-2h-5V5h-2z"/>
    </svg>
  );
};
