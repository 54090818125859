import { useSelector } from "react-redux";
import { Asset } from "../../../../models/assets.model";
import React from "react";
import AssetBuyPanel from "./AssetBuyPanel";
import AssetSellPanel from "./AssetSellPanel";
import { toast } from "react-toastify";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { Crypto } from "../../../../models/crypto.model";

type AssetsMenuProps = {
    selectedAsset: Asset | undefined;
}

export default function AssetTradingSettings({ selectedAsset } : AssetsMenuProps) {

    const [operation, setOperation] = React.useState<boolean>(true);
    const [takeRecordsCount, setTakeRecordsCount] = React.useState<number>(1);
    const [takenRecords, setTakenRecords] = React.useState<Crypto[]>([]);

    const menu = useSelector(selectMenuStates)

    const getLastestRecords = async () => {
        await responseHero.get(`${process.env.REACT_APP_API_URL}/tradinghelper/${menu?.daisyMenu?.group_id}/${selectedAsset?.id}/${takeRecordsCount}/true`).then((response) => {
            setTakenRecords(response.data)
        }).catch(error => {
            console.error(error.response.data);
        })
    }

    const handleClick = async () => {
        try {
            await getLastestRecords();
        } catch (err) {
            toast.error("Hiba az adatok lekérése közben!")
            console.error(err);
        } finally {
            toast.success("Sikeres adatlekérés!")
        }
    };
    

    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    return (
      <div className="ml-5">

        <div className="w-full mb-5 items-center justify-center rounded flex flex-row space-x-4">
          <p onClick={() => setOperation(true)} className={`hover:cursor-pointer ${operation ? 'bg-dark-blue' : 'bg-gray-500'} px-4 py-2 hover:opacity-90 text-white text-center text-2xl w-1/2`}><span className="text-cream">{selectedAsset?.name}</span> - Vásárlás</p>
          <p onClick={() => setOperation(false)} className={`hover:cursor-pointer ${operation ? 'bg-gray-500' : 'bg-dark-blue'} px-4 py-2 hover:opacity-90 text-white text-center text-2xl w-1/2`}><span className="text-cream">{selectedAsset?.name}</span> - Eladás</p>
        </div>

        <div className="bg-white mt-2 border-b-4 border-dark-blue flex space-x-4 px-2 py-2 items-center">
            <label className="text-lg text-dark-blue">Hány darab tranzakciót figyeljünk a kereskedés végéről?</label>
            <input onChange={(e) => setTakeRecordsCount(parseInt(e.target.value, 10))} value={takeRecordsCount} className="text-center  border w-max text-lg" type="number" max={10} min={1}></input>   
            <label className="text-lg text-dark-blue">db</label>
            <button className="text-center px-2 py-2 bg-dark-blue text-white hover:opacity-100 opacity-80"
            onClick={handleClick}
            >Megnyitás</button>
        </div>

        <div className="bg-white" style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0 text-white">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Darabszám</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Árfolyam USDT</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Összeg USDT</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {takenRecords.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.transaction_id}</td>
                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "BUY" : "SELL"}</td>
                                <td className={dataRowStyling}>{record?.quantity}</td>
                                <td className={dataRowStyling}>{record?.exchange_rate}</td>
                                <td className={dataRowStyling}>{record?.amount}</td>
                            </tr>
                        ))}
                    </tbody>
            </table>

            {takenRecords.length > 0 && (
                <div className="bg-white flex space-x-4 px-2 py-2">
                    <p className="text-xl">A tranzakciók átlagos árfolyama: <span className="font-semibold">{calculateAvarageExchangeRateOfLatestTransactions(takenRecords)}$</span></p>
                </div>
            )}
        </div>

        {operation ? (
          <AssetBuyPanel selectedAsset={selectedAsset} takenRecords={takenRecords}/>
        ) :
          <AssetSellPanel selectedAsset={selectedAsset} takenRecords={takenRecords}/>
        }
        
        
      </div>
    );
  }

  export const calculateAvarageExchangeRateOfLatestTransactions = (takenRecords: Crypto[]) => {
    if(takenRecords.length === 0) return 0;

    const totalExchangeRate = takenRecords.reduce((accumlator, current) => accumlator += parseFloat(current.exchange_rate), 0);
    return parseFloat((totalExchangeRate / takenRecords.length).toFixed(2));
  }

  export const getPercentage = (val: number, percentage: number) => {
    return ((val / 100) * percentage);
  }
