import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getDepositRecords } from "../../../../../api/getHumanDCFunctions";
import { DepositRegister } from "../../../../../models/depositregister";
import ModifyRecordModal from "../../../modals/dashboard/ModifyRecordModal";
import AddNewRecordModal from "../../../modals/dashboard/AddNewRecordModal";
import { User } from "../../../../../models/user.model";
import RemoveRecordModal from "../../../modals/dashboard/RemoveRecordModal";
import { Assignments } from "../../../../../types/assigments";
import { getLiquidityTransfers } from "../../../../../api/getLiquidityTransfers";
import { HumanDashboard } from "../../../../../models/types/HumanDashboard";
import { LiqudityTransfer } from "../../../../../models/liqtrans.model";

export default function FormContainer () {

    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [showModifyRecordModal, setShowModifyRecordModal] = React.useState<boolean>(false); // modify record modal state
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // modify record modal state

    const [records, setRecords] = React.useState<HumanDashboard>();
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)

    const [selectedRecord, setSelectedRecord] = React.useState<LiqudityTransfer>();
    const [currentGroupLiq, setCurrentGroupLiq] = React.useState<number>(0);

    const menu = useSelector(selectMenuStates)

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openModifyRecordModal = (record: LiqudityTransfer) => {
        setSelectedRecord(record)
        setShowModifyRecordModal(true)
    };

    const openRemoveModal = (record: LiqudityTransfer) => {
        setSelectedRecord(record)
        setShowRemoveRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowModifyRecordModal(false)
        setShowRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }
    
    React.useEffect(() => {
        getLiquidityTransfers(menu?.daisyMenu)
        .then((response) => {
            setRecords(response)
            setCurrentGroupLiq(response.liquidity);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [menu?.daisyMenu, recodsStateChange])

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#E8D788";
    const defaultValue = "#D3D3D3";

    const getColor = (number1: number, number2: number) => {
        if(Number(number1) === Number(number2)) {
            return "#aaa";
        
        }
        return "#E8D788";
    }
    return (
        <>
        <div className="w-full overflow-x-auto text-white h-full">
            
            <div className="flex flex-row space-x-4 items-center justify-center">
                <p className="px-4 py-3 text-md bg-gray-500 text-center">A program szerinti likviditás: <span className="font-semibold text-cream">{currentGroupLiq.toLocaleString('en-En').replace(',', ' ')}$</span></p>
                <button className="px-4 py-3 border bg-dark-blue shadow-md rounded hover:opacity-80 text-lg" onClick={() => openModal()}>Elvonás rögzítése</button>
            </div>
            
            <ModifyRecordModal title={"Adatsor módosítása"} group={menu.daisyMenu} showModal={showModifyRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <AddNewRecordModal title={"Adatsor hozzárendelése"} group={menu.daisyMenu} showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
            <RemoveRecordModal title={"Adatsor törlése"} group={menu.daisyMenu} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Művelet</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Megjegyzés</th>
                            <th className="border p-2 bg-gray-700 hover:opacity-80 cursor-pointer">Összeg (USD)</th>
                            
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Módosít</span></th>
                            <th scope="col" className="border p-2 hover:opacity-80 cursor-pointer"><span className="sr-only">Törlés</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {records?.transfers?.map((record, index) => (
                            <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.id}</td>
                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={`${dataRowStyling} ${record.operation ? 'bg-success' : 'bg-error'}`}>{record.operation ? "ÉRKEZETT" : "ELVONVA"}</td>
                                <td className={dataRowStyling}>{record?.caption}</td>
                                <td className={dataRowStyling}>{record?.amount}</td>
                                
                                

                                <td className={dataRowStyling}>
                                    <p onClick={() => openModifyRecordModal(record)}className="text-cream hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Módosítás</p>
                                </td>

                                <td className={dataRowStyling}>
                                    <p onClick={() => openRemoveModal(record)} className="text-red-500 hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}