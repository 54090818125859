import React from "react";
import { useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MiningMachine } from "../../../models/miningmachine.model";
import { selectUser } from "../../../store/reducers/userSlice";
import { getMiningMachineTronTypes, getMiningMachineTypes } from "../../../api/getMiningMachineTypes";
import { MiningMachineType } from "../../../models/miningmachinetype.model";
import { Group } from "../../../models/group.model";
import { MiningMachineTron } from "../../../models/miningmachinetron.model";

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    group: Group | undefined;
}

/*
    id: number;
    group_id: number;
    hash: string;
    name: string;
    nft: string;
    creatorId: number;
    miningMachineType: number;
    creator: User;
    miningMachine: MiningMachineType;
    group: Group;

*/
type ModifiedPayload = Pick<MiningMachine, 
"group_id" |
"hash" |
"name" |
"nft" |
"creatorId" |
"miningMachineType" |
"miningMachineTronType" |
"fee" |
"purchase_price">

const AddNewMiningMachineModal = ({ title, showModal, closeModal, handleStateChange, group } : ModalProps) => {

    const user = useSelector(selectUser)

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        group_id: group ? group?.group_id : -1,
        name: "A bányagép neve",
        hash: "",
        nft: "",
        creatorId: user.userInfo.user_id,
        miningMachineType: -1,
        miningMachineTronType: -1,
        purchase_price: 3800,
        fee: 100
    });

    const [miningMachineTypes, setMiningMachineTypes] = React.useState<MiningMachineType[]>([]);
    const [miningMachineTronTypes, setMiningMachineTronTypes] = React.useState<MiningMachineTron[]>([]);
    const [selectedMiningMachineTypeID, setSelectedMiningMachineTypeID] = React.useState<number>();
    const [selectedMiningMachineTronTypeID, setSelectedMiningMachineTronTypeID] = React.useState<number>();

    React.useEffect(() => {
        if(!group) return;
        setRecordData((prevData) => ({
            ...prevData,
            group_id: group?.group_id
        }));
    }, [group])

    React.useEffect(() => {
        getMiningMachineTypes()
        .then((response) => {
            setMiningMachineTypes(response)

            if(response.length > 0) {
                handleMiningMachineTypeSelection(response[0].id);
            }
        })
        .catch((error) => {
            console.error(error);
        }); 

        getMiningMachineTronTypes()
        .then((response) => {
            setMiningMachineTronTypes(response)

            if(response.length > 0) {
                handleMiningMachineTronTypeSelection(response[0].id);
            }
        })
        .catch((error) => {
            console.error(error);
        }); 
      }, [])

    const handleChange = (
        type: keyof ModifiedPayload,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let inputValue = e.target.value;

        setRecordData((prevData) => ({
            ...prevData,
            [type]: (type === "purchase_price" || type === "fee") ? parseInt(inputValue, 10) : inputValue
        }));
    }
          
    
    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/miningMachine`, { miningMachine: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen hozzáadtál egy gépet!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    const handleMiningMachineTypeSelection = (id: number) => {
        setSelectedMiningMachineTypeID(id);

        setRecordData((prevData) => ({
            ...prevData,
            miningMachineType: id,
        }));
    }

    const handleMiningMachineTronTypeSelection = (id: number) => {
        setSelectedMiningMachineTronTypeID(id);

        setRecordData((prevData) => ({
            ...prevData,
            miningMachineTronType: id,
        }));
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">BÁNYAGÉP</span> - {title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagép </span> <br/>Név
                                        </label>
                                        <input
                                            value={recordData?.name}
                                            onChange={(e) => handleChange('name', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="A gép neve"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagép </span> <br/>Hash
                                        </label>
                                        <input
                                            value={recordData?.hash}
                                            onChange={(e) => handleChange('hash', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Hash érték"/>
                                    </div>

                                    <div className="w-1/3 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagép </span> <br/>NFT szám
                                        </label>
                                        <input
                                            value={recordData?.nft}
                                            onChange={(e) => handleChange('nft', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="NFT szám"/>
                                    </div>
                                </div>
                                <div className="flex p-2 rounded shadow-md">

                                    <select defaultValue={selectedMiningMachineTypeID ? selectedMiningMachineTypeID : -1} onChange={(e) => handleMiningMachineTypeSelection(parseInt(e.target.value))} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                        <option disabled={true} value={-1} className="px-2 py-1 text-center">{"Bányagéptípus kiválasztása ..."}</option>
                                        {miningMachineTypes?.map((miningMachineType, index) => (
                                            <option value={miningMachineType.id} className="px-2 py-1 text-center" key={index}>{miningMachineType.name} ({miningMachineType.id})</option>
                                        ))}
                                    </select>

                                 </div>

                                 <div className="flex p-2 rounded shadow-md">

                                    <select defaultValue={selectedMiningMachineTronTypeID ? selectedMiningMachineTronTypeID : -1} onChange={(e) => handleMiningMachineTronTypeSelection(parseInt(e.target.value))} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                        <option disabled={true} value={-1} className="px-2 py-1 text-center">{"TRON pénztárca kiválasztása ..."}</option>
                                        {miningMachineTronTypes?.map((mmTron, index) => (
                                            <option value={mmTron.id} className="px-2 py-1 text-center" key={index}>{mmTron.name} ({mmTron.id})</option>
                                        ))}
                                    </select>

                                 </div>

                                 <div className="flex p-2 rounded shadow-md">

                                    <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagép </span> <br/>Vételár
                                        </label>
                                        <input
                                            value={recordData?.purchase_price}
                                            onChange={(e) => handleChange('purchase_price', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="number" placeholder="Vásárlási ár (USD)"/>
                                    </div>

                                    <div className="w-1/2 pr-2 text-center justify-center items-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                            <span className="text-success">Bányagép </span> <br/>Fee
                                        </label>
                                        <input
                                            value={recordData?.fee}
                                            onChange={(e) => handleChange('fee', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="number" placeholder="Fee (USD)"/>
                                    </div>
                                 </div>
                                
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewMiningMachineModal;