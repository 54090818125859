import { useState } from "react";
import { ModalProps } from "../../../types/modalProps";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { selectUser } from "../../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { ModalPropsWithSystemID } from "../../../types/modalProps";



const CreateNewGroupModal = ({ title, system_id, showModal, closeModal, handleStateChange } : Omit<ModalPropsWithSystemID, "user">) => {

    const [groupName, setGroupName] = useState<string>('')
    const user = useSelector(selectUser)

    const generateGroup = async () => {
        if(groupName.length < 3) {
            return toast.warn('Minimum 3 karakter hosszú csoportnevet adj meg!')
        }
        await responseHero.post(`${process.env.REACT_APP_API_URL}/groups/${system_id}`, { group_name: groupName, creatorId: user? user.userInfo.user_id : 0 }).then((response) => {
            closeModal()
            handleStateChange()

            toast.success("Sikeres csoportlétrehozás!");
        }).catch(error => {
            toast.error("Sikertelen a csoport létrehozása!");
            console.error(error.response.data);
            closeModal()
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="g_name">
                                Csoport neve
                            </label>
                                <input value={groupName} onChange={(e) => setGroupName(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="g_name" type="text" placeholder="Csoport neve"/>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={generateGroup}
                        >
                            Létrehozás
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default CreateNewGroupModal;