import React, { Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { getMiningMachineTypes } from "../../api/getMiningMachineTypes";
import { MiningMachineType } from "../../models/miningmachinetype.model";
import ModifyDailyCostDataModal from "./modals/ModifyDailyCostDataModal";

export default function MiningMachineTypeManager () {
    const { id } = useParams();
    const navigate = useNavigate();

    const [recordStateChange, setRecordStateChange] = React.useState(false);
    const [miningMachineTypes, setMiningMachineTypes] = React.useState<MiningMachineType[]>([]);
    const [selectedMiningMachineType, setSelectedMiningMachineType] = React.useState<MiningMachineType>();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        getMiningMachineTypes()
        .then((response) => {
            setMiningMachineTypes(response)
        })
        .catch((error) => {
            console.error(error);
        }); 
      }, [recordStateChange])

    const openModal = (record: MiningMachineType) => {
        setSelectedMiningMachineType(record);
        setShowModal(true);
    }
    const closeModal = () => setShowModal(false);
    const handleStateChange = () => setRecordStateChange(!recordStateChange);
    return (
        <Fragment>
        <ModifyDailyCostDataModal title={"Napi adatok"} showModal={showModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedMiningMachineType}/>
        <div className="container mx-auto p-4">
            <div className="flex justify-center border bg-dark-blue p-3 m-2 mx-auto">
                
                <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                
                    <thead className="bg-dark-blue text-cream">
                        <tr>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bányagéptípus neve</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Napi KW/h fogyasztás</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Napi fogyasztás ára ($)</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Napi termelt coin mennyiség</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Üzemidő (hónap)</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Műveletek</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y bg-dark-blue divide-gray-600">
                    
                        {miningMachineTypes.map((record, index) => (
                            <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.id}
                                </td>
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.name}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-indigo-300">
                                    {record.avg_kwh}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-indigo-300">
                                    {record.avg_price}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-indigo-300">
                                    {record.avg_amount}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-indigo-300">
                                    {record.operatingMonth} ({(record.operatingMonth * 30).toLocaleString('en-En').replace(',', ' ')} nap)
                                </td>

                                <td onClick={() => openModal(record)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream hover:cursor-pointer hover:underline">Adatok módosítása</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
        </div>
        </Fragment>

    )

}