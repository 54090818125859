import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
import { getAssets } from "../../../api/getAssets";
import { Asset } from "../../../models/assets.model";
import React from "react";
import AssetsMenu from "./tradinghelper/AssetsMenu";
import AssetTradingSettings from "./tradinghelper/AssetTradingSettings";

type TradingHelperPanelProps = {
    altcoinsState: boolean;
}

export default function TradingHelperPanel() {
    const menu = useSelector(selectMenuStates);
    const [assets, setAssets] = React.useState<Asset[]>([]);
    const [selectedAsset, setSelectedAsset] = React.useState<Asset>();
    
    React.useEffect(() => {
      getAssets()
      .then((response) => {
        setAssets(response.filter((asset) => !asset.alt))
      })
      .catch((error) => {
          console.error(error);
      }); 
    }, [])

    React.useEffect(() => {
      if(assets.length > 0) {
        setSelectedAsset(assets[0]);
      }
    }, [assets])

    const handleSelectedAsset = (asset: Asset) => {
      setSelectedAsset(asset);
    }

    return (
      <div className="p-3">
        <div className="flex mt-2">
          <div className="w-1/4">
            <AssetsMenu assetsList={assets} selectedAsset={selectedAsset} handleSelectedAsset={handleSelectedAsset}/>
          </div>
          <div className="w-3/4">
            <AssetTradingSettings selectedAsset={selectedAsset}/>
          </div>
        </div>
      </div>
    );
  }
