import { Group } from "../models/group.model";
import { responseHero } from "../utils/axiosInterceptor";
import { Response, Result, GroupData } from "../types/groupDataSummary";

export const getGroupData = (group: Group | undefined): Promise<Response> => {
    return new Promise<Response>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/group/${group?.group_id}`).then((response) => {
            resolve(response.data as Response);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};