import React, { useEffect } from "react";
import { responseHero } from "../../utils/axiosInterceptor";
import { Template } from "../../models/templates.model";
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuStates, set_template_menu, set_insurance_menu } from "../../store/reducers/menuSlice";
import { REMOVE_SELECTED_USER } from "../../store/reducers/selectedUserSlice";
import { SET_TEMPLATES, selectNavStates } from "../../store/reducers/navSlice";
import { RefreshIcon } from "../../styles/assets/svgs/RefreshIcon";
import { useNavigate } from "react-router";
import { REMOVE_SELECTED_ORG } from "../../store/reducers/selectedOrgSlice";

export default function ContractTypeList() {
    const [templates, setTemplates] = React.useState<Template[]>([]);
    const [spinner, setSpinner] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const menu = useSelector(selectMenuStates);
    const nav = useSelector(selectNavStates);

    const navigate = useNavigate();

    /* Memoized callback */
    const getTemplates = React.useCallback(async () => {
        setSpinner(true);
        dispatch(SET_TEMPLATES([]));
        await responseHero.get(`${process.env.REACT_APP_API_URL}/templates`).then((response) => {
            setTemplates(response.data.reverse());
            dispatch(SET_TEMPLATES(response.data));
            setSpinner(false);
        }).catch(error => {
            console.error(error.response.data);
            setSpinner(false);
        });
    }, [templates, dispatch]);

    /* Effects */
    React.useEffect(() => {
        // Only fetch templates if contracts array is empty
        if (nav.templates.length === 0) {
          getTemplates();
        }
    }, [getTemplates, nav.templates.length]);

    return (
        <nav className="bg-dark-blue border-t-2 border-brown">
            <div className="max-w-screen-xl mx-auto px-2 py-4">
                <div className="flex items-center">
                    <ul className="flex flex-wrap flex-row font-medium mt-0 space-x-4 space-y-4 text-sm">
                        <li onClick={getTemplates}>
                            <RefreshIcon color={"#FFFFFF"} />
                        </li>
                        <li>
                            <div className="">
                                <p onClick={() => navigate('/envelopes')} className="text-gray-100 text-md hover:underline text-gray-300 cursor-pointer">Előzmények</p>
                            </div>
                        </li>
                        {spinner && (
                            <li>
                                <div className="py-2 px-2">
                                    <p className="text-cream">Betöltés ...</p>
                                </div>
                            </li>
                        )}
                        {nav?.templates?.map((template, index) => (
                            <li key={index} className="m-auto">
                                <p onClick={() => {
                                    dispatch(REMOVE_SELECTED_USER());
                                    dispatch(REMOVE_SELECTED_ORG());
                                    dispatch(set_template_menu({ templateMenu: template }));
                                }}
                                className={`hover:underline border px-2 py-2 cursor-pointer ${menu.templateMenu === template ? 'text-cream underline px-1 py-1 rounded shadow-sm shadow-gray-200' : 'text-white'}`}>{template.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
