import { useSelector } from "react-redux";
import { Asset } from "../../../../models/assets.model";
import React from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { toast } from "react-toastify";
import { Crypto } from "../../../../models/crypto.model";
import { calculateAvarageExchangeRateOfLatestTransactions, getPercentage } from "./AssetTradingSettings";
import LiqLevelsModal from "../../modals/tradinghelper/BuyLevelChangeModal";
import BuyLevelChangeModal from "../../modals/tradinghelper/BuyLevelChangeModal";
import { getCurrentLiquidityValue } from "../../../../api/getCurrentLiquidityValue";

type AssetsMenuProps = {
    selectedAsset: Asset | undefined;
    takenRecords: Crypto[];
}

export type BuyingLevels = {
    first: number;
    second: number;
    third: number;
}

export default function AssetBuyPanel({ selectedAsset, takenRecords } : AssetsMenuProps) {

    const [exchangeDrop, setExchangeDrop] = React.useState<number>(15);

    const menu = useSelector(selectMenuStates)

    const [liqLevelModal, setLiqLevelModal] = React.useState<boolean>(false);
    const [stateChange, setStateChange] = React.useState<boolean>(false);
    const [currentLiq, setCurrentLiq] = React.useState<number>(0);
    const [buyingLevels, setBuyingLevels] = React.useState<BuyingLevels>({
        first: 50,
        second: 30,
        third: 20
    });

    React.useEffect(() => {
        if(selectedAsset && menu?.daisyMenu) {

            getCurrentLiquidityValue(menu?.daisyMenu?.group_id)
            .then((response) => {
              setCurrentLiq(response.amount);
            })
            .catch((error) => {
              console.error(error);
            });
          };
    
          
        }, [selectedAsset, menu?.daisyMenu]);
  
    const closeModal = () => {
      setLiqLevelModal(false);
    }
  
    const handleStateChange = () => {
      setStateChange(!stateChange);
    }

    const calculateExchangeDrop = () => {
        if(takenRecords.length === 0 && exchangeDrop <= 0) return 0;
        return parseFloat((calculateAvarageExchangeRateOfLatestTransactions(takenRecords) * (1 - (exchangeDrop / 100))).toFixed(2));
    }
    return (
    <>
        <BuyLevelChangeModal title={"Likviditási szintek"} showModal={liqLevelModal} closeModal={closeModal} handleStateChange={handleStateChange} buyingLevels={buyingLevels}/>
        
        <div className="mt-5">    
            <div className="bg-white rounded shadow-sm shadow-gray-400 flex space-x-4 px-2 py-2 items-center">
                <label className="text-lg text-dark-blue">Hány százalékos <strong>árfolyamesést</strong> nézzünk? </label>
                <input onChange={(e) => setExchangeDrop(parseInt(e.target.value, 10))} value={exchangeDrop} className="text-center  border w-max text-lg" type="number" max={10} min={1}></input>   
                <label className="text-lg text-dark-blue">%</label>
                <button className="text-center px-2 py-2 bg-dark-blue text-white hover:opacity-100 opacity-80"
                onClick={() => alert('Mentve')}
                >Változtatás</button>
            </div>

            {(takenRecords.length > 0 && exchangeDrop > 0) && (
                <>
                <div className="bg-white rounded shadow-sm shadow-gray-400 mt-2 flex space-x-4 px-2 py-2 items-center">
                    <p className="text-xl">{exchangeDrop}% -os esésnél az árfolyam: <span className="font-semibold">{calculateExchangeDrop()}$</span></p>
                </div>

                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <h1 className="text-2xl text-center">Rendelkezésre álló likviditás: <span className="text-emerald-600">{currentLiq?.toLocaleString('en-En').replace(',', ' ')}$</span></h1>
                
                    <div className="flex space-x-4 px-2 py-2 items-center">
                        <ul className="px-2 py-2 bg-gray-100 rounded w-full flex flex-row space-xs-4 items-center">
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>I. vásárlási szint: A likviditás: <span className="text-cream">{buyingLevels.first}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(currentLiq, buyingLevels.first).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>II. vásárlási szint: A likviditás: <span className="text-cream">{buyingLevels.second}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(currentLiq, buyingLevels.second).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>III. vásárlási szint: A likviditás: <span className="text-cream">{buyingLevels.third}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(currentLiq, buyingLevels.third).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>

                            <li className="py-2 ml-2">
                                <button className="text-center px-2 py-2 bg-dark-blue text-white hover:opacity-100 opacity-80 ml-2"
                                    onClick={() => setLiqLevelModal(true)}
                                >Változtatás</button>
                            </li>
                        </ul>
                    </div>
                </div>
                </>
            )}
        </div>
      </>
    );
  }
