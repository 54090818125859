import React, { useEffect, useState } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { DaisyDeposits } from "../../../../models/daisydeposits.model";
import { Systems } from "../../../../enums/systems";
import { UserWithInvestment } from "../../../../types/userWithInvestment";
import { getGroupMembers } from "../../../../api/getGroupMembers";
import { System } from "../../../../models/system.model";

type ModifiedPayload = Omit<DaisyDeposits, "id" | "user" | "group">

type ModalProps = {
    title: string;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    incoming: boolean;
    system: System | undefined;
}

const AddNewDepositModal = ({ title, showModal, closeModal, handleStateChange, incoming, system } : ModalProps) => {

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        date: new Date(),
        deposit: 0,
        user_id: -1,
        group_id: -1,
        incoming: incoming,
    });

    const [groups, setGroups] = React.useState<Group[]>([])
    const [groupMembers, setGroupMembers] = React.useState<UserWithInvestment[]>([]);
    
    /* Effects */
    React.useEffect(() => {
        const getGroups = async () => {
            try {
                const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/groups`);
                
                const filteredGroups = response.data.filter((group: Group) => group.system_id === system?.id && group.running === true);
                
                if(filteredGroups.length === 0) {
                    return;
                }
                setGroups(filteredGroups);
            } catch (error: any) {
                console.error(error.response.data);
            }
        }

        getGroups()
      }, [system]);

    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        setRecordData(prevData => ({
            ...prevData,
            [type]: type === "date" ? e.target.value : parseInt(e.target.value, 10)
        }));
    };

    const handleGroupSelection = (group_name: string) => {
        const findGroupID = groups.find((group) => group.group_name === group_name)?.group_id;
        if(!findGroupID) return;

        setRecordData(prevData => ({
            ...prevData,
            group_id: findGroupID
        }));

        getGroupMembers(findGroupID)
        .then((response) => {
            setGroupMembers(response)
            handleUserSelection(response[0].name, response)
        })
        .catch((error) => {
            console.error(error);
        }); 
    }

    useEffect(() => {
        setRecordData(prevData => ({
            ...prevData,
            incoming: incoming
        }));
    }, [incoming])

    const handleUserSelection = (user_name: string, groupMembers: UserWithInvestment[]) => {
        const user = groupMembers.find((member) => member.name === user_name);
        if(!user) return console.log("pf");

        setRecordData(prevData => ({
            ...prevData,
            deposit: user.invested, // jujj
            user_id: user.user_id
        }));
    }


    const makeRecord = async () => {
        await responseHero.post(`${process.env.REACT_APP_API_URL}/daisydeposit`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`${incoming ? '[Befizetés]: ' : '[Kifizetés]: '} Sikeresen létrehoztad a rekordot!`);
        }).catch(error => {
            closeModal()
            toast.error(`Sikertelen adatrögzítés! Próbáld meg újra kiválasztani a csoportot és az ügyfelet!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl font=semibold"><span className="text-brown">{system?.name}</span> - {title} | {recordData?.date?.toString().split('T')[0]}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Dátum </span> <br/>Rögzítés dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                    
                                    </div>

                                    <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Befizetés </span> <br/> USD
                                        </label>
                                        <input
                                            value={recordData?.deposit}
                                            onChange={(e) => handleChange('deposit', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inpayment" type="number" placeholder="USD"/>
                                    </div>


                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Csoport </span> <br/> Csoport neve
                                        </label>

                                        <select defaultValue={'Nincs kiválasztva'} onChange={(e) => handleGroupSelection(e.target.value)} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                        <option disabled={true} value={"Nincs kiválasztva"} className="px-2 py-1 text-center">{"Nincs kiválasztva"}</option>
                                            {groups.map((group, index) => (
                                                <option value={group.group_name} className="px-2 py-1 text-center" key={index}>{group.group_name}</option>
                                            ))}
                                        </select>
                                           
                                    </div>

                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Ügyfél </span> <br/> Ügyfél neve
                                        </label>

                                        <select defaultValue={'Nincs kiválasztva'} onChange={(e) => handleUserSelection(e.target.value, groupMembers)} className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md">
                                            <option disabled={true} value={"Nincs kiválasztva"} className="px-2 py-1 text-center">{"Nincs kiválasztva"}</option>
                                            {groupMembers.map((user, index) => (
                                                <option value={user.name} className="px-2 py-1 text-center" key={index}>{user.name}</option>
                                            ))}
                                        </select>
                                    
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewDepositModal;