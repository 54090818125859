import { ModalProps } from '../../../types/modalProps';
import { responseHero } from '../../../utils/axiosInterceptor';
import { Group } from "../../../models/group.model";
import { toast } from "react-toastify";
import { UserWithInvestment } from "../../../types/userWithInvestment";
import React from "react";
import { getGroupMembers } from "../../../api/getGroupMembers";

interface ModalPropsWithStatus extends ModalProps {
    group: Group | undefined,
    status: Boolean;
}

const StartStopModal = ({ title, showModal, closeModal, group, handleStateChange, status } : Omit<ModalPropsWithStatus,"user">) => {


    const [users, setUsers] = React.useState<UserWithInvestment[]>([])
    
    const operation = async (group: Group | undefined) => {
        if(!group) return;

        await getGroupMembers(group.group_id)
        .then((response) => {
            setUsers(response)
        })
        .catch((error) => {
            console.error(error);
        });
        
        const findUnique = users.find((user) => user.invested === 0)
        if(findUnique) return toast.error('Még nem állítottad be mindenki befektetési összegét!')

        await responseHero.put(`${process.env.REACT_APP_API_URL}/groups/${group.group_id}`,{ group: { system_id: group?.system_id, running: !status }}).then((response) => {
            closeModal()
            handleStateChange()

            toast.success(`Sikeres ${status ? 'leállítottad' : 'elindítottad' } a következő csoportot: ` + group.group_name);
        }).catch(error => {
            console.error(error.response.data);
            closeModal()

            toast.error("Hiba a csoportművelet közben!")
        })

        await responseHero.put(`${process.env.REACT_APP_API_URL}/share/${group.group_id}`).then((response) => {
            closeModal()
            handleStateChange()
        }).catch(error => {
            console.error(error.response.data);
        })
    }

            return (
            <>
                {(showModal && group) && (
                    <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-3xl font=semibold">{title}</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p>Biztos vagy benne, hogy {status ? 'leállítod' : 'elindítod'} a következő csoportot: <b>{group.group_name}</b> ?</p>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Mégse
                                </button>
                                <button
                                    className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => operation(group)}
                                >
                                    {status ? 'Leállítás' : 'Elindítás'}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </>
        );
}

export default StartStopModal;